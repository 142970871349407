import React from "react";
import "./styles.css";

import { IMAGES } from "../../../assets";

const Sections = [
  {
    title: "Quickly mark your prayers",
    description: `Swipe right (prayed) or swipe left (didn’t pray) for each of the five prayers. That’s all you have to do!`,
    image: IMAGES.phoneOne,
  },
  {
    title: "See prayers in a calendar",
    description: `Open the calendar to see which prayers you missed, complete those prayers, then mark them as prayed!`,
    image: IMAGES.phoneTwo,
  },
  {
    title: `Find the Qibla`,
    description: `Open the compass and find the Qibla`,
    image: IMAGES.phoneThree,
  },
  {
    title: "Pause during your cycle",
    description: `During your cycle, pause Niya and we will take care of marking your prayers!`,
    image: IMAGES.phoneFour,
  },
];

const Features = () => {
  return (
    <section className="container features-wrapper">
      <div className="features-wrapper-flower">
        <img src={IMAGES.flower} />
      </div>

      <h2 className="features-wrapper-title">Our main features</h2>
      <div className="features-items">
        {Sections.map((s, index) => (
          <div key={index} className="features-item">
            <img src={s.image} className="features-item-img" />
            <p className="features-item-title">{s.title}</p>
            <p className="features-item-description">{s.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
