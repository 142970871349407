import logo from "./logo.png";
import topBg from "./top-bg.png";
import bg from "./bg.png";
import appStore from "./appStore.png";
import googlePlay from "./googlePlay.png";
import preview from "./preview.png";
import phoneOne from "./phoneOne.png";
import phoneTwo from "./phoneTwo.png";
import phoneThree from "./phoneThree.png";
import phoneFour from "./phoneFour.png";
import flower from "./flower.png";
import burgerMenu from "./burgerMenu";
import cross from "./cross";
import selectIcon from "./selectIcon";
import engFlag from "./engFlag";
import arabicFlag from "./arabicFlag";
import check from "./check";
import indonesiaFlag from "./indonesiaFlag";

const IMAGES = {
  logo,
  topBg,
  bg,
  appStore,
  googlePlay,
  preview,
  phoneOne,
  phoneTwo,
  phoneThree,
  phoneFour,
  flower,
};

const ICONS = {
  burgerMenu,
  cross,
  selectIcon,
  check,
  engFlag,
  arabicFlag,
  indonesiaFlag,
};

export { IMAGES, ICONS };
