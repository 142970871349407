import React from "react";
import "./styles.css";
import { Footer } from "../../components";

const About = () => {
  return (
    <>
      <div className="about">
        <div className="container">
          <h4 className="about-title">About Us</h4>

          <p className="about-text">
            Our mission is to make completing all of your salah easier, on time
            or qada.
          </p>
          <p className="about-text">
            Do you ever miss your prayer because you forgot to pray or you had
            such a busy day and came home so tired? Do you want to make them up,
            but you’re forgetting which prayers you missed? The solution is
            Niya.
          </p>
          <p className="about-text">
            Niya is here to help you remember which prayers you completed and
            which ones you missed. By marking your prayers daily, you will feel
            motivated by consistently completing your prayers on time and if you
            miss your prayers, you will remember which ones you need to make up!{" "}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
