import React from "react";
import "./styles.css";

export const PrivacyPolicyInd = () => {
  return (
    <div>
      <p class="privacy-title">KEBIJAKAN PRIBADI</p>
      <p class="privacy-text">Terakhir diperbarui 04 Maret 2024</p>
      <p class="privacy-text">
        <span class="c14">
          Pemberitahuan privasi ini untuk Tigris Studios FZCO (&#39;
        </span>
        <span class="c14 c30">Kami</span>
        <span class="c14">&#39;,&#39;</span>
        <span class="c14 c30">kita</span>
        <span class="c14">&#39;, atau &#39;</span>
        <span class="c14 c30">kita</span>
        <span class="c14">
          &#39;), menjelaskan bagaimana dan mengapa kami mengumpulkan,
          menyimpan, menggunakan, dan/atau membagikan (&#39;
        </span>
        <span class="c14 c30">proses</span>
        <span class="c14">
          &#39;) informasi Anda saat Anda menggunakan layanan kami (&#39;
        </span>
        <span class="c14 c30">Jasa</span>
        <span class="c1">&#39;), misalnya ketika Anda:</span>
      </p>
      <ul class="lst-kix_ba47n1fgoi12-0" style={{ marginLeft: 50 }}>
        <li class="privacy-text li-bullet-0">
          Unduh dan gunakan aplikasi seluler kami (Niya), atau aplikasi kami
          lainnya yang tertaut ke pemberitahuan privasi ini
        </li>
      </ul>
      <ul class="lst-kix_7yj7nmvi9zg5-0" style={{ marginLeft: 50 }}>
        <li class="privacy-text li-bullet-0">
          <span class="c1">
            Terlibat dengan kami dengan cara terkait lainnya, termasuk
            penjualan, pemasaran, atau acara apa pun
          </span>
        </li>
      </ul>
      <p className="privacy-text">
        <span class="c14 c30">Pertanyaan atau kekhawatiran? </span>
        Pertanyaan atau kekhawatiran? Membaca pemberitahuan privasi ini akan
        membantu Anda memahami hak dan pilihan privasi Anda. Jika Anda tidak
        setuju dengan kebijakan dan praktik kami, mohon jangan menggunakan
        Layanan kami. Jika Anda masih memiliki pertanyaan atau masalah, silakan
        hubungi kami di hello@tigrisstudios.com.
      </p>

      <span class="c21">RINGKASAN POIN-POIN UTAMA</span>
      <br />
      <p class="privacy-text">
        <span class="c14 c30">Informasi pribadi apa yang kami proses?</span>
        <span class="c14">
          &nbsp;Saat Anda mengunjungi, menggunakan, atau menavigasi Layanan
          kami, kami dapat memproses informasi pribadi tergantung pada cara Anda
          berinteraksi dengan kami dan Layanan, pilihan yang Anda buat, serta
          produk dan fitur yang Anda gunakan.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">
          Apakah kami memproses informasi pribadi sensitif?
        </span>
        <span class="c1">
          &nbsp;Kami tidak memproses informasi pribadi yang sensitif.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">
          Apakah kami menerima informasi dari pihak ketiga?
        </span>
        <span class="c1">
          &nbsp;Kami tidak menerima informasi apa pun dari pihak ketiga.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">Bagaimana kami memproses informasi Anda?</span>
        <span class="c14">
          &nbsp;Kami memproses informasi Anda untuk menyediakan, meningkatkan,
          dan mengelola Layanan kami, berkomunikasi dengan Anda, untuk keamanan
          dan pencegahan penipuan, dan untuk mematuhi hukum. Kami juga dapat
          memproses informasi Anda untuk tujuan lain dengan persetujuan Anda.
          Kami memproses informasi Anda hanya jika kami memiliki alasan hukum
          yang sah untuk melakukannya.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">
          Dalam situasi apa dan dengan pihak mana kami membagikan informasi
          pribadi?
        </span>
        <span class="c14">
          &nbsp;Kami dapat berbagi informasi dalam situasi tertentu dan dengan
          pihak ketiga tertentu.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">
          Bagaimana kami menjaga keamanan informasi Anda?
        </span>
        <span class="c14">
          &nbsp;Kami memiliki proses dan prosedur organisasi dan teknis untuk
          melindungi informasi pribadi Anda. Namun, tidak ada transmisi
          elektronik melalui internet atau teknologi penyimpanan informasi yang
          dapat dijamin 100% aman, sehingga kami tidak dapat menjanjikan atau
          menjamin bahwa peretas, penjahat dunia maya, atau pihak ketiga tidak
          sah lainnya tidak akan mampu merusak keamanan kami dan mengumpulkan,
          mengakses secara tidak patut. , mencuri, atau mengubah informasi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">Apa hak Anda?</span>
        <span class="c14">
          &nbsp;Bergantung pada lokasi geografis Anda, undang-undang privasi
          yang berlaku mungkin berarti Anda memiliki hak tertentu terkait
          informasi pribadi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">Bagaimana Anda menggunakan hak Anda?</span>
        <span class="c1">
          &nbsp;Cara termudah untuk menggunakan hak Anda adalah dengan
          menghubungi kami. Kami akan mempertimbangkan dan menindaklanjuti
          permintaan apa pun sesuai dengan undang-undang perlindungan data yang
          berlaku.
        </span>
      </p>
      <p>
        <span class="privacy-text c21">DAFTAR ISI</span>
      </p>
      <p class="privacy-subtitle">
        <span class="c21">1. INFORMASI APA YANG KAMI KUMPULKAN?</span>
      </p>
      <span class="privacy-text">
        Informasi pribadi yang Anda ungkapkan kepada kami
      </span>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Kami mengumpulkan informasi pribadi yang Anda berikan kepada kami.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami mengumpulkan informasi pribadi yang Anda berikan secara sukarela
          kepada kami saat Anda mendaftar pada Layanan, menyatakan minat untuk
          memperoleh informasi tentang kami atau produk dan Layanan kami, saat
          Anda berpartisipasi dalam aktivitas di Layanan, atau saat Anda
          menghubungi kami.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">Informasi Pribadi yang Anda Berikan.</span>
        <span class="c1">
          &nbsp;Informasi pribadi yang kami kumpulkan bergantung pada konteks
          interaksi Anda dengan kami dan Layanan, pilihan yang Anda buat, serta
          produk dan fitur yang Anda gunakan. Informasi pribadi yang kami
          kumpulkan mungkin mencakup hal-hal berikut:
        </span>
      </p>
      <ul class="lst-kix_puz46d5w0h2q-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">nama</span>
        </li>
      </ul>
      <ul class="lst-kix_v6w6idwsxn3b-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">nomor telepon</span>
        </li>
      </ul>
      <ul class="lst-kix_rl8p5bh4f51s-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">alamat email</span>
        </li>
      </ul>
      <ul class="lst-kix_7b25xv5benxs-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">nama pengguna</span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c14 c30">Informasi sensitif.</span>
        <span class="c1">&nbsp;Kami tidak memproses informasi sensitif.</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">Data Masuk Media Sosial.</span>
        <span class="c1">
          Kami dapat memberi Anda opsi untuk mendaftar bersama kami menggunakan
          detail akun media sosial Anda yang ada, seperti Facebook, Twitter,
          atau akun media sosial lainnya. Jika Anda memilih untuk mendaftar
          dengan cara ini, kami akan mengumpulkan informasi yang dijelaskan di
          bagian yang disebutdi bawah.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30">Data aplikasi.</span>
        <span class="c29">
          &nbsp;Jika Anda menggunakan aplikasi kami, kami juga dapat
          mengumpulkan informasi berikut jika Anda memilih untuk memberi kami
          akses atau izin:
        </span>
      </p>
      <ul class="lst-kix_k88niecqhucu-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c39">Informasi Geolokasi.</span>
          <span class="c29">
            &nbsp;Kami dapat meminta akses atau izin untuk melacak informasi
            berbasis lokasi dari perangkat seluler Anda, baik secara
            terus-menerus atau saat Anda menggunakan aplikasi seluler kami,
            untuk menyediakan layanan berbasis lokasi tertentu. Jika Anda ingin
            mengubah akses atau izin kami, Anda dapat melakukannya di pengaturan
            perangkat Anda.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_ppf1fawta7f0-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c39">Pemberitahuan Dorong.</span>
          <span class="c1">
            &nbsp;Kami mungkin meminta untuk mengirimi Anda pemberitahuan push
            mengenai akun Anda atau fitur tertentu dari aplikasi. Jika Anda
            tidak ingin menerima jenis komunikasi ini, Anda dapat
            menonaktifkannya di pengaturan perangkat Anda.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c1">
          Informasi ini terutama diperlukan untuk menjaga keamanan dan
          pengoperasian aplikasi kami, untuk pemecahan masalah, dan untuk tujuan
          analisis dan pelaporan internal kami.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Semua informasi pribadi yang Anda berikan kepada kami harus benar,
          lengkap, dan akurat, dan Anda harus memberi tahu kami jika ada
          perubahan pada informasi pribadi tersebut.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c35 c30 c27">Informasi dikumpulkan secara otomatis</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Beberapa informasi &mdash; seperti alamat Protokol Internet (IP) Anda
          dan/atau karakteristik browser dan perangkat &mdash; dikumpulkan
          secara otomatis saat Anda mengunjungi Layanan kami.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami secara otomatis mengumpulkan informasi tertentu ketika Anda
          mengunjungi, menggunakan, atau menavigasi Layanan. Informasi ini tidak
          mengungkapkan identitas spesifik Anda (seperti nama atau informasi
          kontak Anda) namun mungkin mencakup informasi perangkat dan
          penggunaan, seperti alamat IP Anda, karakteristik browser dan
          perangkat, sistem operasi, preferensi bahasa, URL rujukan, nama
          perangkat, negara, lokasi , informasi tentang bagaimana dan kapan Anda
          menggunakan Layanan kami, dan informasi teknis lainnya. Informasi ini
          terutama diperlukan untuk menjaga keamanan dan pengoperasian Layanan
          kami, serta untuk tujuan analisis dan pelaporan internal kami.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Seperti banyak bisnis lainnya, kami juga mengumpulkan informasi
          melalui cookie dan teknologi serupa.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">Informasi yang kami kumpulkan meliputi:</span>
      </p>
      <ul class="lst-kix_dzrflgyay8hp-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c39">Data Log dan Penggunaan.</span>
          <span class="c14">
            &nbsp;Data log dan penggunaan adalah informasi terkait layanan,
            diagnostik, penggunaan, dan kinerja yang dikumpulkan server kami
            secara otomatis saat Anda mengakses atau menggunakan Layanan kami
            dan yang kami catat dalam file log. Bergantung pada cara Anda
            berinteraksi dengan kami, data log ini mungkin mencakup alamat IP
            Anda, informasi perangkat, jenis browser, dan pengaturan serta
            informasi tentang aktivitas Anda di Layanan.
          </span>
          <span class="c33">&nbsp;</span>
          <span class="c1">
            (seperti stempel tanggal/waktu yang terkait dengan penggunaan Anda,
            halaman dan file yang dilihat, pencarian, dan tindakan lain yang
            Anda ambil seperti fitur apa yang Anda gunakan), informasi peristiwa
            perangkat (seperti aktivitas sistem, laporan kesalahan (terkadang
            disebut &#39;crash dumps &#39;), dan pengaturan perangkat keras).
          </span>
        </li>
      </ul>
      <ul class="lst-kix_kg90g5x1j72h-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c39">Data Perangkat.</span>
          <span class="c1">
            &nbsp;Kami mengumpulkan data perangkat seperti informasi tentang
            komputer, ponsel, tablet, atau perangkat lain yang Anda gunakan
            untuk mengakses Layanan. Tergantung pada perangkat yang digunakan,
            data perangkat ini mungkin mencakup informasi seperti alamat IP Anda
            (atau server proxy), nomor identifikasi perangkat dan aplikasi,
            lokasi, jenis browser, model perangkat keras, penyedia layanan
            Internet dan/atau operator seluler, sistem operasi, dan informasi
            konfigurasi sistem.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_xrkpvvmfgurj-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c39">Data Lokasi.</span>
          <span class="c1">
            &nbsp;Kami mengumpulkan data lokasi seperti informasi tentang lokasi
            perangkat Anda, yang bisa tepat atau tidak tepat. Berapa banyak
            informasi yang kami kumpulkan bergantung pada jenis dan pengaturan
            perangkat yang Anda gunakan untuk mengakses Layanan. Misalnya, kami
            mungkin menggunakan GPS dan teknologi lainnya untuk mengumpulkan
            data geolokasi yang memberi tahu kami lokasi Anda saat ini
            (berdasarkan alamat IP Anda). Anda dapat memilih untuk tidak
            mengizinkan kami mengumpulkan informasi ini dengan menolak akses ke
            informasi tersebut atau dengan menonaktifkan pengaturan Lokasi di
            perangkat Anda. Namun, jika Anda memilih untuk tidak ikut serta,
            Anda mungkin tidak dapat menggunakan aspek tertentu dari Layanan.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c21">2. BAGAIMANA KAMI MEMPROSES INFORMASI ANDA?</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Kami memproses informasi Anda untuk menyediakan, meningkatkan, dan
          mengelola Layanan kami, berkomunikasi dengan Anda, untuk keamanan dan
          pencegahan penipuan, dan untuk mematuhi hukum. Kami juga dapat
          memproses informasi Anda untuk tujuan lain dengan persetujuan Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c4">
          Kami memproses informasi pribadi Anda karena berbagai alasan,
          bergantung pada cara Anda berinteraksi dengan Layanan kami, termasuk:
        </span>
      </p>
      <ul class="lst-kix_ol89yeg7sdif-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">
            Untuk memfasilitasi pembuatan akun dan otentikasi serta mengelola
            akun pengguna.
          </span>
          <span class="c1">
            Kami dapat memproses informasi Anda sehingga Anda dapat membuat dan
            masuk ke akun Anda, serta menjaga akun Anda tetap berfungsi.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_ti0sk4nhxte5-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">Untuk meminta umpan balik.</span>
          <span class="c1">
            Kami dapat memproses informasi Anda bila diperlukan untuk meminta
            umpan balik dan menghubungi Anda tentang penggunaan Anda atas
            Layanan kami.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_sxoy081bpjec-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">
            Untuk menyelamatkan atau melindungi kepentingan vital seseorang.
          </span>
          <span class="c29">
            &nbsp;Kami dapat memproses informasi Anda bila diperlukan untuk
            menyelamatkan atau melindungi kepentingan vital seseorang, misalnya
            untuk mencegah bahaya.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c21">
          3. DASAR HUKUM APA YANG KAMI HANDALKAN UNTUK MEMPROSES INFORMASI ANDA?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Kami hanya memproses informasi pribadi Anda jika kami yakin hal
          tersebut diperlukan dan kami memiliki alasan hukum yang sah (yaitu
          dasar hukum) untuk melakukannya berdasarkan hukum yang berlaku,
          seperti persetujuan Anda, untuk mematuhi undang-undang, untuk memberi
          Anda layanan untuk masuk ke dalam atau memenuhi kewajiban kontrak
          kami, untuk melindungi hak Anda, atau untuk memenuhi kepentingan
          bisnis kami yang sah.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c20 c14 c17">
          Jika Anda berlokasi di UE atau Inggris Raya, bagian ini berlaku untuk
          Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Peraturan Perlindungan Data Umum (GDPR) dan GDPR Inggris mengharuskan
          kami menjelaskan dasar hukum valid yang kami andalkan untuk memproses
          informasi pribadi Anda. Oleh karena itu, kami dapat mengandalkan dasar
          hukum berikut untuk memproses informasi pribadi Anda:
        </span>
      </p>
      <ul class="lst-kix_8tuijn4svs40-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">Izin.</span>
          <span class="c1">
            Kami dapat memproses informasi Anda jika Anda telah memberi kami
            izin (yaitu persetujuan) untuk menggunakan informasi pribadi Anda
            untuk tujuan tertentu. Anda dapat menarik persetujuan Anda kapan
            saja.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_2abn2g2km97p-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">Kepentingan yang Sah.</span>
          <span class="c29">
            &nbsp;Kami dapat memproses informasi Anda ketika kami yakin hal
            tersebut diperlukan secara wajar untuk mencapai kepentingan bisnis
            kami yang sah dan kepentingan tersebut tidak melebihi kepentingan
            serta hak dan kebebasan dasar Anda. Misalnya, kami mungkin memproses
            informasi pribadi Anda untuk beberapa tujuan yang dijelaskan untuk:
          </span>
        </li>
      </ul>
      <ul class="lst-kix_ppndj2fanav4-0 start">
        <li class="c46 li-bullet-0">
          <span class="c29">
            Pahami cara pengguna menggunakan produk dan layanan kami sehingga
            kami dapat meningkatkan pengalaman pengguna
          </span>
        </li>
      </ul>
      <ul class="lst-kix_qi7v1powo7dz-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">Kewajiban Hukum.</span>
          <span class="c29">
            &nbsp;Kami dapat memproses informasi Anda jika kami yakin hal
            tersebut diperlukan untuk mematuhi kewajiban hukum kami, seperti
            untuk bekerja sama dengan badan penegak hukum atau badan pengatur,
            melaksanakan atau membela hak hukum kami, atau mengungkapkan
            informasi Anda sebagai bukti dalam litigasi di mana kami berada.
            terlibat.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_26pd3ajzi9m2-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">Kepentingan Vital.</span>
          <span class="c29">
            &nbsp;Kami dapat memproses informasi Anda jika kami yakin hal
            tersebut diperlukan untuk melindungi kepentingan vital Anda atau
            kepentingan vital pihak ketiga, seperti situasi yang melibatkan
            potensi ancaman terhadap keselamatan seseorang.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c14 c17 c20">
          Jika Anda berlokasi di Kanada, bagian ini berlaku untuk Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c33">
          Kami dapat memproses informasi Anda jika Anda telah memberi kami izin
          khusus (yaitu persetujuan tersurat) untuk menggunakan informasi
          pribadi Anda untuk tujuan tertentu, atau dalam situasi di mana izin
          Anda dapat disimpulkan (yaitu persetujuan tersirat).
        </span>
      </p>
      <br />
      <p class="privacy-text">
        <span class="c29">
          Dalam beberapa kasus luar biasa, kami mungkin diizinkan secara hukum
          berdasarkan hukum yang berlaku untuk memproses informasi Anda tanpa
          persetujuan Anda, termasuk, misalnya:
        </span>
      </p>
      <ul class="lst-kix_3ynnd2dxreni-0 start">
        <li class="c0 li-bullet-0">
          <span class="c29">
            Jika pengumpulan jelas-jelas demi kepentingan individu dan
            persetujuan tidak dapat diperoleh pada waktu yang tepat
          </span>
        </li>
      </ul>
      <ul class="lst-kix_z5vqysbrzc9-0 start">
        <li class="c0 li-bullet-0">
          <span class="c29">
            Untuk investigasi dan deteksi dan pencegahan penipuan
          </span>
        </li>
      </ul>
      <ul class="lst-kix_iuh9e1gx6r05-0 start">
        <li class="c0 li-bullet-0">
          <span class="c29">
            Untuk transaksi bisnis, kondisi tertentu terpenuhi
          </span>
        </li>
      </ul>
      <ul class="lst-kix_mbp6mzn1s1h4-0 start">
        <li class="c0 li-bullet-0">
          <span class="c29">
            Jika tercantum dalam keterangan saksi dan pengumpulannya diperlukan
            untuk menilai, memproses, atau menyelesaikan suatu klaim asuransi
          </span>
        </li>
      </ul>
      <ul class="lst-kix_yh09krrwgxwf-0 start">
        <li class="c0 li-bullet-0">
          <span class="c29">
            Untuk mengidentifikasi orang yang terluka, sakit, atau meninggal dan
            berkomunikasi dengan kerabat terdekat
          </span>
        </li>
      </ul>
      <ul class="lst-kix_nu9man2jx189-0 start">
        <li class="c0 li-bullet-0">
          <span class="c29">
            Jika kami memiliki alasan yang masuk akal untuk meyakini bahwa
            seseorang telah, sedang, atau mungkin menjadi korban penyalahgunaan
            keuangan
          </span>
        </li>
      </ul>
      <ul class="lst-kix_3s0obr52c6ck-0 start">
        <li class="c0 li-bullet-0">
          <span class="c29">
            Jika wajar untuk mengharapkan pengumpulan dan penggunaan dengan
            persetujuan akan membahayakan ketersediaan atau keakuratan informasi
            dan pengumpulan tersebut wajar untuk tujuan yang berkaitan dengan
            penyelidikan pelanggaran perjanjian atau pelanggaran hukum Kanada
            atau provinsi.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_oe2045uzi6hq-0 start">
        <li class="c0 li-bullet-0">
          <span class="c29">
            Jika pengungkapan diperlukan untuk mematuhi panggilan pengadilan,
            surat perintah, perintah pengadilan, atau aturan pengadilan terkait
            dengan pembuatan catatan
          </span>
        </li>
      </ul>
      <ul class="lst-kix_50sdld1c0aux-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Jika informasi tersebut dihasilkan oleh seseorang dalam rangka
            pekerjaan, bisnis, atau profesinya dan pengumpulannya konsisten
            dengan tujuan pembuatan informasi tersebut.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_sgv2p1n60t4e-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Jika koleksinya semata-mata untuk tujuan jurnalistik, seni, atau
            sastra
          </span>
        </li>
      </ul>
      <ul class="lst-kix_mqsn65gfo7su-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Jika informasi tersebut tersedia untuk umum dan ditentukan oleh
            peraturan
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c21">
          4. KAPAN DAN DENGAN SIAPA KAMI BERBAGI INFORMASI PRIBADI ANDA?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          &nbsp;Kami dapat berbagi informasi dalam situasi tertentu yang
          dijelaskan dalam bagian ini dan/atau dengan pihak ketiga berikut.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami mungkin perlu membagikan informasi pribadi Anda dalam situasi
          berikut:
        </span>
      </p>
      <ul class="lst-kix_vzieuupiymui-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">Transfer Bisnis.</span>
          <span class="c1">
            &nbsp;Kami dapat membagikan atau mentransfer informasi Anda
            sehubungan dengan, atau selama negosiasi, merger, penjualan aset
            perusahaan, pembiayaan, atau akuisisi seluruh atau sebagian bisnis
            kami ke perusahaan lain.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_lykj7ulkogxo-0 start">
        <li class="c0 li-bullet-0">
          <span class="c14 c30">
            Saat kami menggunakan API Google Maps Platform.
          </span>
          <span class="c1">
            &nbsp;Kami dapat membagikan informasi Anda dengan Google Maps
            Platform API tertentu (misalnya Google Maps API, Places API). Kami
            menggunakan API Google Maps Platform tertentu untuk mengambil
            informasi tertentu saat Anda membuat permintaan spesifik lokasi. Ini
            termasuk: Lokasi Pengguna; dan informasi serupa lainnya. Daftar
            lengkap informasi yang kami gunakan dapat ditemukan di bagian ini
            dan di bagian sebelumnya yang berjudulKami memperoleh dan menyimpan
            di perangkat Anda (&#39;cache&#39;) lokasi Anda. Anda dapat mencabut
            persetujuan Anda kapan saja dengan menghubungi kami di rincian
            kontak yang disediakan di akhir dokumen ini. API Google Maps
            Platform yang kami gunakan menyimpan dan mengakses cookie dan
            informasi lainnya di perangkat Anda. Jika Anda saat ini adalah
            pengguna di Wilayah Ekonomi Eropa (negara-negara UE, Islandia,
            Liechtenstein, dan Norwegia) atau Inggris, silakan lihat
            Pemberitahuan Cookie kami.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c21">
          5. APA SUDAH KAMI TERHADAP SITUS WEB PIHAK KETIGA?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          &nbsp;Kami tidak bertanggung jawab atas keamanan informasi apa pun
          yang Anda bagikan dengan pihak ketiga yang mungkin kami tautkan atau
          yang beriklan di Layanan kami, namun tidak berafiliasi dengan, Layanan
          kami.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Layanan dapat tertaut ke situs web pihak ketiga, layanan online, atau
          aplikasi seluler dan/atau berisi iklan dari pihak ketiga yang tidak
          berafiliasi dengan kami dan mungkin tertaut ke situs web, layanan,
          atau aplikasi lain. Oleh karena itu, kami tidak memberikan jaminan apa
          pun mengenai pihak ketiga tersebut, dan kami tidak akan bertanggung
          jawab atas kehilangan atau kerusakan apa pun yang disebabkan oleh
          penggunaan situs web, layanan, atau aplikasi pihak ketiga tersebut.
          Dimasukkannya tautan ke situs web, layanan, atau aplikasi pihak ketiga
          tidak menyiratkan dukungan kami. Kami tidak dapat menjamin keamanan
          dan privasi data yang Anda berikan kepada pihak ketiga mana pun. Data
          apa pun yang dikumpulkan oleh pihak ketiga tidak tercakup dalam
          pemberitahuan privasi ini. Kami tidak bertanggung jawab atas konten
          atau privasi dan praktik keamanan serta kebijakan pihak ketiga mana
          pun, termasuk situs web, layanan, atau aplikasi lain yang mungkin
          tertaut ke atau dari Layanan. Anda harus meninjau kebijakan pihak
          ketiga tersebut dan menghubungi mereka secara langsung untuk menjawab
          pertanyaan Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c21">
          6. APAKAH KITA MENGGUNAKAN COOKIE DAN TEKNOLOGI PELACAKAN LAINNYA?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          &nbsp;Kami mungkin menggunakan cookie dan teknologi pelacakan lainnya
          untuk mengumpulkan dan menyimpan informasi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami dapat menggunakan cookie dan teknologi pelacakan serupa (seperti
          web beacon dan piksel) untuk mengakses atau menyimpan informasi.
          Informasi spesifik tentang cara kami menggunakan teknologi tersebut
          dan cara Anda dapat menolak cookie tertentu diatur dalam Pemberitahuan
          Cookie kami.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c21">7. BAGAIMANA KAMI MENANGANI LOGIN SOSIAL ANDA?</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Jika Anda memilih untuk mendaftar atau masuk ke Layanan kami
          menggunakan akun media sosial, kami mungkin memiliki akses ke
          informasi tertentu tentang Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Layanan kami menawarkan Anda kemampuan untuk mendaftar dan masuk
          menggunakan detail akun media sosial pihak ketiga Anda (seperti login
          Facebook atau Twitter Anda). Jika Anda memilih untuk melakukan ini,
          kami akan menerima informasi profil tertentu tentang Anda dari
          penyedia media sosial Anda. Informasi profil yang kami terima mungkin
          berbeda-beda tergantung pada penyedia media sosial yang bersangkutan,
          namun sering kali menyertakan nama, alamat email, daftar teman, dan
          gambar profil Anda, serta informasi lain yang Anda pilih untuk
          dipublikasikan di platform media sosial tersebut.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami akan menggunakan informasi yang kami terima hanya untuk tujuan
          yang dijelaskan dalam pemberitahuan privasi ini atau yang dijelaskan
          kepada Anda di Layanan terkait. Harap diperhatikan bahwa kami tidak
          mengontrol, dan tidak bertanggung jawab atas, penggunaan lain atas
          informasi pribadi Anda oleh penyedia media sosial pihak ketiga Anda.
          Kami menyarankan Anda meninjau pemberitahuan privasi mereka untuk
          memahami cara mereka mengumpulkan, menggunakan, dan membagikan
          informasi pribadi Anda, dan bagaimana Anda dapat mengatur preferensi
          privasi Anda di situs dan aplikasi mereka.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c21">8. BERAPA LAMA KAMI MENYIMPAN INFORMASI ANDA?</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Kami menyimpan informasi Anda selama diperlukan untuk memenuhi tujuan
          yang diuraikan dalam pemberitahuan privasi ini kecuali diwajibkan oleh
          hukum.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami hanya akan menyimpan informasi pribadi Anda selama diperlukan
          untuk tujuan yang ditetapkan dalam pemberitahuan privasi ini, kecuali
          periode penyimpanan yang lebih lama diperlukan atau diizinkan oleh
          hukum (seperti pajak, akuntansi, atau persyaratan hukum lainnya).
          Tidak ada tujuan dalam pemberitahuan ini yang mengharuskan kami
          menyimpan informasi pribadi Anda lebih lama dari jangka waktu pengguna
          memiliki akun dengan kami.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Ketika kami tidak mempunyai keperluan bisnis yang sah untuk memproses
          informasi pribadi Anda, kami akan menghapus atau menganonimkan
          informasi tersebut, atau, jika hal ini tidak memungkinkan (misalnya,
          karena informasi pribadi Anda telah disimpan dalam arsip cadangan),
          maka kami akan secara aman simpan informasi pribadi Anda dan pisahkan
          dari pemrosesan lebih lanjut hingga penghapusan dapat dilakukan.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c21">9. BAGAIMANA KAMI MENJAGA INFORMASI ANDA AMAN?</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Kami bertujuan untuk melindungi informasi pribadi Anda melalui sistem
          tindakan keamanan organisasi dan teknis.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami telah menerapkan langkah-langkah keamanan teknis dan organisasi
          yang sesuai dan wajar yang dirancang untuk melindungi keamanan
          informasi pribadi apa pun yang kami proses. Namun, terlepas dari upaya
          perlindungan dan upaya kami untuk mengamankan informasi Anda, tidak
          ada transmisi elektronik melalui Internet atau teknologi penyimpanan
          informasi yang dapat dijamin 100% aman, sehingga kami tidak dapat
          menjanjikan atau menjamin bahwa peretas, penjahat dunia maya, atau
          pihak ketiga tidak sah lainnya tidak akan aman. mampu mengalahkan
          keamanan kami dan mengumpulkan, mengakses, mencuri, atau mengubah
          informasi Anda secara tidak patut. Meskipun kami akan melakukan yang
          terbaik untuk melindungi informasi pribadi Anda, transmisi informasi
          pribadi ke dan dari Layanan kami adalah risiko Anda sendiri. Anda
          sebaiknya hanya mengakses Layanan dalam lingkungan yang aman.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c21">10. APA HAK PRIVASI ANDA?</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          &nbsp;Di beberapa kawasan, seperti Wilayah Ekonomi Eropa (EEA),
          Inggris Raya (UK), Swiss, dan Kanada, Anda memiliki hak yang
          memungkinkan Anda mengakses dan mengontrol lebih besar atas informasi
          pribadi Anda. Anda dapat meninjau, mengubah, atau menghentikan akun
          Anda kapan saja.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Di beberapa wilayah (seperti EEA, Inggris Raya, Swiss, dan Kanada),
          Anda memiliki hak tertentu berdasarkan undang-undang perlindungan data
          yang berlaku. Hal ini dapat mencakup hak (i) untuk meminta akses dan
          mendapatkan salinan informasi pribadi Anda, (ii) untuk meminta
          perbaikan atau penghapusan; (iii) untuk membatasi pemrosesan informasi
          pribadi Anda; (iv) jika berlaku, terhadap portabilitas data; dan (v)
          tidak tunduk pada pengambilan keputusan otomatis. Dalam keadaan
          tertentu, Anda mungkin juga berhak menolak pemrosesan informasi
          pribadi Anda. Anda dapat membuat permintaan tersebut dengan
          menghubungi kami menggunakan rincian kontak yang disediakan di bagian
          tersebut di bawah.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami akan mempertimbangkan dan menindaklanjuti permintaan apa pun
          sesuai dengan undang-undang perlindungan data yang berlaku.
        </span>
      </p>
      <br />
      <p class="privacy-text">
        <span class="c14">
          Jika Anda berlokasi di EEA atau Inggris Raya dan Anda yakin kami
          memproses informasi pribadi Anda secara tidak sah, Anda juga berhak
          mengajukan keluhan kepada Anda
        </span>
        <span class="c44">
          <a
            class="c22"
            href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1710525807402149&amp;usg=AOvVaw2xn1_z7pS-n7dWCkOf-lrU"
          >
            Otoritas perlindungan data Negara Anggota
          </a>
        </span>
        <span class="c14">&nbsp;atau</span>
        <span class="c13">
          <a
            class="c22"
            href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&amp;sa=D&amp;source=editors&amp;ust=1710525807402494&amp;usg=AOvVaw1SX3yz9DjQCHSzIxtNa9e9"
          >
            Otoritas perlindungan data Inggris
          </a>
        </span>
        <span class="c1">.</span>
      </p>
      <p class="privacy-text">
        <span class="c14">
          Jika Anda berlokasi di Swiss, Anda dapat menghubungi
        </span>
        <span class="c13">
          <a
            class="c22"
            href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1710525807402859&amp;usg=AOvVaw3f2d7xt_wQdaUiMjNrY33g"
          >
            Komisaris Perlindungan Data dan Informasi Federal
          </a>
        </span>
        <span class="c1">.</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c30 c52">Menarik persetujuan Anda:</span>
        <span class="c1">
          &nbsp;Jika kami mengandalkan persetujuan Anda untuk memproses
          informasi pribadi Anda, yang mungkin merupakan persetujuan tersurat
          dan/atau tersirat tergantung pada hukum yang berlaku, Anda berhak
          untuk menarik persetujuan Anda kapan saja. Anda dapat menarik
          persetujuan Anda kapan saja dengan menghubungi kami menggunakan
          rincian kontak yang disediakan di bagian tersebut di bawah.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Namun, perlu diketahui bahwa hal ini tidak akan memengaruhi keabsahan
          pemrosesan sebelum penarikannya, dan jika hukum yang berlaku
          mengizinkan, hal ini tidak akan memengaruhi pemrosesan informasi
          pribadi Anda yang dilakukan dengan mengandalkan dasar pemrosesan yang
          sah selain dari persetujuan.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c35 c30 c27">Informasi Akun</span>
      </p>
      <p class="privacy-text">
        <span class="c29">
          Jika suatu saat Anda ingin meninjau atau mengubah informasi di akun
          Anda atau menghentikan akun Anda, Anda dapat:
        </span>
      </p>
      <ul class="lst-kix_jjgemk7qsfu7-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Masuk ke pengaturan akun Anda dan perbarui akun pengguna Anda.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c1">
          Atas permintaan Anda untuk menghentikan akun Anda, kami akan
          menonaktifkan atau menghapus akun dan informasi Anda dari database
          aktif kami. Namun, kami mungkin menyimpan beberapa informasi dalam
          file kami untuk mencegah penipuan, memecahkan masalah, membantu
          penyelidikan apa pun, menegakkan ketentuan hukum kami dan/atau
          mematuhi persyaratan hukum yang berlaku.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c33">
          Jika Anda memiliki pertanyaan atau komentar tentang hak privasi Anda,
          Anda dapat mengirim email kepada kami di
        </span>
        <span class="c14">halo@tigrisstudios.com</span>
        <span class="c29">.</span>
      </p>
      <p class="privacy-text">
        <span class="c21">11. KONTROL UNTUK FITUR JANGAN LACAK</span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Sebagian besar browser web dan beberapa sistem operasi seluler serta
          aplikasi seluler menyertakan fitur atau pengaturan Jangan Lacak
          (&#39;DNT&#39;) yang dapat Anda aktifkan untuk menandakan preferensi
          privasi Anda agar data tentang aktivitas penjelajahan online Anda
          tidak dipantau dan dikumpulkan. Pada tahap ini belum ada standar
          teknologi seragam untuk mengenali dan menerapkan sinyal DNT yang telah
          diselesaikan. Oleh karena itu, saat ini kami tidak merespons sinyal
          browser DNT atau mekanisme lain apa pun yang secara otomatis
          menyampaikan pilihan Anda untuk tidak dilacak secara online. Jika
          standar pelacakan online diadopsi dan harus kami ikuti di masa
          mendatang, kami akan memberi tahu Anda tentang praktik tersebut dalam
          versi revisi pemberitahuan privasi ini.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c21">
          12. APAKAH PENDUDUK AMERIKA SERIKAT MEMILIKI HAK PRIVASI KHUSUS?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Jika Anda adalah penduduk Virginia, Utah, Connecticut, Colorado, atau
          California, Anda diberikan hak khusus terkait akses ke informasi
          pribadi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c4">
          Kategori informasi pribadi apa yang kami kumpulkan?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami telah mengumpulkan kategori informasi pribadi berikut dalam dua
          belas (12) bulan terakhir:
        </span>
      </p>
      <a id="t.a74855f7d0aa700517dca4ef86fe2fd40417e85c"></a>
      <a id="t.0"></a>
      <table style={{ width: "100%" }} class="c7">
        <tr class="c56">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c32">
              <span class="c14 c30">Kategori</span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c32">
              <span class="c14 c30">Contoh</span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <span class="c14 c30">Dikumpulkan</span>
          </td>
        </tr>
        <tr class="c18">
          <td class="c36" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">A.Pengidentifikasi</span>
            </p>
          </td>
          <td class="c43" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                Detail kontak, seperti nama asli, alias, alamat pos, nomor
                kontak telepon atau ponsel, pengenal pribadi unik, pengenal
                online, alamat Protokol Internet, alamat email, dan nama akun
              </span>
            </p>
          </td>
          <td class="c58" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">YA</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
      </table>
      <p class="c31">
        <span class="c2"></span>
      </p>
      <a id="t.b3a145ff916abbe10676ff4811b77cee0bc4e5d2"></a>
      <a id="t.1"></a>
      <table class="c7" style={{ width: "100%" }}>
        <tr class="c18">
          <td class="c36" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                B. Informasi pribadi sebagaimana didefinisikan dalam
                undang-undang Catatan Pelanggan California
              </span>
            </p>
          </td>
          <td class="c43" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                Nama, informasi kontak, pendidikan, pekerjaan, riwayat
                pekerjaan, dan informasi keuangan
              </span>
            </p>
          </td>
          <td class="c37" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">YA</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
      </table>
      <p class="c31">
        <span class="c2"></span>
      </p>
      <a id="t.f55ef704f32d4c47b09c786b830857ddadf2eadb"></a>
      <a id="t.2"></a>
      <table class="c7" style={{ width: "100%" }}>
        <tr class="c18">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                C. Karakteristik klasifikasi yang dilindungi berdasarkan
                undang-undang negara bagian atau federal
              </span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">Jenis kelamin dan tanggal lahir</span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">YA</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c18">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">D.Informasi komersial</span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                Informasi transaksi, riwayat pembelian, rincian keuangan, dan
                informasi pembayaran
              </span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">TIDAK</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c18">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">E. Informasi biometrik</span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">Sidik jari dan sidik suara</span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">TIDAK</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c18">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                F. Internet atau aktivitas jaringan serupa lainnya
              </span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                Riwayat penjelajahan, riwayat pencarian, perilaku online, data
                minat, dan interaksi dengan situs web, aplikasi, sistem, dan
                iklan kami dan lainnya
              </span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">TIDAK</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c18">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">G.Data geolokasi</span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">Lokasi perangkat</span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">YA</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c18">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                H. Informasi audio, elektronik, visual, termal, penciuman, atau
                serupa
              </span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                Gambar dan rekaman audio, video atau panggilan yang dibuat
                sehubungan dengan aktivitas bisnis kami
              </span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">TIDAK</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c18">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                I. Informasi profesional atau terkait pekerjaan
              </span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                Perincian kontak bisnis untuk memberi Anda Layanan kami di
                tingkat bisnis atau jabatan, riwayat pekerjaan, dan kualifikasi
                profesional jika Anda melamar pekerjaan dengan kami
              </span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">TIDAK</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c18">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">J. Informasi Pendidikan</span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">Catatan siswa dan informasi direktori</span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">TIDAK</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c24">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                K. Kesimpulan diambil dari informasi pribadi yang dikumpulkan
              </span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c1">
                Kesimpulan diambil dari informasi pribadi apa pun yang
                dikumpulkan yang tercantum di atas untuk membuat profil atau
                ringkasan tentang, misalnya, preferensi dan karakteristik
                individu
              </span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c15">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">TIDAK</span>
            </p>
            <p class="c15">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
        <tr class="c50">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c14">L. Informasi pribadi yang sensitif</span>
            </p>
          </td>
          <td class="c12" colspan="1" rowspan="1">
            <p class="c9 c42">
              <span class="c2"></span>
            </p>
          </td>
          <td class="c26" colspan="1" rowspan="1">
            <p class="c28">
              <span class="c2"></span>
            </p>
            <p class="c38">
              <span class="c1">TIDAK</span>
            </p>
            <p class="c28">
              <span class="c2"></span>
            </p>
          </td>
        </tr>
      </table>
      <p class="privacy-text">
        <span class="c1">
          Kami akan menggunakan dan menyimpan informasi pribadi yang dikumpulkan
          sebagaimana diperlukan untuk menyediakan Layanan atau untuk:
        </span>
      </p>
      <ul class="lst-kix_2uyq7erxnrvp-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Kategori A - Selama pengguna memiliki akun bersama kami
          </span>
        </li>
      </ul>
      <ul class="lst-kix_pun6jm8umc7w-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Kategori B - Selama pengguna memiliki akun bersama kami
          </span>
        </li>
      </ul>
      <ul class="lst-kix_jts4266o7ez5-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Kategori C - Selama pengguna memiliki akun bersama kami
          </span>
        </li>
      </ul>
      <ul class="lst-kix_tn9j3ipvdcni-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Kategori G - Selama pengguna memiliki akun bersama kami
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c1">
          Kami juga dapat mengumpulkan informasi pribadi lainnya di luar
          kategori ini melalui kejadian di mana Anda berinteraksi dengan kami
          secara langsung, online, atau melalui telepon atau surat dalam
          konteks:
        </span>
      </p>
      <ul class="lst-kix_v03mx0kxnvea-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Menerima bantuan melalui saluran dukungan pelanggan kami;
          </span>
        </li>
      </ul>
      <ul class="lst-kix_yhk96nr9jqh-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Partisipasi dalam survei atau kontes pelanggan; Dan
          </span>
        </li>
      </ul>
      <ul class="lst-kix_cit182463q4c-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Fasilitasi dalam penyampaian Layanan kami dan untuk menanggapi
            pertanyaan Anda.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c4">
          Bagaimana kami menggunakan dan membagikan informasi pribadi Anda?
        </span>
      </p>
      <p class="c3">
        <span class="c29"></span>
      </p>
      <p class="privacy-text">
        <span class="c4">
          Apakah informasi Anda akan dibagikan kepada orang lain?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14">
          Kami dapat mengungkapkan informasi pribadi Anda dengan penyedia
          layanan kami berdasarkan kontrak tertulis antara kami dan
          masing-masing penyedia layanan.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami dapat menggunakan informasi pribadi Anda untuk tujuan bisnis
          kami, misalnya untuk melakukan penelitian internal untuk pengembangan
          dan demonstrasi teknologi. Hal ini tidak dianggap sebagai
          &#39;penjualan&#39; informasi pribadi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami belum mengungkapkan, menjual, atau membagikan informasi pribadi
          apa pun kepada pihak ketiga untuk tujuan bisnis atau komersial dalam
          dua belas (12) bulan sebelumnya. Kami tidak akan menjual atau
          membagikan informasi pribadi milik pengunjung situs web, pengguna, dan
          konsumen lainnya di kemudian hari.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c30 c34">Penduduk Kalifornia</span>
      </p>
      <p class="privacy-text">
        <span class="c29">
          California Civil Code Pasal 1798.83, juga dikenal sebagai
          undang-undang &#39;Shine The Light&#39; mengizinkan pengguna kami yang
          merupakan penduduk California untuk meminta dan memperoleh dari kami,
          setahun sekali dan gratis, informasi tentang kategori informasi
          pribadi (jika ada) yang kami ungkapkan kepada pihak ketiga untuk
          tujuan pemasaran langsung dan nama serta alamat semua pihak ketiga
          yang kami bagikan informasi pribadinya pada tahun kalender sebelumnya.
          Jika Anda adalah penduduk California dan ingin mengajukan permintaan
          tersebut, silakan kirimkan permintaan Anda secara tertulis kepada kami
          menggunakan informasi kontak yang tersedia di bawah.
        </span>
      </p>
      <p class="c3">
        <span class="c1"></span>
      </p>
      <p class="privacy-text">
        <span class="c29">
          Jika Anda berusia di bawah 18 tahun, tinggal di California, dan
          memiliki akun terdaftar di Layanan, Anda berhak meminta penghapusan
          data yang tidak diinginkan yang Anda posting secara publik di Layanan.
          Untuk meminta penghapusan data tersebut, silakan hubungi kami
          menggunakan informasi kontak yang disediakan di bawah dan sertakan
          alamat email yang terkait dengan akun Anda dan pernyataan bahwa Anda
          tinggal di California. Kami akan memastikan data tidak ditampilkan
          secara publik di Layanan, namun harap diingat bahwa data tersebut
          mungkin tidak dihapus seluruhnya atau seluruhnya dari semua sistem
          kami (misalnya pencadangan, dll.).
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c20 c14 c30 c41">Pemberitahuan Privasi CCPA</span>
      </p>
      <p class="c3">
        <span class="c1"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Bagian ini hanya berlaku untuk penduduk California. Berdasarkan
          Undang-Undang Privasi Konsumen California (CCPA), Anda memiliki
          hak-hak yang tercantum di bawah.
        </span>
      </p>
      <p class="c3">
        <span class="c1"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kode Peraturan California mendefinisikan &#39;penduduk&#39; sebagai:
        </span>
      </p>
      <p class="c3">
        <span class="c1"></span>
      </p>
      <p class="c5 c55">
        <span class="c1">
          (1) setiap individu yang berada di Negara Bagian California selain
          untuk tujuan sementara atau sementara dan
        </span>
      </p>
      <p class="c5 c55">
        <span class="c1">
          (2) Setiap orang yang berdomisili di Negara Bagian Kalifornia yang
          berada di luar Negara Bagian Kalifornia untuk tujuan sementara atau
          peralihan
        </span>
      </p>
      <p class="c3">
        <span class="c1"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Semua individu lainnya didefinisikan sebagai &#39;bukan penduduk&#39;.
        </span>
      </p>
      <p class="c3">
        <span class="c1"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Jika definisi &#39;penduduk&#39; ini berlaku untuk Anda, kami harus
          mematuhi hak dan kewajiban tertentu terkait informasi pribadi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c4">Hak Anda sehubungan dengan data pribadi Anda</span>
      </p>
      <p class="privacy-text">
        <span class="c6">
          Hak untuk meminta penghapusan data &mdash; Permintaan untuk menghapus
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Anda dapat meminta penghapusan informasi pribadi Anda. Jika Anda
          meminta kami untuk menghapus informasi pribadi Anda, kami akan
          menghormati permintaan Anda dan menghapus informasi pribadi Anda,
          dengan tunduk pada pengecualian tertentu yang diberikan oleh hukum,
          seperti (namun tidak terbatas pada) pelaksanaan hak kebebasan
          berpendapat oleh konsumen lain. , persyaratan kepatuhan kami yang
          diakibatkan oleh kewajiban hukum, atau proses apa pun yang mungkin
          diperlukan untuk melindungi dari aktivitas ilegal.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c6">
          Hak untuk mendapat informasi &mdash; Permintaan untuk mengetahui
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Tergantung pada situasinya, Anda berhak mengetahui:
        </span>
      </p>
      <ul class="lst-kix_69y2gcf2x83v-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            apakah kami mengumpulkan dan menggunakan informasi pribadi Anda;
          </span>
        </li>
      </ul>
      <ul class="lst-kix_u7h4wtr3pen6-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            kategori informasi pribadi yang kami kumpulkan;
          </span>
        </li>
      </ul>
      <ul class="lst-kix_1h1uolj9ia1i-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            tujuan penggunaan informasi pribadi yang dikumpulkan;
          </span>
        </li>
      </ul>
      <ul class="lst-kix_cwg7kptqxbfa-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            apakah kami menjual atau membagikan informasi pribadi kepada pihak
            ketiga;
          </span>
        </li>
      </ul>
      <ul class="lst-kix_9v14y0uln8t7-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            kategori informasi pribadi yang kami jual, bagikan, atau ungkapkan
            untuk tujuan bisnis;
          </span>
        </li>
      </ul>
      <ul class="lst-kix_d3n134oi11r9-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            kategori pihak ketiga kepada siapa informasi pribadi tersebut
            dijual, dibagikan, atau diungkapkan untuk tujuan bisnis;
          </span>
        </li>
      </ul>
      <ul class="lst-kix_baogfub2sn92-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            tujuan bisnis atau komersial untuk mengumpulkan, menjual, atau
            berbagi informasi pribadi; Dan
          </span>
        </li>
      </ul>
      <ul class="lst-kix_bgxyo9jydcp6-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            bagian spesifik dari informasi pribadi yang kami kumpulkan tentang
            Anda.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c1">
          Sesuai dengan hukum yang berlaku, kami tidak berkewajiban untuk
          memberikan atau menghapus informasi konsumen yang tidak
          teridentifikasi sebagai tanggapan atas permintaan konsumen atau untuk
          mengidentifikasi ulang data individu untuk memverifikasi permintaan
          konsumen.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c6">
          Hak Non-Diskriminasi dalam Penggunaan Hak Privasi Konsumen
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami tidak akan mendiskriminasi Anda jika Anda menggunakan hak privasi
          Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c6">
          Hak untuk Membatasi Penggunaan dan Pengungkapan Informasi Pribadi
          Sensitif
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami tidak memproses informasi pribadi sensitif konsumen.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c6">Proses verifikasi</span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Setelah menerima permintaan Anda, kami perlu memverifikasi identitas
          Anda untuk menentukan bahwa Anda adalah orang yang sama yang
          informasinya kami miliki di sistem kami. Upaya verifikasi ini
          mengharuskan kami meminta Anda memberikan informasi sehingga kami
          dapat mencocokkannya dengan informasi yang Anda berikan sebelumnya
          kepada kami. Misalnya, tergantung pada jenis permintaan yang Anda
          kirimkan, kami mungkin meminta Anda untuk memberikan informasi
          tertentu sehingga kami dapat mencocokkan informasi yang Anda berikan
          dengan informasi yang sudah kami simpan, atau kami dapat menghubungi
          Anda melalui metode komunikasi (misalnya telepon atau email) yang
          sebelumnya Anda berikan kepada kami. Kami juga dapat menggunakan
          metode verifikasi lain sesuai keadaan.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami hanya akan menggunakan informasi pribadi yang diberikan dalam
          permintaan Anda untuk memverifikasi identitas atau wewenang Anda untuk
          membuat permintaan. Sebisa mungkin, kami akan menghindari permintaan
          informasi tambahan dari Anda untuk keperluan verifikasi. Namun, jika
          kami tidak dapat memverifikasi identitas Anda berdasarkan informasi
          yang telah kami simpan, kami dapat meminta Anda memberikan informasi
          tambahan untuk keperluan verifikasi identitas Anda dan untuk tujuan
          keamanan atau pencegahan penipuan. Kami akan menghapus informasi
          tambahan yang diberikan tersebut segera setelah kami selesai
          memverifikasi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c6">Hak privasi lainnya</span>
      </p>
      <ul class="lst-kix_gl8rannxapfy-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Anda mungkin keberatan dengan pemrosesan informasi pribadi Anda.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_icctsqfgnucc-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Anda dapat meminta koreksi atas data pribadi Anda jika data tersebut
            salah atau tidak relevan lagi, atau meminta untuk membatasi
            pemrosesan informasi tersebut.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_cedzcn6ujqqk-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Anda dapat menunjuk agen resmi untuk mengajukan permintaan
            berdasarkan CCPA atas nama Anda. Kami dapat menolak permintaan dari
            agen resmi yang tidak memberikan bukti bahwa mereka telah diberi
            wewenang secara sah untuk bertindak atas nama Anda sesuai dengan
            CCPA.
          </span>
        </li>
      </ul>
      <ul class="lst-kix_2obzlqgqx45d-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Anda dapat meminta untuk tidak ikut serta dalam penjualan atau
            pembagian informasi pribadi Anda di masa mendatang kepada pihak
            ketiga. Setelah menerima permintaan untuk tidak ikut serta, kami
            akan menindaklanjuti permintaan tersebut sesegera mungkin, namun
            tidak lebih dari lima belas (15) hari sejak tanggal pengajuan
            permintaan.
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c1">
          Untuk menggunakan hak ini, Anda dapat menghubungi kami melalui email
          di hello@tigrisstudios.com, atau dengan mengacu pada rincian kontak di
          bagian bawah dokumen ini. Jika Anda memiliki keluhan tentang cara kami
          menangani data Anda, kami ingin mendengar pendapat Anda.
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c35 c30 c27">Penduduk Colorado</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Bagian ini hanya berlaku untuk penduduk Colorado. Berdasarkan Colorado
          Privacy Act (CPA), Anda memiliki hak yang tercantum di bawah. Namun,
          hak-hak ini tidak bersifat mutlak, dan dalam kasus tertentu, kami
          dapat menolak permintaan Anda sebagaimana diizinkan oleh hukum.
        </span>
      </p>
      <ul class="lst-kix_kq9jsr4wsixe-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk diberi tahu apakah kami sedang memproses data pribadi Anda
            atau tidak
          </span>
        </li>
      </ul>
      <ul class="lst-kix_9nm3cmgpqznm-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">Hak untuk mengakses data pribadi Anda</span>
        </li>
      </ul>
      <ul class="lst-kix_vl0okbdkm76u-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk memperbaiki ketidakakuratan dalam data pribadi Anda
          </span>
        </li>
      </ul>
      <ul class="lst-kix_jf547gvjbsic-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk meminta penghapusan data pribadi Anda
          </span>
        </li>
      </ul>
      <ul class="lst-kix_jq2o2r9az4fn-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk mendapatkan salinan data pribadi yang sebelumnya Anda
            bagikan kepada kami
          </span>
        </li>
      </ul>
      <ul class="lst-kix_gtx5blt0msqe-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk tidak ikut serta dalam pemrosesan data pribadi Anda jika
            data tersebut digunakan untuk iklan bertarget, penjualan data
            pribadi, atau pembuatan profil sebagai kelanjutan dari keputusan
            yang menghasilkan dampak signifikan secara hukum atau sejenisnya
            (&ldquo;pembuatan profil&rdquo;)
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c33">Untuk mengajukan permintaan untuk berolahraga</span>
        <span class="c14">&nbsp;</span>
        <span class="c33">ini</span>
        <span class="c14">&nbsp;</span>
        <span class="c33">
          hak yang dijelaskan di atas, silakan kirim email
        </span>
        <span class="c14">&nbsp;</span>
        <span class="c33">halo@tigrisstudios.com.</span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Jika kami menolak mengambil tindakan terkait permintaan Anda dan Anda
          ingin mengajukan banding atas keputusan kami, silakan kirim email
          kepada kami di hello@tigrisstudios.com. Dalam waktu empat puluh lima
          (45) hari sejak diterimanya permohonan banding, kami akan memberi tahu
          Anda secara tertulis mengenai tindakan apa pun yang diambil atau tidak
          diambil sebagai tanggapan terhadap banding tersebut, termasuk
          penjelasan tertulis tentang alasan keputusan tersebut.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c35 c30 c27">Penduduk Connecticut</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Bagian ini hanya berlaku untuk penduduk Connecticut. Berdasarkan
          Undang-Undang Privasi Data Connecticut (CTDPA), Anda memiliki hak-hak
          yang tercantum di bawah. Namun, hak-hak ini tidak bersifat mutlak, dan
          dalam kasus tertentu, kami dapat menolak permintaan Anda sebagaimana
          diizinkan oleh hukum.
        </span>
      </p>
      <ul class="lst-kix_9hu1d5sw3kuz-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk diberi tahu apakah kami sedang memproses data pribadi Anda
            atau tidak
          </span>
        </li>
      </ul>
      <ul class="lst-kix_j7bul7xldl12-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">Hak untuk mengakses data pribadi Anda</span>
        </li>
      </ul>
      <ul class="lst-kix_fs5bwse5y5-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk memperbaiki ketidakakuratan dalam data pribadi Anda
          </span>
        </li>
      </ul>
      <ul class="lst-kix_x0r2u23iwdm0-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk meminta penghapusan data pribadi Anda
          </span>
        </li>
      </ul>
      <ul class="lst-kix_dboy6qr3f5wl-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk mendapatkan salinan data pribadi yang sebelumnya Anda
            bagikan kepada kami
          </span>
        </li>
      </ul>
      <ul class="lst-kix_mj0dxg694dar-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk tidak ikut serta dalam pemrosesan data pribadi Anda jika
            data tersebut digunakan untuk iklan bertarget, penjualan data
            pribadi, atau pembuatan profil sebagai kelanjutan dari keputusan
            yang menghasilkan dampak signifikan secara hukum atau sejenisnya
            (&ldquo;pembuatan profil&rdquo;)
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c1">
          Untuk mengajukan permintaan untuk menggunakan hak-hak yang dijelaskan
          di atas, silakan kirim email ke hello@tigrisstudios.com.
        </span>
      </p>
      <p class="c8">
        <span class="c1">
          Jika kami menolak mengambil tindakan terkait permintaan Anda dan Anda
          ingin mengajukan banding atas keputusan kami, silakan kirim email
          kepada kami di hello@tigrisstudios.com. Dalam waktu enam puluh (60)
          hari sejak diterimanya pengajuan banding, kami akan memberi tahu Anda
          secara tertulis mengenai tindakan apa pun yang diambil atau tidak
          diambil sebagai tanggapan atas banding tersebut, termasuk penjelasan
          tertulis tentang alasan keputusan tersebut.
        </span>
      </p>
      <p class="c8 c42">
        <span class="c25"></span>
      </p>
      <p class="c8">
        <span class="c35 c30 c27">Penduduk Utah</span>
      </p>
      <p class="c8 c42">
        <span class="c25"></span>
      </p>
      <p class="c8">
        <span class="c1">
          Bagian ini hanya berlaku untuk penduduk Utah. Berdasarkan
          Undang-Undang Privasi Konsumen Utah (UCPA), Anda memiliki hak-hak yang
          tercantum di bawah. Namun, hak-hak ini tidak bersifat mutlak, dan
          dalam kasus tertentu, kami dapat menolak permintaan Anda sebagaimana
          diizinkan oleh hukum.
        </span>
      </p>
      <ul class="lst-kix_ijyzkf1x8ce2-0 start">
        <li class="c47 li-bullet-0">
          <span class="c1">
            Hak untuk diberi tahu apakah kami sedang memproses data pribadi Anda
            atau tidak
          </span>
        </li>
      </ul>
      <ul class="lst-kix_5aaluz8fl6pj-0 start">
        <li class="c47 li-bullet-0">
          <span class="c1">Hak untuk mengakses data pribadi Anda</span>
        </li>
      </ul>
      <ul class="lst-kix_mvyo1nbatgq1-0 start">
        <li class="c47 li-bullet-0">
          <span class="c1">
            Hak untuk meminta penghapusan data pribadi Anda
          </span>
        </li>
      </ul>
      <ul class="lst-kix_4n0cwh4t78fm-0 start">
        <li class="c47 li-bullet-0">
          <span class="c1">
            Hak untuk mendapatkan salinan data pribadi yang sebelumnya Anda
            bagikan kepada kami
          </span>
        </li>
      </ul>
      <ul class="lst-kix_i67fusm9beap-0 start">
        <li class="c47 li-bullet-0">
          <span class="c1">
            Hak untuk tidak ikut serta dalam pemrosesan data pribadi Anda jika
            data tersebut digunakan untuk iklan bertarget atau penjualan data
            pribadi
          </span>
        </li>
      </ul>
      <p class="c8">
        <span class="c33">
          Untuk mengajukan permintaan untuk menggunakan hak-hak yang dijelaskan
          di atas, silakan kirim email
        </span>
        <span class="c14">halo@tigrisstudios.com</span>
        <span class="c29">.</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c35 c30 c27">Penduduk Virginia</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Berdasarkan Undang-Undang Perlindungan Data Konsumen Virginia (VCDPA):
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          &#39;Konsumen&#39; berarti orang perseorangan yang merupakan penduduk
          Persemakmuran yang bertindak hanya dalam konteks individu atau rumah
          tangga. Hal ini tidak mencakup orang perseorangan yang bertindak dalam
          konteks komersial atau pekerjaan.
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          &#39;Data pribadi&#39; berarti informasi apa pun yang terkait atau
          dapat ditautkan secara wajar ke orang perorangan yang teridentifikasi
          atau dapat diidentifikasi. &#39;Data pribadi&#39; tidak termasuk data
          yang tidak teridentifikasi atau informasi yang tersedia untuk umum.
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          &#39;Penjualan data pribadi&#39; berarti pertukaran data pribadi untuk
          pertimbangan keuangan.
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Jika definisi &#39;konsumen&#39; ini berlaku untuk Anda, kami harus
          mematuhi hak dan kewajiban tertentu terkait data pribadi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c6">Hak Anda sehubungan dengan data pribadi Anda</span>
      </p>
      <ul class="lst-kix_edeihoy2hczq-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk diberi tahu apakah kami sedang memproses data pribadi Anda
            atau tidak
          </span>
        </li>
      </ul>
      <ul class="lst-kix_ev7fjw60cxp0-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">Hak untuk mengakses data pribadi Anda</span>
        </li>
      </ul>
      <ul class="lst-kix_5jt5v11b12jv-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk memperbaiki ketidakakuratan dalam data pribadi Anda
          </span>
        </li>
      </ul>
      <ul class="lst-kix_9kccf4qc7vte-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk meminta penghapusan data pribadi Anda
          </span>
        </li>
      </ul>
      <ul class="lst-kix_s1069itq09s3-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk mendapatkan salinan data pribadi yang sebelumnya Anda
            bagikan kepada kami
          </span>
        </li>
      </ul>
      <ul class="lst-kix_row4f2oyvtz4-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            Hak untuk tidak ikut serta dalam pemrosesan data pribadi Anda jika
            data tersebut digunakan untuk iklan bertarget, penjualan data
            pribadi, atau pembuatan profil sebagai kelanjutan dari keputusan
            yang menghasilkan dampak signifikan secara hukum atau sejenisnya
            (&ldquo;pembuatan profil&rdquo;)
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c6">
          Gunakan hak Anda yang diberikan berdasarkan VCDPA Virginia
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Anda dapat menghubungi kami melalui email di hello@tigrisstudios.com.
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Jika Anda menggunakan agen resmi untuk melaksanakan hak Anda, kami
          dapat menolak permintaan jika agen resmi tersebut tidak memberikan
          bukti bahwa mereka telah diberi wewenang secara sah untuk bertindak
          atas nama Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c6">Proses verifikasi</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami mungkin meminta Anda memberikan informasi tambahan yang
          diperlukan secara wajar untuk memverifikasi permintaan Anda dan
          konsumen Anda. Jika Anda mengajukan permintaan melalui agen resmi,
          kami mungkin perlu mengumpulkan informasi tambahan untuk memverifikasi
          identitas Anda sebelum memproses permintaan Anda.
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Setelah menerima permintaan Anda, kami akan merespons tanpa penundaan
          yang tidak semestinya, namun dalam semua kasus, dalam waktu empat
          puluh lima (45) hari sejak diterimanya. Periode tanggapan dapat
          diperpanjang satu kali selama empat puluh lima (45) hari tambahan bila
          diperlukan. Kami akan memberi tahu Anda tentang perpanjangan tersebut
          dalam periode respons awal 45 hari, beserta alasan perpanjangannya.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c6">Hak untuk mengajukan banding</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c14">
          Jika kami menolak mengambil tindakan terkait permintaan Anda, kami
          akan memberi tahu Anda tentang keputusan kami dan alasan di baliknya.
          Jika Anda ingin mengajukan banding atas keputusan kami, silakan kirim
          email kepada kami di hello@tigrisstudios.com. Dalam waktu enam puluh
          (60) hari sejak diterimanya pengajuan banding, kami akan memberi tahu
          Anda secara tertulis mengenai tindakan apa pun yang diambil atau tidak
          diambil sebagai tanggapan atas banding tersebut, termasuk penjelasan
          tertulis tentang alasan keputusan tersebut.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c21">
          13. APAKAH DAERAH LAIN PUNYA HAK PRIVASI KHUSUS?
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          &nbsp;Anda mungkin memiliki hak tambahan berdasarkan negara tempat
          Anda tinggal.
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c30 c27">Australia</span>
        <span class="c27">&nbsp;</span>
        <span class="c30 c27">Dan</span>
        <span class="c27">&nbsp;</span>
        <span class="c35 c30 c27">Selandia Baru</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami mengumpulkan dan memproses informasi pribadi Anda berdasarkan
          kewajiban dan ketentuan yang ditetapkan oleh Undang-Undang Privasi
          Australia tahun 1988 dan Undang-undang Privasi Selandia Baru tahun
          2020 (Undang-Undang Privasi).
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Pemberitahuan privasi ini memenuhi persyaratan pemberitahuan yang
          ditentukan dalam kedua Undang-undang Privasi, khususnya: informasi
          pribadi apa yang kami kumpulkan dari Anda, dari sumber mana, untuk
          tujuan apa, dan penerima informasi pribadi Anda lainnya.
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Jika Anda tidak ingin memberikan informasi pribadi yang diperlukan
          untuk memenuhi tujuan yang berlaku, hal ini dapat memengaruhi
          kemampuan kami untuk menyediakan layanan kami, khususnya:
        </span>
      </p>
      <ul class="lst-kix_65978ddppnfj-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            menawarkan produk atau layanan yang Anda inginkan
          </span>
        </li>
      </ul>
      <ul class="lst-kix_16ts5fjjnjyd-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">menanggapi atau membantu permintaan Anda</span>
        </li>
      </ul>
      <ul class="lst-kix_uzef32rqso1j-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">kelola akun Anda bersama kami</span>
        </li>
      </ul>
      <ul class="lst-kix_8hync06jx33q-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1">
            konfirmasikan identitas Anda dan lindungi akun Anda
          </span>
        </li>
      </ul>
      <p class="privacy-text">
        <span class="c14">
          Kapan saja, Anda berhak meminta akses atau koreksi informasi pribadi
          Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c51 c60">SAYA</span>
        <span class="c14">
          jika Anda yakin kami memproses informasi pribadi Anda secara melawan
          hukum, Anda berhak mengajukan keluhan tentang pelanggaran Prinsip
          Privasi Australia kepada
        </span>
        <span class="c13">
          <a
            class="c22"
            href="https://www.google.com/url?q=https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us&amp;sa=D&amp;source=editors&amp;ust=1710525807430424&amp;usg=AOvVaw0_C0fvs70_kmndp2Q66wQn"
          >
            Kantor Komisaris Informasi Australia
          </a>
        </span>
        <span class="c14">
          &nbsp;dan pelanggaran Prinsip Privasi Selandia Baru terhadap
        </span>
        <span class="c13">
          <a
            class="c22"
            href="https://www.google.com/url?q=https://www.privacy.org.nz/your-rights/making-a-complaint/&amp;sa=D&amp;source=editors&amp;ust=1710525807430788&amp;usg=AOvVaw3l2BFIcDA1rRoiqYB0q0Pu"
          >
            Kantor Komisaris Privasi Selandia Baru
          </a>
        </span>
        <span class="c1">.</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c35 c30 c27">Republik Afrika Selatan</span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c14">
          Kapan saja, Anda berhak meminta akses atau koreksi informasi pribadi
          Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Jika Anda tidak puas dengan cara kami menangani keluhan apa pun
          sehubungan dengan pemrosesan informasi pribadi kami, Anda dapat
          menghubungi kantor regulator, yang rinciannya adalah:
        </span>
      </p>
      <p class="c3">
        <span class="c25"></span>
      </p>
      <p class="privacy-text">
        <span class="c13">
          <a
            class="c22"
            href="https://www.google.com/url?q=https://inforegulator.org.za/&amp;sa=D&amp;source=editors&amp;ust=1710525807431655&amp;usg=AOvVaw3smfEJe2IxndqrMjB_e8zk"
          >
            Regulator Informasi (Afrika Selatan)
          </a>
        </span>
      </p>
      <p class="privacy-text">
        <span class="c33">Pertanyaan Umum:</span>
        <span class="c40">pertanyaan@inforegulator.org.za</span>
      </p>
      <p class="privacy-text">
        <span class="c33">Pengaduan (lengkapi formulir POPIA/PAIA 5):</span>
        <span class="c49">PAIAComplaints@inforegulator.org.za</span>
        <span class="c33">&nbsp;&amp;</span>
        <span class="c40">POPIAComplaints@inforegulator.org.za</span>
      </p>
      <p class="privacy-text">
        <span class="c21">14. APAKAH KAMI MEMPERBARUI PEMBERITAHUAN INI?</span>
      </p>
      <p class="privacy-text">
        <span class="c14 c17">Pendeknya:</span>
        <span class="c11">
          Ya, kami akan memperbarui pemberitahuan ini seperlunya agar tetap
          mematuhi undang-undang yang relevan.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Kami dapat memperbarui pemberitahuan privasi ini dari waktu ke waktu.
          Versi yang diperbarui akan ditandai dengan tanggal &#39;Revisi&#39;
          yang diperbarui dan versi yang diperbarui akan berlaku segera setelah
          dapat diakses. Jika kami melakukan perubahan penting terhadap
          pemberitahuan privasi ini, kami dapat memberi tahu Anda dengan
          memasang pemberitahuan secara jelas mengenai perubahan tersebut atau
          dengan mengirimkan pemberitahuan secara langsung kepada Anda. Kami
          mendorong Anda untuk meninjau pemberitahuan privasi ini sesering
          mungkin untuk mengetahui cara kami melindungi informasi Anda.
        </span>
      </p>
      <p class="privacy-text">
        <span class="c21">
          15. BAGAIMANA ANDA DAPAT MENGHUBUNGI KAMI TENTANG PEMBERITAHUAN INI?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">
          Jika Anda memiliki pertanyaan atau komentar tentang pemberitahuan ini,
          Anda dapat mengirim email kepada kami di hello@tigrisstudios.com atau
          menghubungi kami melalui pos di:
        </span>
      </p>
      <p class="privacy-text">
        <span class="c1">Studio Tigris FZCO</span>
      </p>
      <p class="privacy-text">
        <span class="c1">16235-001, Taman Bisnis IFZA, DDP</span>
      </p>
      <p class="privacy-text">
        <span class="c14">Dubai</span>
        <span class="c33">,</span>
        <span class="c1">UEA</span>
      </p>
      <p class="privacy-text">
        <span class="c1">Uni Emirat Arab</span>
      </p>
      <p class="privacy-text">
        <span class="c21">
          16. BAGAIMANA ANDA DAPAT MENINJAU, MEMPERBARUI, ATAU MENGHAPUS DATA
          YANG KAMI KUMPULKAN DARI ANDA?
        </span>
      </p>
      <p class="privacy-text">
        <span class="c14">
          Anda berhak meminta akses terhadap informasi pribadi yang kami
          kumpulkan dari Anda, mengubah informasi tersebut, atau menghapusnya.
        </span>
      </p>
      <p class="c31">
        <span class="c35 c51 c57"></span>
      </p>
      <p class="c31">
        <span class="c23"></span>
      </p>
      <p class="c31">
        <span class="c35 c51 c57"></span>
      </p>
    </div>
  );
};

export default PrivacyPolicyInd;
