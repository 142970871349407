import React, { useState } from "react";
import { ICONS } from "../../assets";
import "./styles.css";

const LanguageSelect = ({ language, onSelect, options }) => {
  const [isShow, setShow] = useState(false);

  return (
    <div className="wrap" onClick={() => setShow(!isShow)}>
      <div className="select" style={{ backgroundColor: "white" }}>
        <div className="value-wrap">
          <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
            {language.icon}
            <p className="language">{language.label}</p>
          </div>

          <ICONS.selectIcon />
        </div>
      </div>

      {isShow && (
        <div className="dropdown" style={{ backgroundColor: "white" }}>
          {options.map((option) => {
            return (
              <div
                key={option.value}
                className="dropdown-value-wrap"
                onClick={() => onSelect(option)}
              >
                <div className="optionWrap">
                  {/* {option.icon} */}
                  <p className="language">{option.label}</p>
                </div>

                {option.value === language.value ? <ICONS.check /> : null}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanguageSelect;
