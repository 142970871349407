const cross = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.462484 0.462462C1.0791 -0.154155 2.07884 -0.154154 2.69545 0.462464L10 7.76702L17.3046 0.462463C17.9212 -0.154154 18.9209 -0.154154 19.5375 0.462463C20.1542 1.07908 20.1542 2.07881 19.5375 2.69543L12.233 10L19.5375 17.3046C20.1542 17.9212 20.1542 18.9209 19.5375 19.5375C18.9209 20.1542 17.9212 20.1542 17.3046 19.5375L10 12.233L2.69543 19.5375C2.07881 20.1542 1.07908 20.1542 0.462463 19.5375C-0.154154 18.9209 -0.154154 17.9212 0.462463 17.3046L7.76704 9.99999L0.462482 2.69543C-0.154135 2.07881 -0.154134 1.07908 0.462484 0.462462Z"
      fill="black"
    />
  </svg>
);

export default cross;
