const checkIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3096 5.19046C17.6646 5.54548 17.6646 6.12109 17.3096 6.47611L8.1429 15.6428C7.97241 15.8133 7.74118 15.909 7.50007 15.909C7.25897 15.909 7.02774 15.8133 6.85725 15.6428L2.69058 11.4761C2.33556 11.1211 2.33556 10.5455 2.69058 10.1905C3.04561 9.83544 3.62121 9.83544 3.97623 10.1905L7.50007 13.7143L16.0239 5.19046C16.3789 4.83544 16.9545 4.83544 17.3096 5.19046Z"
      fill="#3CB6C1"
    />
  </svg>
);

export default checkIcon;
