import React from "react";

export const TermsOfUseInd = () => {  
  return (
    <>
      <p class="privacy-title">SYARAT PENGGUNAAN</p>
      <p style={{ fontWeight: 700 }} class="privacy-text">
        Terakhir diperbarui 04 Maret 2024
      </p>
      <p class="privacy-subtitle">PERSETUJUAN TERHADAP KETENTUAN HUKUM KAMI</p>
      <p class="privacy-text">
        KitaStudio Tigris FZCO ('Perusahaan','Kami','kita', atau 'kita'),
        perusahaan yang terdaftar diUni Emirat Arab pada16235-001, IFZA Business
        Park, DDP, Dubai, UEA.
      </p>
      <p class="privacy-text">
        Kami mengoperasikan aplikasi selulerDia (yang 'Aplikasi'), serta produk
        dan layanan terkait lainnya yang mengacu atau tertaut ke ketentuan hukum
        ini ('Ketentuan Hukum') (secara kolektif disebut 'Jasa').
      </p>
      <p class="privacy-text">
        Lacak shalat Anda dan jangan pernah meninggalkan apa pun qada! Apakah
        Anda pernah melewatkan shalat karena suatu alasan? Apakah Anda ingin
        mengqadha tetapi lupa salat mana yang terlewat? Niya ada di sini untuk
        membantu! Niya ada di sini untuk membantu
      </p>
      <p class="privacy-text">
        Anda dapat menghubungi kami melaluiemail di hello@tigrisstudios.com atau
        melalui surat ke 16235-001, IFZA Business Park, DDP, Dubai, UAE, United
        Arab Emirates.
      </p>
      <p class="privacy-text">
        Ketentuan Hukum ini merupakan perjanjian yang mengikat secara hukum yang
        dibuat antara Anda, baik secara pribadi atau atas nama suatu entitas
        ('Anda'), DanStudio Tigris FZCO, mengenai akses Anda dan penggunaan
        Layanan. Anda setuju bahwa dengan mengakses Layanan, Anda telah membaca,
        memahami, dan setuju untuk terikat oleh seluruh Ketentuan Hukum ini.
        JIKA ANDA TIDAK SETUJU DENGAN SEMUA KETENTUAN HUKUM INI, MAKA ANDA
        SECARA TEGAS DILARANG MENGGUNAKAN LAYANAN INI DAN ANDA HARUS SEGERA
        MENGHENTIKAN PENGGUNAAN.
      </p>
      <p class="privacy-text">
        Syarat dan ketentuan tambahan atau dokumen yang mungkin diposting pada
        Layanan dari waktu ke waktu dengan ini secara tegas disertakan di sini
        sebagai referensi. Kami berhak, berdasarkan kebijakan kami sendiri,
        untuk melakukan perubahan atau modifikasi terhadap Ketentuan Hukum ini
        dari waktu ke waktu. Kami akan mengingatkan Anda tentang perubahan apa
        pun dengan memperbarui tanggal 'Terakhir diperbarui' pada Ketentuan
        Hukum ini, dan Anda melepaskan hak apa pun untuk menerima pemberitahuan
        spesifik mengenai setiap perubahan tersebut. Anda bertanggung jawab
        untuk meninjau Ketentuan Hukum ini secara berkala agar tetap mendapat
        informasi terkini. Anda akan tunduk pada, dan akan dianggap telah
        mengetahui dan menerima, perubahan-perubahan dalam Ketentuan Hukum yang
        direvisi dengan terus menggunakan Layanan setelah tanggal Ketentuan
        Hukum yang direvisi tersebut diposting.
      </p>
      <p class="privacy-text">
        Semua pengguna yang masih di bawah umur di yurisdiksi tempat mereka
        tinggal (umumnya berusia di bawah 18 tahun) harus mendapat izin, dan
        diawasi langsung oleh, orang tua atau wali mereka untuk menggunakan
        Layanan. Jika Anda masih di bawah umur, Anda harus meminta orang tua
        atau wali Anda membaca dan menyetujui Ketentuan Hukum ini sebelum Anda
        menggunakan Layanan.
      </p>
      <p class="privacy-text">
        Kami menyarankan Anda mencetak salinan Ketentuan Hukum ini untuk catatan
        Anda.
      </p>
      <p class="privacy-subtitle">DAFTAR ISI</p>
      <p class="privacy-subtitle">1. LAYANAN KAMI</p>
      <p class="privacy-text">
        Informasi yang diberikan saat menggunakan Layanan tidak dimaksudkan
        untuk didistribusikan kepada atau digunakan oleh orang atau entitas mana
        pun di yurisdiksi atau negara mana pun di mana distribusi atau
        penggunaan tersebut akan bertentangan dengan undang-undang atau
        peraturan atau yang akan membuat kami tunduk pada persyaratan
        pendaftaran apa pun dalam yurisdiksi tersebut atau negara. Oleh karena
        itu, orang-orang yang memilih untuk mengakses Layanan dari lokasi lain
        melakukannya atas inisiatif mereka sendiri dan sepenuhnya bertanggung
        jawab untuk mematuhi undang-undang setempat, jika dan sejauh
        undang-undang setempat berlaku.
      </p>
      <p class="privacy-text">
        Layanan tidak dirancang untuk mematuhi peraturan khusus industri
        (Undang-Undang Portabilitas dan Akuntabilitas Asuransi Kesehatan
        (HIPAA), Undang-Undang Manajemen Keamanan Informasi Federal (FISMA),
        dll.), jadi jika interaksi Anda akan tunduk pada undang-undang tersebut,
        Anda tidak boleh menggunakan Layanan. Anda tidak boleh menggunakan
        Layanan dengan cara yang melanggar Gramm-Leach-Bliley Act (GLBA).
      </p>
      <p class="privacy-subtitle">2. HAK KEKAYAAN INTELEKTUAL</p>
      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Kekayaan intelektual kami
      </p>
      <p class="privacy-text">
        Kami adalah pemilik atau penerima lisensi semua hak kekayaan intelektual
        dalam Layanan kami, termasuk semua kode sumber, basis data,
        fungsionalitas, perangkat lunak, desain situs web, audio, video, teks,
        foto, dan grafik dalam Layanan (secara kolektif disebut 'Konten' ),
        serta merek dagang, merek layanan, dan logo yang terkandung di dalamnya
        (“Merek”).
      </p>
      <p class="privacy-text">
        Konten dan Merek kami dilindungi oleh undang-undang hak cipta dan merek
        dagang (dan berbagai undang-undang hak kekayaan intelektual dan
        persaingan tidak sehat lainnya) serta perjanjian di Amerika Serikat dan
        di seluruh dunia.
      </p>
      <p class="privacy-text">
        Konten dan Merek disediakan dalam atau melalui Layanan 'SEBAGAIMANA
        ADANYA' untuk penggunaan pribadi, nonkomersial, atau tujuan bisnis
        internal Anda saja.
      </p>
      <p class="privacy-subtitle">Penggunaan Anda atas Layanan kami</p>
      <p class="privacy-text">
        Tunduk pada kepatuhan Anda terhadap Ketentuan Hukum ini, kami memberi
        Anda lisensi non-eksklusif, tidak dapat dipindahtangankan, dan dapat
        dibatalkan untuk:
      </p>
      <ul style={{ listStyleType: "square", marginLeft: 50 }}>
        <li class="privacy-text">mengakses Layanan, dan</li>
        <li class="privacy-text">
          mengunduh atau mencetak salinan bagian mana pun dari Konten yang
          aksesnya telah Anda peroleh dengan benar.
        </li>
      </ul>
      <p class="privacy-text">
        semata-mata untuk penggunaan pribadi, non-komersial, atau tujuan bisnis
        internal Anda.
      </p>
      <p class="privacy-text">
        Kecuali sebagaimana diatur dalam bagian ini atau di tempat lain dalam
        Ketentuan Hukum kami, tidak ada bagian dari Layanan dan tidak ada Konten
        atau Merek yang boleh disalin, direproduksi, dikumpulkan, diterbitkan
        ulang, diunggah, diposting, ditampilkan secara publik, dikodekan,
        diterjemahkan, ditransmisikan, didistribusikan, dijual , dilisensikan,
        atau dieksploitasi untuk tujuan komersial apa pun, tanpa izin tertulis
        sebelumnya dari kami.
      </p>
      <p class="privacy-text">
        Jika Anda ingin menggunakan Layanan, Konten, atau Merek selain yang
        ditetapkan dalam bagian ini atau di tempat lain dalam Ketentuan Hukum
        kami, harap sampaikan permintaan Anda ke:halo@tigrisstudios.com. Jika
        kami memberi Anda izin untuk memposting, mereproduksi, atau menampilkan
        secara publik bagian apa pun dari Layanan atau Konten kami, Anda harus
        mengidentifikasi kami sebagai pemilik atau pemberi lisensi Layanan,
        Konten, atau Merek dan memastikan bahwa pemberitahuan hak cipta atau hak
        milik apa pun muncul atau terlihat saat memposting, mereproduksi, atau
        menampilkan Konten kami.
      </p>
      <p class="privacy-text">
        Kami mempunyai semua hak yang tidak secara tegas diberikan kepada Anda
        dalam dan terhadap Layanan, Konten, dan Merek.
      </p>
      <p class="privacy-text">
        Setiap pelanggaran terhadap Hak Kekayaan Intelektual ini merupakan
        pelanggaran material terhadap Ketentuan Hukum kami dan hak Anda untuk
        menggunakan Layanan kami akan segera dihentikan.
      </p>
      <p class="privacy-subtitle">Kiriman Anda</p>
      <p class="privacy-text">
        Harap tinjau bagian inisecara cermat sebelum menggunakan Layanan kami
        untuk memahami (a) hak yang Anda berikan kepada kami dan (b) kewajiban
        yang Anda miliki ketika Anda memposting atau mengunggah konten apa pun
        melalui Layanan.
      </p>
      <p class="privacy-text">
        <span style={{ fontWeight: 700 }}>Kiriman:</span> Dengan mengirimkan
        secara langsung kepada kami pertanyaan, komentar, saran, ide, umpan
        balik, atau informasi lain tentang Layanan (“Pengajuan”), Anda setuju
        untuk menyerahkan kepada kami semua hak kekayaan intelektual dalam
        Pengajuan tersebut. Anda setuju bahwa kami akan memiliki Kiriman ini dan
        berhak atas penggunaan dan penyebarannya yang tidak dibatasi untuk
        tujuan apa pun yang sah, komersial atau lainnya, tanpa pengakuan atau
        kompensasi kepada Anda.
      </p>
      <p class="privacy-text">
        Anda bertanggung jawab atas apa yang Anda posting atau unggah: Dengan
        mengirimkan Kiriman kepada kami melalui bagian mana pun dari Layanan,
        Anda:
      </p>
      <ul style={{ listStyleType: "square", marginLeft: 50 }}>
        <li class="privacy-text">
          konfirmasikan bahwa Andatidak akan memposting, mengirim, menerbitkan,
          mengunggah, atau mengirimkan melalui Layanan segala Kiriman yang
          ilegal, melecehkan, penuh kebencian, berbahaya, memfitnah, cabul,
          intimidasi, kasar, diskriminatif, mengancam orang atau kelompok mana
          pun, eksplisit secara seksual, palsu, tidak akurat , menipu, atau
          menyesatkan;
        </li>
        <li class="privacy-text">
          sejauh diizinkan oleh hukum yang berlaku, melepaskan setiap dan
          seluruh hak moral atas Kiriman tersebut;
        </li>
        <li class="privacy-text">
          menjamin bahwa Kiriman tersebut adalah asli milik Anda atau bahwa Anda
          mempunyai hak dan lisensi yang diperlukan untuk mengirimkan Kiriman
          tersebut dan bahwa Anda mempunyai wewenang penuh untuk memberi kami
          hak-hak yang disebutkan di atas sehubungan dengan Kiriman Anda; Dan
        </li>
        <li class="privacy-text">
          menjamin dan menyatakan bahwa Kiriman Anda bukan merupakan informasi
          rahasia.
        </li>
      </ul>
      <p class="privacy-text">
        Anda sepenuhnya bertanggung jawab atas Kiriman Anda dan Anda secara
        tegas setuju untuk mengganti kami atas setiap dan seluruh kerugian yang
        mungkin kami derita karena pelanggaran Anda terhadap (a) bagian ini, (b)
        hak kekayaan intelektual pihak ketiga mana pun, atau (c) hukum yang
        berlaku .
      </p>
      <p class="privacy-subtitle">3. PERWAKILAN PENGGUNA</p>
      <p class="privacy-text">
        Dengan menggunakan Layanan, Anda menyatakan dan menjamin bahwa: (1)
        semua informasi pendaftaran yang Anda kirimkan adalah benar, akurat,
        terkini, dan lengkap; (2) Anda akan menjaga keakuratan informasi
        tersebut dan segera memperbarui informasi pendaftaran tersebut jika
        diperlukan; (3) Anda mempunyai kapasitas hukum dan Anda setuju untuk
        mematuhi Ketentuan Hukum ini; (4) Anda bukan anak di bawah umur di
        yurisdiksi tempat Anda tinggal, atau jika Anda masih di bawah umur, Anda
        telah menerima izin orang tua untuk menggunakan Layanan; (5) Anda tidak
        akan mengakses Layanan melalui cara otomatis atau non-manusia, baik
        melalui bot, skrip, atau lainnya; (6) Anda tidak akan menggunakan
        Layanan untuk tujuan ilegal atau tidak sah; dan (7) penggunaan Layanan
        oleh Anda tidak akan melanggar hukum atau peraturan apa pun yang
        berlaku.
      </p>
      <p class="privacy-text">
        Jika Anda memberikan informasi apa pun yang tidak benar, tidak akurat,
        tidak terkini, atau tidak lengkap, kami berhak menangguhkan atau
        menghentikan akun Anda dan menolak setiap dan semua penggunaan Layanan
        saat ini atau di masa mendatang (atau bagian apa pun darinya).
      </p>
      <p class="privacy-subtitle">4. PENDAFTARAN PENGGUNA</p>
      <p class="privacy-text">
        Anda mungkin diminta mendaftar untuk menggunakan Layanan. Anda setuju
        untuk menjaga kerahasiaan kata sandi Anda dan akan bertanggung jawab
        atas semua penggunaan akun dan kata sandi Anda. Kami berhak menghapus,
        mengklaim kembali, atau mengubah nama pengguna yang Anda pilih jika kami
        menentukan, berdasarkan kebijakan kami sendiri, bahwa nama pengguna
        tersebut tidak pantas, tidak senonoh, atau tidak pantas.
      </p>
      <p class="privacy-subtitle">5. BERLANGGANAN</p>
      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Penagihan dan Perpanjangan
      </p>
      <p class="privacy-text">
        Langganan Anda akan berlanjut dan diperpanjang secara otomatis kecuali
        dibatalkan. Anda menyetujui kami menagih metode pembayaran Anda secara
        berulang tanpa memerlukan persetujuan Anda sebelumnya untuk setiap
        tagihan berulang, hingga Anda membatalkan pesanan yang berlaku. Lamanya
        siklus penagihan Anda akan bergantung pada jenis paket berlangganan yang
        Anda pilih saat berlangganan Layanan.
      </p>
      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Pembatalan
      </p>
      <p class="privacy-text">
        Anda dapat membatalkan langganan kapan saja dengan masuk ke akun Anda.
        Pembatalan Anda akan berlaku pada akhir jangka waktu pembayaran saat
        ini. Jika Anda memiliki pertanyaan atau tidak puas dengan Layanan kami,
        silakan kirim email kepada kami di hello@tigrisstudios.com.
      </p>
      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Perubahan Biaya
      </p>
      <p class="privacy-text">
        Kami dapat, dari waktu ke waktu, melakukan perubahan pada biaya
        berlangganan dan akan mengomunikasikan perubahan harga apa pun kepada
        Anda sesuai dengan hukum yang berlaku.
      </p>
      <p class="privacy-subtitle">6. KEGIATAN YANG DILARANG</p>
      <p class="privacy-text">
        Anda tidak boleh mengakses atau menggunakan Layanan untuk tujuan apa pun
        selain dari tujuan kami menyediakan Layanan. Layanan tidak boleh
        digunakan sehubungan dengan upaya komersial apa pun kecuali yang secara
        khusus didukung atau disetujui oleh kami.
      </p>
      <p class="privacy-text">
        Sebagai pengguna Layanan, Anda setuju untuk tidak:
      </p>
      <ul style={{ listStyleType: "square", marginLeft: 50 }}>
        <li className="privacy-text">
          Secara sistematis mengambil data atau konten lain dari Layanan untuk
          membuat atau menyusun, secara langsung atau tidak langsung, suatu
          koleksi, kompilasi, basis data, atau direktori tanpa izin tertulis
          dari kami.
        </li>
        <li className="privacy-text">
          Menipu, menipu, atau menyesatkan kami dan pengguna lain, terutama
          dalam upaya apa pun untuk mengetahui informasi akun sensitif seperti
          kata sandi pengguna.
        </li>
        <li className="privacy-text">
          Mengakali, menonaktifkan, atau mengganggu fitur-fitur terkait keamanan
          Layanan, termasuk fitur yang mencegah atau membatasi penggunaan atau
          penyalinan Konten apa pun atau menerapkan pembatasan pada penggunaan
          Layanan dan/atau Konten yang terkandung di dalamnya.
        </li>
        <li className="privacy-text">
          Meremehkan, menodai, atau merugikan, menurut pendapat kami, kami
          dan/atau Layanan.
        </li>
        <li className="privacy-text">
          Menggunakan informasi apa pun yang diperoleh dari Layanan untuk
          melecehkan, menyalahgunakan, atau merugikan orang lain.
        </li>
        <li className="privacy-text">
          Menyalahgunaan layanan dukungan kami atau mengirimkan laporan palsu
          tentang penyalahgunaan atau pelanggaran.
        </li>
        <li className="privacy-text">
          Menggunakan Layanan dengan cara yang tidak sesuai dengan undang-undang
          atau peraturan yang berlaku.
        </li>
        <li className="privacy-text">
          Terlibat dalam pembingkaian atau penautan yang tidak sah ke Layanan.
        </li>
        <li className="privacy-text">
          Mengunggah atau mengirimkan (atau mencoba mengunggah atau mengirimkan)
          virus, Trojan horse, atau materi lainnya, termasuk penggunaan huruf
          kapital yang berlebihan dan spam (pengeposan teks berulang secara
          terus-menerus), yang mengganggu penggunaan dan kenikmatan Layanan oleh
          pihak mana pun tanpa gangguan atau memodifikasi, merusak, mengganggu,
          mengubah, atau mengganggu penggunaan, fitur, fungsi, pengoperasian,
          atau pemeliharaan Layanan.
        </li>
        <li className="privacy-text">
          Terlibat dalam penggunaan sistem secara otomatis, seperti menggunakan
          skrip untuk mengirim komentar atau pesan, atau menggunakan penambangan
          data, robot, atau alat pengumpulan dan ekstraksi data serupa.
        </li>
        <li className="privacy-text">
          Hapus pemberitahuan hak cipta atau hak kepemilikan lainnya dari Konten
          apa pun.
        </li>
        <li className="privacy-text">
          Mencoba meniru identitas pengguna atau orang lain atau menggunakan
          nama pengguna pengguna lain.
        </li>
        <li className="privacy-text">
          Mengunggah atau mengirimkan (atau mencoba mengunggah atau mengirimkan)
          materi apa pun yang bertindak sebagai mekanisme pengumpulan atau
          transmisi informasi pasif atau aktif, termasuk namun tidak terbatas
          pada, format pertukaran grafik yang jelas ('gif'), piksel 1×1, bug
          web, cookie , atau perangkat serupa lainnya (terkadang disebut sebagai
          'spyware' atau 'mekanisme pengumpulan pasif' atau 'pcms').
        </li>
        <li className="privacy-text">
          Mengganggu, mengganggu, atau menciptakan beban yang tidak semestinya
          pada Layanan atau jaringan atau layanan yang terhubung ke Layanan.
        </li>
        <li className="privacy-text">
          Melecehkan, mengganggu, mengintimidasi, atau mengancam karyawan atau
          agen kami yang terlibat dalam menyediakan sebagian Layanan kepada
          Anda.
        </li>
        <li className="privacy-text">
          Mencoba untuk mengabaikan segala tindakan Layanan yang dirancang untuk
          mencegah atau membatasi akses ke Layanan, atau bagian mana pun dari
          Layanan.
        </li>
        <li className="privacy-text">
          Menyalin atau mengadaptasi perangkat lunak Layanan, termasuk namun
          tidak terbatas pada Flash, PHP, HTML, JavaScript, atau kode lainnya.
        </li>
        <li className="privacy-text">
          Kecuali jika diizinkan oleh undang-undang yang berlaku, menguraikan,
          mendekompilasi, membongkar, atau merekayasa balik perangkat lunak mana
          pun yang terdiri atau dengan cara apa pun menjadi bagian dari Layanan.
        </li>
        <li className="privacy-text">
          Kecuali mungkin akibat dari penggunaan mesin pencari atau browser
          Internet standar, penggunaan, peluncuran, pengembangan, atau
          distribusi sistem otomatis apa pun, termasuk namun tidak terbatas
          pada, spider, robot, utilitas cheat, scraper, atau pembaca offline apa
          pun yang mengakses Layanan, atau menggunakan atau meluncurkan skrip
          atau perangkat lunak lain yang tidak sah.
        </li>
        <li className="privacy-text">
          Menggunakan agen pembelian atau agen pembelian untuk melakukan
          pembelian pada Layanan.
        </li>
        <li className="privacy-text">
          Melakukan penggunaan Layanan yang tidak sah, termasuk mengumpulkan
          nama pengguna dan/atau alamat email pengguna secara elektronik atau
          cara lain untuk tujuan pengiriman email yang tidak diminta, atau
          membuat akun pengguna dengan cara otomatis atau dengan alasan palsu.
        </li>
        <li className="privacy-text">
          Menggunakan Layanan sebagai bagian dari segala upaya untuk bersaing
          dengan kami atau menggunakan Layanan dan/atau Konten untuk usaha atau
          perusahaan komersial yang menghasilkan pendapatan.
        </li>
      </ul>

      <p class="privacy-subtitle">7. KONTRIBUSI YANG DIBUAT PENGGUNA</p>

      <p class="privacy-text">
        Layanan tidak menawarkan pengguna untuk mengirimkan atau memposting
        konten.
      </p>

      <p class="privacy-subtitle">8. IZIN KONTRIBUSI</p>

      <p class="privacy-text">
        Anda dan Layanan setuju bahwa kami dapat mengakses, menyimpan,
        memproses, dan menggunakan informasi dan data pribadi apa pun yang Anda
        berikan sesuai dengan ketentuan Kebijakan Privasi dan pilihan Anda
        (termasuk pengaturan).
      </p>

      <p class="privacy-text">
        Dengan mengirimkan saran atau umpan balik lainnya mengenai Layanan, Anda
        setuju bahwa kami dapat menggunakan dan membagikan umpan balik tersebut
        untuk tujuan apa pun tanpa kompensasi kepada Anda.
      </p>

      <p class="privacy-subtitle">9. LISENSI APLIKASI SELULER</p>

      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Gunakan Lisensi
      </p>

      <p class="privacy-text">
        Jika Anda mengakses Layanan melalui Aplikasi, maka kami memberi Anda hak
        terbatas yang dapat dibatalkan, non-eksklusif, tidak dapat dialihkan,
        untuk menginstal dan menggunakan Aplikasi pada perangkat elektronik
        nirkabel yang Anda miliki atau kendalikan, dan untuk mengakses dan
        menggunakan Aplikasi pada perangkat tersebut benar-benar sesuai dengan
        syarat dan ketentuan lisensi aplikasi seluler yang terkandung dalam
        Ketentuan Hukum ini. Anda tidak boleh: (1) kecuali sebagaimana diizinkan
        oleh hukum yang berlaku, mendekompilasi, merekayasa balik, membongkar,
        mencoba mendapatkan kode sumber, atau mendekripsi Aplikasi; (2)
        melakukan modifikasi, adaptasi, penyempurnaan, penyempurnaan,
        penerjemahan, atau karya turunan apa pun dari Aplikasi; (3) melanggar
        undang-undang, peraturan, atau ketentuan apa pun yang berlaku sehubungan
        dengan akses atau penggunaan Aplikasi oleh Anda; (4) menghapus,
        mengubah, atau mengaburkan pemberitahuan kepemilikan apa pun (termasuk
        pemberitahuan hak cipta atau merek dagang) yang dipasang oleh kami atau
        pemberi lisensi Aplikasi; (5) menggunakan Aplikasi untuk segala upaya
        yang menghasilkan pendapatan, perusahaan komersial, atau tujuan lain
        yang tidak dirancang atau dimaksudkan; (6) membuat Aplikasi tersedia
        melalui jaringan atau lingkungan lain yang mengizinkan akses atau
        penggunaan oleh beberapa perangkat atau pengguna pada saat yang
        bersamaan; (7) menggunakan Aplikasi untuk menciptakan produk, layanan,
        atau perangkat lunak yang, secara langsung atau tidak langsung, bersaing
        dengan atau dengan cara apa pun merupakan pengganti Aplikasi; (8)
        menggunakan Aplikasi untuk mengirimkan pertanyaan otomatis ke situs web
        mana pun atau untuk mengirim email komersial yang tidak diminta; atau
        (9) menggunakan informasi kepemilikan apa pun atau antarmuka kami atau
        kekayaan intelektual kami lainnya dalam desain, pengembangan,
        manufaktur, lisensi, atau distribusi aplikasi, aksesori, atau perangkat
        apa pun untuk digunakan dengan Aplikasi.
      </p>

      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Perangkat Apple dan Android
      </p>

      <p class="privacy-text">
        Ketentuan berikut ini berlaku ketika Anda menggunakan Aplikasi yang
        diperoleh dari Apple Store atau Google Play (masing-masing merupakan
        'Distributor Aplikasi') untuk mengakses Layanan: (1) lisensi yang
        diberikan kepada Anda untuk Aplikasi kami terbatas pada lisensi yang
        tidak dapat dialihkan untuk menggunakan aplikasi pada perangkat yang
        menggunakan sistem operasi Apple iOS atau Android, sebagaimana berlaku,
        dan sesuai dengan aturan penggunaan yang ditetapkan dalam ketentuan
        layanan Distributor Aplikasi yang berlaku; (2) kami bertanggung jawab
        untuk menyediakan layanan pemeliharaan dan dukungan apa pun sehubungan
        dengan Aplikasi sebagaimana ditentukan dalam syarat dan ketentuan
        lisensi aplikasi seluler ini yang terkandung dalam Ketentuan Hukum ini
        atau sebagaimana diwajibkan berdasarkan hukum yang berlaku, dan Anda
        mengakui bahwa setiap Distributor Aplikasi tidak mempunyai kewajiban apa
        pun untuk menyediakan layanan pemeliharaan dan dukungan apa pun
        sehubungan dengan Aplikasi; (3) jika terjadi kegagalan Aplikasi untuk
        mematuhi jaminan yang berlaku, Anda dapat memberi tahu Distributor
        Aplikasi yang bersangkutan, dan Distributor Aplikasi, sesuai dengan
        syarat dan kebijakannya, dapat mengembalikan uang sejumlah harga
        pembelian, jika ada, yang telah dibayarkan untuk Aplikasi, dan sejauh
        diizinkan oleh hukum yang berlaku, Distributor Aplikasi tidak mempunyai
        kewajiban jaminan apa pun sehubungan dengan Aplikasi; (4) Anda
        menyatakan dan menjamin bahwa (i) Anda tidak berlokasi di negara yang
        terkena embargo pemerintah AS, atau yang telah ditetapkan oleh
        pemerintah AS sebagai negara 'pendukung teroris' dan (ii) Anda tidak
        terdaftar dalam daftar pihak yang dilarang atau dibatasi oleh pemerintah
        AS; (5) Anda harus mematuhi ketentuan perjanjian pihak ketiga yang
        berlaku saat menggunakan Aplikasi, misalnya. jika Anda memiliki aplikasi
        VoIP, maka Anda tidak boleh melanggar perjanjian layanan data nirkabel
        mereka saat menggunakan Aplikasi; dan (6) Anda mengakui dan menyetujui
        bahwa Distributor Aplikasi adalah penerima pihak ketiga dari syarat dan
        ketentuan dalam lisensi aplikasi seluler ini yang terkandung dalam
        Ketentuan Hukum ini, dan bahwa setiap Distributor Aplikasi berhak (dan
        akan dianggap telah menerima hak) untuk menegakkan syarat dan ketentuan
        dalam lisensi aplikasi seluler ini yang terkandung dalam Ketentuan Hukum
        ini terhadap Anda sebagai penerima pihak ketiga.
      </p>

      <p class="privacy-subtitle">10. PENGIKLAN</p>

      <p class="privacy-text">
        Kami mengizinkan pengiklan untuk menampilkan iklan mereka dan informasi
        lainnya di area Layanan tertentu, seperti iklan sidebar atau iklan
        banner. Kami hanya menyediakan ruang untuk memasang iklan tersebut, dan
        kami tidak memiliki hubungan lain dengan pengiklan.
      </p>

      <p class="privacy-subtitle">11. MANAJEMEN JASA</p>

      <p class="privacy-text">
        Kami berhak, namun tidak berkewajiban, untuk: (1) memantau Layanan jika
        ada pelanggaran terhadap Ketentuan Hukum ini; (2) mengambil tindakan
        hukum yang sesuai terhadap siapa pun yang, berdasarkan kebijakan kami
        sendiri, melanggar hukum atau Ketentuan Hukum ini, termasuk namun tidak
        terbatas pada, melaporkan pengguna tersebut ke pihak penegak hukum; (3)
        berdasarkan kebijakan kami sendiri dan tanpa batasan, menolak, membatasi
        akses, membatasi ketersediaan, atau menonaktifkan (sejauh memungkinkan
        secara teknologi) Kontribusi Anda atau bagian mana pun darinya; (4) atas
        kebijakan kami sendiri dan tanpa batasan, pemberitahuan, atau tanggung
        jawab, untuk menghapus dari Layanan atau menonaktifkan semua file dan
        konten yang ukurannya berlebihan atau dengan cara apa pun memberatkan
        sistem kami; dan (5) mengelola Layanan dengan cara yang dirancang untuk
        melindungi hak dan properti kami dan untuk memfasilitasi berfungsinya
        Layanan dengan baik.
      </p>

      <p class="privacy-subtitle">12. KEBIJAKAN PRIVASI</p>

      <p class="privacy-text">
        Kami peduli dengan privasi dan keamanan data. Mohon tinjau kembali
        kebijakan privasi kami. Dengan menggunakan Layanan, Anda setuju untuk
        terikat oleh Kebijakan Privasi kami, yang disertakan dalam Ketentuan
        Hukum ini. Harap diperhatikan bahwa Layanan di-host diUni Emirat Arab.
        Jika Anda mengakses Layanan dari wilayah lain mana pun di dunia dengan
        undang-undang atau persyaratan lain yang mengatur pengumpulan,
        penggunaan, atau pengungkapan data pribadi yang berbeda dari
        undang-undang yang berlaku di wilayah tersebut.Uni Emirat Arab, lalu
        melalui penggunaan Layanan yang berkelanjutan, Anda mentransfer data
        Anda keUni Emirat Arab, dan Anda secara tegas menyetujui data Anda
        ditransfer ke dan diproses diUni Emirat Arab.
      </p>

      <p class="privacy-subtitle">13. JANGKA WAKTU DAN PENGHENTIAN</p>

      <p class="privacy-text">
        Ketentuan Hukum ini akan tetap berlaku sepenuhnya saat Anda menggunakan
        Layanan. TANPA MEMBATASI KETENTUAN LAIN DARI KETENTUAN HUKUM INI, KAMI
        BERHAK, ATAS KEBIJAKSANAAN KAMI SENDIRI DAN TANPA PEMBERITAHUAN ATAU
        TANGGUNG JAWAB, MENOLAK AKSES DAN PENGGUNAAN LAYANAN (TERMASUK MEMBLOKIR
        ALAMAT IP TERTENTU), KEPADA ORANG MANAPUN DENGAN ALASAN ATAU UNTUK APA
        PUN TIDAK ADA ALASAN, TERMASUK TANPA BATASAN PELANGGARAN TERHADAP
        PERNYATAAN, JAMINAN, ATAU PERJANJIAN APAPUN YANG TERCANTUM DALAM SYARAT
        HUKUM INI ATAU HUKUM ATAU PERATURAN YANG BERLAKU. KAMI DAPAT
        MENGHENTIKAN PENGGUNAAN ATAU PARTISIPASI ANDA DALAM LAYANAN ATAU
        MENGHAPUSAKUN ANDA DANKONTEN ATAU INFORMASI APAPUN YANG ANDA POSTING
        SETIAP SAAT, TANPA PERINGATAN, ATAS KEBIJAKSANAAN KAMI SENDIRI.
      </p>

      <p class="privacy-text">
        Jika kami menghentikan atau menangguhkan akun Anda karena alasan apa
        pun, Anda dilarang mendaftar dan membuat akun baru atas nama Anda, nama
        palsu atau pinjaman, atau nama pihak ketiga mana pun, meskipun Anda
        mungkin bertindak atas nama pihak ketiga. berpesta. Selain menghentikan
        atau menangguhkan akun Anda, kami berhak mengambil tindakan hukum yang
        sesuai, termasuk tanpa batasan melakukan ganti rugi perdata, pidana, dan
        ganti rugi.
      </p>

      <p class="privacy-subtitle">14. MODIFIKASI DAN INTERUPSI</p>

      <p class="privacy-text">
        Kami berhak mengubah, memodifikasi, atau menghapus konten Layanan kapan
        saja atau karena alasan apa pun atas kebijakan kami sendiri tanpa
        pemberitahuan. Namun, kami tidak berkewajiban memperbarui informasi apa
        pun pada Layanan kami. Kami tidak akan bertanggung jawab kepada Anda
        atau pihak ketiga mana pun atas modifikasi, perubahan harga,
        penangguhan, atau penghentian Layanan.
      </p>

      <p class="privacy-text">
        Kami tidak dapat menjamin Layanan akan tersedia setiap saat. Kami
        mungkin mengalami masalah perangkat keras, perangkat lunak, atau masalah
        lain atau perlu melakukan pemeliharaan terkait Layanan, yang
        mengakibatkan gangguan, penundaan, atau kesalahan. Kami berhak mengubah,
        merevisi, memperbarui, menangguhkan, menghentikan, atau memodifikasi
        Layanan kapan saja atau untuk alasan apa pun tanpa pemberitahuan kepada
        Anda. Anda setuju bahwa kami tidak mempunyai tanggung jawab apa pun atas
        kehilangan, kerusakan, atau ketidaknyamanan apa pun yang disebabkan oleh
        ketidakmampuan Anda mengakses atau menggunakan Layanan selama waktu
        henti atau penghentian Layanan. Ketentuan Hukum ini tidak akan
        ditafsirkan untuk mewajibkan kami memelihara dan mendukung Layanan atau
        memberikan koreksi, pembaruan, atau pelepasan apa pun sehubungan dengan
        Layanan tersebut.
      </p>

      <p class="privacy-subtitle">15. HUKUM YANG MENGATUR</p>

      <p class="privacy-text">
        Ketentuan Hukum ini diatur oleh dan didefinisikan mengikuti hukum negara
        tersebutUni Emirat Arab. Tigris Studios FZCO dan Anda sendiri secara
        tidak dapat ditarik kembali menyetujui bahwa pengadilanituUni Emirat
        Arab memiliki yurisdiksi eksklusif untuk menyelesaikan setiap
        perselisihan yang mungkin timbul sehubungan dengan Ketentuan Hukum ini.
      </p>

      <p class="privacy-subtitle">16. PENYELESAIAN SENGKETA</p>

      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Negosiasi Informal
      </p>

      <p class="privacy-text">
        Untuk mempercepat penyelesaian dan mengendalikan biaya perselisihan,
        kontroversi, atau klaim apa pun yang terkait dengan Ketentuan Hukum ini
        (masing-masing disebut 'Sengketa' dan secara kolektif disebut
        'Sengketa') yang diajukan oleh Anda atau kami (secara individu, 'Pihak'
        dan secara kolektif, 'Para Pihak'), Para Pihak setuju untuk melakukan
        upaya pertama untuk merundingkan setiap Sengketa (kecuali Sengketa yang
        secara tegas disebutkan di bawah) secara informal setidaknya untuk14
        hari sebelum memulai arbitrase. Perundingan informal tersebut dimulai
        berdasarkan pemberitahuan tertulis dari satu Pihak kepada Pihak lainnya.
      </p>

      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Pembatasan
      </p>

      <p class="privacy-text">
        Para Pihak sepakat bahwa setiap arbitrase akan dibatasi pada
        Perselisihan antara Para Pihak secara individual. Sepanjang diizinkan
        oleh undang-undang, (a) arbitrase tidak boleh digabungkan dengan proses
        hukum lainnya; (b) tidak ada hak atau wewenang untuk setiap Sengketa
        untuk diselesaikan melalui arbitrase berdasarkan gugatan kelompok atau
        menggunakan prosedur gugatan kelompok; dan (c) tidak ada hak atau
        wewenang untuk mengajukan Sengketa apa pun dalam kapasitas yang dianggap
        mewakili atas nama masyarakat umum atau orang lain.
      </p>

      <p class="privacy-text" style={{ fontWeight: 700 }}>
        Pengecualian terhadap Negosiasi Informal dan Arbitrase
      </p>

      <p class="privacy-text">
        Para Pihak sepakat bahwa Perselisihan berikut ini tidak tunduk pada
        ketentuan-ketentuan di atas mengenai arbitrase yang mengikat negosiasi
        informal: (a) setiap Perselisihan yang bertujuan untuk menegakkan atau
        melindungi, atau mengenai keabsahan, hak kekayaan intelektual salah satu
        Pihak; (b) setiap Sengketa yang berkaitan dengan, atau timbul dari,
        dugaan pencurian, pembajakan, pelanggaran privasi, atau penggunaan tidak
        sah; dan (c) tuntutan ganti rugi apa pun. Apabila ketentuan ini ternyata
        tidak sah atau tidak dapat dilaksanakan, maka tidak ada Pihak yang akan
        memilih untuk melakukan arbitrase terhadap setiap Sengketa yang termasuk
        dalam bagian ketentuan ini yang dianggap ilegal atau tidak dapat
        dilaksanakan dan Sengketa tersebut akan diputuskan oleh pengadilan
        dengan yurisdiksi yang kompeten dalam pengadilan yang terdaftar untuk
        yurisdiksi di atas, dan Para Pihak setuju untuk tunduk pada yurisdiksi
        pribadi pengadilan tersebut.
      </p>

      <p class="privacy-subtitle">17. KOREKSI</p>

      <p class="privacy-text">
        Mungkin terdapat informasi pada Layanan yang mengandung kesalahan ketik,
        ketidakakuratan, atau kelalaian, termasuk deskripsi, harga,
        ketersediaan, dan berbagai informasi lainnya. Kami berhak memperbaiki
        kesalahan, ketidakakuratan, atau kelalaian apa pun dan mengubah atau
        memperbarui informasi pada Layanan kapan saja, tanpa pemberitahuan
        sebelumnya.
      </p>

      <p class="privacy-subtitle">18. PENOLAKAN</p>

      <p class="privacy-text">
        LAYANAN INI DISEDIAKAN SEBAGAIMANA ADANYA DAN SEBAGAIMANA TERSEDIA. ANDA
        SETUJU BAHWA PENGGUNAAN LAYANAN INI AKAN MENJADI RISIKO ANDA SENDIRI.
        SEJAUH DIIZINKAN OLEH HUKUM, KAMI MENOLAK SEMUA JAMINAN, TERSURAT MAUPUN
        TERSIRAT, SEHUBUNGAN DENGAN LAYANAN DAN PENGGUNAANNYA, TERMASUK, TANPA
        BATASAN, JAMINAN TERSIRAT ATAS KELAYAKAN UNTUK DIPERDAGANGKAN,
        KESESUAIAN UNTUK TUJUAN TERTENTU, DAN TIDAK ADA PELANGGARAN. KAMI TIDAK
        MEMBERIKAN JAMINAN ATAU PERNYATAAN TENTANG KEAKURATAN ATAU KELENGKAPAN
        KONTEN LAYANAN ATAU KONTEN SITUS WEB ATAU APLIKASI SELULER APA PUN YANG
        TERKAIT DENGAN LAYANAN DAN KAMI TIDAK BERTANGGUNG JAWAB ATAU TANGGUNG
        JAWAB ATAS (1) KESALAHAN, KESALAHAN, ATAU KETIDAKURATAN KONTEN DAN
        MATERI, (2) CEDERA PRIBADI ATAU KERUSAKAN PROPERTI, DALAM BENTUK APA
        PUN, AKIBAT AKSES ANDA KE DAN PENGGUNAAN LAYANAN, (3) AKSES TIDAK SAH KE
        ATAU PENGGUNAAN SERVER AMAN KAMI DAN/ATAU SEMUA INFORMASI PRIBADI DAN
        /ATAU INFORMASI KEUANGAN YANG TERSIMPAN DI DALAMNYA, (4) SETIAP GANGGUAN
        ATAU PENGHENTIAN TRANSMISI KE ATAU DARI LAYANAN, (5) SETIAP BUGS, VIRUS,
        KUDA TROJAN, ATAU SEPERTINYA YANG DAPAT DIKIRIM KE ATAU MELALUI LAYANAN
        OLEH PIHAK KETIGA MANA PUN, DAN/ATAU (6) KESALAHAN ATAU KELALAIAN DALAM
        KONTEN DAN MATERI APAPUN ATAU KERUGIAN ATAU KERUSAKAN DALAM BENTUK APA
        PUN YANG TERJADI AKIBAT PENGGUNAAN KONTEN YANG DIKIRIM, DIKIRIM, ATAU
        LAINNYA YANG TERSEDIA MELALUI LAYANAN. KAMI TIDAK MENJAMIN, MENDUKUNG,
        MENJAMIN, ATAU BERTANGGUNG JAWAB ATAS PRODUK ATAU LAYANAN APAPUN YANG
        DIIKLANKAN ATAU DITAWARKAN OLEH PIHAK KETIGA MELALUI LAYANAN, SITUS WEB
        HYPERLINK, ATAU SITUS WEB ATAU APLIKASI SELULER APAPUN YANG DITAMPILKAN
        DALAM BANNER ATAU IKLAN LAINNYA, DAN KAMI TIDAK AKAN JADILAH PIHAK DALAM
        ATAU DENGAN CARA APAPUN BERTANGGUNG JAWAB UNTUK MEMANTAU TRANSAKSI APA
        PUN ANTARA ANDA DAN PENYEDIA PRODUK ATAU LAYANAN PIHAK KETIGA. SEPERTI
        PEMBELIAN PRODUK ATAU LAYANAN MELALUI MEDIUM ATAU DALAM LINGKUNGAN
        APAPUN, ANDA HARUS MENGGUNAKAN PERTIMBANGAN TERBAIK DAN BERHATI-HATI
        JIKA TEPAT.
      </p>

      <p class="privacy-subtitle">19. BATASAN TANGGUNG JAWAB</p>

      <p class="privacy-text">
        DALAM KEADAAN APA PUN KAMI ATAU DIREKTUR, KARYAWAN, ATAU AGEN KAMI TIDAK
        BERTANGGUNG JAWAB KEPADA ANDA ATAU PIHAK KETIGA ATAS KERUGIAN LANGSUNG,
        TIDAK LANGSUNG, KONSEKUENSIAL, CONTOH, INSIDENTAL, KHUSUS, ATAU HUKUMAN,
        TERMASUK KEHILANGAN KEUNTUNGAN, KEHILANGAN PENDAPATAN, KEHILANGAN DATA,
        ATAU KERUGIAN LAIN YANG TIMBUL DARI PENGGUNAAN LAYANAN, MESKIPUN KAMI
        TELAH DIBERITAHU TENTANG KEMUNGKINAN KERUSAKAN TERSEBUT. MESKIPUN APA
        PUN YANG TERCANTUM DI SINI, TANGGUNG JAWAB KAMI KEPADA ANDA ATAS
        PENYEBAB APA PUN DAN APAPUN BENTUK TINDAKANNYA, AKAN SETIAP SAAT
        TERBATAS PADA JUMLAH YANG DIBAYARKAN, JIKA ADA, OLEH ANDA KEPADA KAMI
        ATAU . HUKUM NEGARA AS DAN HUKUM INTERNASIONAL TERTENTU TIDAK
        MENGIZINKAN PEMBATASAN JAMINAN TERSIRAT ATAU PENGECUALIAN ATAU
        PEMBATASAN KERUGIAN TERTENTU. JIKA HUKUM INI BERLAKU BAGI ANDA, BEBERAPA
        ATAU SEMUA PENOLAKAN ATAU PEMBATASAN DI ATAS MUNGKIN TIDAK BERLAKU BAGI
        ANDA, DAN ANDA MUNGKIN MEMILIKI HAK TAMBAHAN.
      </p>

      <p class="privacy-subtitle">20. GANTI RUGI</p>

      <p class="privacy-text">
        Anda setuju untuk membela, mengganti kerugian, dan membebaskan kami,
        termasuk anak perusahaan, afiliasi, dan semua pejabat, agen, mitra, dan
        karyawan kami masing-masing, dari dan terhadap segala kerugian,
        kerusakan, tanggung jawab, klaim, atau permintaan, termasuk pengacara
        yang wajar ' biaya dan pengeluaran, yang dilakukan oleh pihak ketiga
        mana pun karena atau timbul dari:(1) penggunaan Layanan; (2) pelanggaran
        Ketentuan Hukum ini; (3) setiap pelanggaran terhadap pernyataan dan
        jaminan Anda yang ditetapkan dalam Ketentuan Hukum ini; (4) pelanggaran
        Anda terhadap hak pihak ketiga, termasuk namun tidak terbatas pada hak
        kekayaan intelektual; atau (5) segala tindakan merugikan yang nyata
        terhadap pengguna Layanan lain yang terhubung dengan Anda melalui
        Layanan. Sekalipun demikian, kami berhak, atas biaya Anda, untuk
        mengambil alih pembelaan dan kendali eksklusif atas masalah apa pun yang
        mengharuskan Anda mengganti kerugian kami, dan Anda setuju untuk bekerja
        sama, atas biaya Anda, dalam pembelaan kami atas klaim tersebut. Kami
        akan melakukan upaya yang wajar untuk memberi tahu Anda tentang klaim,
        tindakan, atau proses apa pun yang tunduk pada ganti rugi ini setelah
        menyadarinya.
      </p>

      <p class="privacy-subtitle">21. DATA PENGGUNA</p>

      <p class="privacy-text">
        Kami akan menyimpan data tertentu yang Anda kirimkan ke Layanan untuk
        tujuan mengelola kinerja Layanan, serta data yang berkaitan dengan
        penggunaan Anda atas Layanan. Meskipun kami melakukan pencadangan data
        rutin secara rutin, Anda sepenuhnya bertanggung jawab atas semua data
        yang Anda kirimkan atau yang berkaitan dengan aktivitas apa pun yang
        Anda lakukan menggunakan Layanan. Anda setuju bahwa kami tidak
        bertanggung jawab kepada Anda atas kehilangan atau kerusakan apa pun
        pada data tersebut, dan Anda dengan ini melepaskan hak tindakan apa pun
        terhadap kami yang timbul dari kehilangan atau kerusakan data tersebut.
      </p>

      <p class="privacy-subtitle">
        22. KOMUNIKASI, TRANSAKSI, DAN TANDA TANGAN ELEKTRONIK
      </p>

      <p class="privacy-text">
        Mengunjungi Layanan, mengirimkan email kepada kami, dan mengisi formulir
        online merupakan komunikasi elektronik. Anda setuju untuk menerima
        komunikasi elektronik, dan Anda setuju bahwa semua perjanjian,
        pemberitahuan, pengungkapan, dan komunikasi lain yang kami berikan
        kepada Anda secara elektronik, melalui email dan pada Layanan, memenuhi
        persyaratan hukum bahwa komunikasi tersebut dilakukan secara tertulis.
        ANDA DENGAN INI MENYETUJUI PENGGUNAAN TANDA TANGAN ELEKTRONIK, KONTRAK,
        PESANAN, DAN CATATAN LAINNYA, DAN TERHADAP PEMBERITAHUAN, KEBIJAKAN, DAN
        CATATAN TRANSAKSI YANG DIMULAI ATAU DISELESAIKAN OLEH KAMI ATAU MELALUI
        LAYANAN. Anda dengan ini melepaskan hak atau persyaratan apa pun
        berdasarkan undang-undang, peraturan, aturan, tata cara, atau
        undang-undang lain di yurisdiksi mana pun yang memerlukan tanda tangan
        asli atau penyerahan atau penyimpanan catatan non-elektronik, atau
        terhadap pembayaran atau pemberian kredit dengan cara apa pun lainnya.
        daripada sarana elektronik.
      </p>

      <p class="privacy-subtitle">23. PENGGUNA DAN PENDUDUK CALIFORNIA</p>

      <p class="privacy-text">
        Jika ada keluhan terhadap kami yang tidak terselesaikan secara
        memuaskan, Anda dapat menghubungi Unit Bantuan Pengaduan Divisi Layanan
        Konsumen Departemen Urusan Konsumen California secara tertulis di 1625
        North Market Blvd., Suite N 112, Sacramento, California 95834 atau
        melalui telepon di (800) 952-5210 atau (916) 445-1254.
      </p>

      <p class="privacy-subtitle">24. LAIN-LAIN</p>

      <p class="privacy-text">
        Ketentuan Hukum ini dan setiap kebijakan atau aturan pengoperasian yang
        kami posting pada Layanan atau sehubungan dengan Layanan merupakan
        keseluruhan perjanjian dan pemahaman antara Anda dan kami. Kegagalan
        kami untuk melaksanakan atau menegakkan hak atau ketentuan apa pun dalam
        Ketentuan Hukum ini tidak akan dianggap sebagai pelepasan hak atau
        ketentuan tersebut. Ketentuan Hukum ini berlaku sejauh yang diizinkan
        oleh hukum. Kami dapat mengalihkan sebagian atau seluruh hak dan
        kewajiban kami kepada orang lain kapan saja. Kami tidak bertanggung
        jawab atau berkewajiban atas kehilangan, kerusakan, keterlambatan, atau
        kegagalan bertindak yang disebabkan oleh sebab apa pun di luar kendali
        wajar kami. Jika ada ketentuan atau bagian dari ketentuan dalam
        Ketentuan Hukum ini yang dianggap melanggar hukum, batal, atau tidak
        dapat dilaksanakan, ketentuan atau bagian dari ketentuan tersebut
        dianggap dapat dipisahkan dari Ketentuan Hukum ini dan tidak
        mempengaruhi keabsahan dan keberlakuan ketentuan lainnya. Tidak ada
        hubungan usaha patungan, kemitraan, hubungan kerja, atau keagenan yang
        tercipta antara Anda dan kami sebagai akibat dari Ketentuan Hukum ini
        atau penggunaan Layanan. Anda setuju bahwa Ketentuan Hukum ini tidak
        akan ditafsirkan melawan kami berdasarkan penyusunannya. Anda dengan ini
        mengesampingkan setiap dan seluruh pembelaan yang mungkin Anda miliki
        berdasarkan bentuk elektronik dari Ketentuan Hukum ini dan kurangnya
        penandatanganan oleh pihak-pihak dalam Perjanjian ini untuk melaksanakan
        Ketentuan Hukum ini.
      </p>

      <p class="privacy-subtitle">25. HUBUNGI KAMI</p>

      <p class="privacy-text">
        In order to resolve a complaint regarding the Services or to receive
        further information regarding use of the Services, please contact us at:
      </p>

      <p class="privacy-text" style={{ fontWeight: 700, marginBottom: 0 }}>
        Studio Tigris FZCO
      </p>
      <p class="privacy-text" style={{ fontWeight: 700, marginBottom: 0 }}>
        16235-001, Taman Bisnis IFZA, DDP
      </p>
      <p class="privacy-text" style={{ fontWeight: 700, marginBottom: 0 }}>
        Dubai, Uni Emirat Arab
      </p>
      <p class="privacy-text" style={{ fontWeight: 700, marginBottom: 0 }}>
        Uni Emirat Arab
      </p>
      <p class="privacy-text" style={{ fontWeight: 700, marginBottom: 0 }}>
        halo@tigrisstudios.com
      </p>
    </>
  );
};

export default TermsOfUseInd;
