const indonesiaFlag = () => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_511_174)">
      <rect y="0.5" width="24" height="17" rx="3" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.5H24V9.56667H0V0.5Z"
        fill="#F93939"
      />
    </g>
    <defs>
      <clipPath id="clip0_511_174">
        <rect y="0.5" width="24" height="17" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default indonesiaFlag;
