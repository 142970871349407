import React from "react";
import "./styles.css";

const Textarea = ({ value, onChange, placeholder, max }) => {
  const handleChange = (e) => {
    if (max !== undefined && e.target.value.length > max) return;
    onChange(e.target.value);
  };

  return (
    <div className="textarea-wrapper">
      <textarea
        className="textarea"
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
      {max !== undefined && (
        <p className="textarea-helper">
          {value.length}/{max}
        </p>
      )}
    </div>
  );
};

export default Textarea;
