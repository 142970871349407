const arabicFlag = () => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_511_180)">
      <path
        d="M21.7143 0.5H2.28571C1.02335 0.5 0 1.51482 0 2.76667V15.2333C0 16.4852 1.02335 17.5 2.28571 17.5H21.7143C22.9767 17.5 24 16.4852 24 15.2333V2.76667C24 1.51482 22.9767 0.5 21.7143 0.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.8334H24V17.5H0V11.8334Z"
        fill="#151515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.5H24V6.16667H0V0.5Z"
        fill="#249F58"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.5V17.5H6.85714V0.5H0Z"
        fill="#F93939"
      />
    </g>
    <defs>
      <clipPath id="clip0_511_180">
        <rect y="0.5" width="24" height="17" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default arabicFlag;
