const selectIcon = () => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.95969 0H1.04031C0.621059 0 0.387973 0.484966 0.649878 0.812348L3.60957 4.51196C3.80973 4.76216 4.19027 4.76216 4.39043 4.51196L7.35012 0.812348C7.61203 0.484966 7.37894 0 6.95969 0Z"
      fill="#151717"
    />
  </svg>
);

export default selectIcon;
