import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";
import { IMAGES, ICONS } from "../../assets";

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 600) {
        setIsOpenMenu(false);
      }
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const renderBurgerMenu = () => {
    return !isOpenMenu ? (
      <div className="burger-menu" onClick={toggleMenu}>
        <ICONS.burgerMenu />
      </div>
    ) : (
      <div className="cross-wrapper" onClick={toggleMenu}>
        <ICONS.cross />
      </div>
    );
  };

  const isShowBurgerMenu = windowWidth <= 600;

  return (
    <>
      <header
        className="header container"
        style={{
          position: isShowBurgerMenu ? "fixed" : "relative",
          backgroundColor: isShowBurgerMenu ? "white" : "transparent",
        }}
      >
        <div className="header-logo">
          <img style={{ width: 64, height: 64 }} src={IMAGES.logo} />
          <p className="logo-name">NIYA</p>
        </div>
        {!isShowBurgerMenu && (
          <div className="header-menu">
            <nav className="header-navigation">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "header-navigation-item-active"
                    : "header-navigation-item"
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive
                    ? "header-navigation-item-active"
                    : "header-navigation-item"
                }
              >
                About us
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? "header-navigation-item-active"
                    : "header-navigation-item"
                }
                style={{ marginRight: 0 }}
              >
                Contact us
              </NavLink>
            </nav>
          </div>
        )}
        {isShowBurgerMenu && renderBurgerMenu()}
      </header>
      {isOpenMenu && (
        <div className="burger-menu-wrapper">
          <div className="burger-menu-container">
            <nav className="burger-menu-navigation">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "burger-menu-navigation-item-active"
                    : "burger-menu-navigation-item"
                }
                onClick={() => setIsOpenMenu(false)}
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive
                    ? "burger-menu-navigation-item-active"
                    : "burger-menu-navigation-item"
                }
                onClick={() => setIsOpenMenu(false)}
              >
                About us
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? "burger-menu-navigation-item-active"
                    : "burger-menu-navigation-item"
                }
                style={{ marginRight: 0 }}
                onClick={() => setIsOpenMenu(false)}
              >
                Contact us
              </NavLink>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
