import React from "react";

export const TermsOfUseAra = () => {
  return (
    <>
      <p dir="rtl" class="privacy-title">
        شروط الاستخدام
      </p>
      <p dir="rtl" style={{ fontWeight: 700 }} class="privacy-text">
        آخر تحديث 04 مارس 2024
      </p>
      <p dir="rtl" class="privacy-subtitle">
        الموافقة على شروطنا القانونية
      </p>
      <p dir="rtl" class="privacy-text">
        نحناستوديوهات دجلة FZCO ((شركة"،"نحن"،"نحن'، أو 'ملكنا')، وهي شركة مسجلة
        فيالإمارات العربية المتحدة في16235-001، مجمع IFZA للأعمال، DDP، دبي،
        الإمارات العربية المتحدة.
      </p>
      <p dir="rtl" class="privacy-text">
        نحن نعمل على تطبيق الهاتف المحمولهو (ال 'برنامج')، بالإضافة إلى أي
        منتجات وخدمات أخرى ذات صلة تشير إلى هذه الشروط القانونية أو ترتبط بها
        ('مصطلحات قانونية') (بشكل جماعي، 'خدمات').
      </p>
      <p dir="rtl" class="privacy-text">
        تتبع صلاحك ولا تترك أي شيء قضاء! هل سبق لك أن فاتتك صلاتك لسبب ما؟ هل
        تريد قضاء قضائك ولكن نسيت أي صلاة فاتتك؟ نيا هنا للمساعدة! نيا هنا
        للمساعدة
      </p>
      <p dir="rtl" class="privacy-text">
        يمكنك الاتصال بنا عن طريقأرسل بريدًا إلكترونيًا إلى
        hello@tigrisstudios.com أو بالبريد إلى 16235-001، IFZA Business Park،
        DDP، دبي، الإمارات العربية المتحدة، الإمارات العربية المتحدة.
      </p>
      <p dir="rtl" class="privacy-text">
        تشكل هذه الشروط القانونية اتفاقية ملزمة قانونًا مبرمة بينك، سواء شخصيًا
        أو نيابة عن كيان ('أنت')، واستوديوهات دجلة FZCOفيما يتعلق بوصولك إلى
        الخدمات واستخدامها. أنت توافق على أنه من خلال الوصول إلى الخدمات، تكون
        قد قرأت جميع هذه الشروط القانونية وفهمتها ووافقت على الالتزام بها. إذا
        كنت لا توافق على جميع هذه الشروط القانونية، فأنت محظور صراحةً من استخدام
        الخدمات ويجب عليك التوقف عن استخدامها على الفور.
      </p>
      <p dir="rtl" class="privacy-text">
        تشكل هذه الشروط القانونية اتفاقية ملزمة قانونًا مبرمة بينك، سواء شخصيًا
        أو نيابة عن كيان ('أنت')، واستوديوهات دجلة FZCOفيما يتعلق بوصولك إلى
        الخدمات واستخدامها. أنت توافق على أنه من خلال الوصول إلى الخدمات، تكون
        قد قرأت جميع هذه الشروط القانونية وفهمتها ووافقت على الالتزام بها. إذا
        كنت لا توافق على جميع هذه الشروط القانونية، فأنت محظور صراحةً من استخدام
        الخدمات ويجب عليك التوقف عن استخدامها على الفور.
      </p>
      <p dir="rtl" class="privacy-text">
        يجب أن يحصل جميع المستخدمين القاصرين في الولاية القضائية التي يقيمون
        فيها (تحت سن 18 عامًا عمومًا) على إذن من والديهم أو الوصي عليهم مباشرة
        لاستخدام الخدمات. إذا كنت قاصرًا، فيجب أن يطلب من والدك أو ولي أمرك
        قراءة هذه الشروط القانونية والموافقة عليها قبل استخدام الخدمات.
      </p>
      <p dir="rtl" class="privacy-text">
        نوصي بطباعة نسخة من هذه الشروط القانونية للاحتفاظ بها في سجلاتك.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        جدول المحتويات
      </p>
      <p dir="rtl" class="privacy-subtitle">
        1. خدماتنا
      </p>
      <p dir="rtl" class="privacy-text">
        المعلومات المقدمة عند استخدام الخدمات ليست مخصصة للتوزيع أو الاستخدام من
        قبل أي شخص أو كيان في أي ولاية قضائية أو بلد حيث يكون هذا التوزيع أو
        الاستخدام مخالفًا للقانون أو اللوائح أو قد يعرضنا لأي متطلبات تسجيل داخل
        تلك الولاية القضائية أو دولة. وبناء على ذلك، فإن هؤلاء الأشخاص الذين
        يختارون الوصول إلى الخدمات من مواقع أخرى يفعلون ذلك بمبادرة منهم ويكونون
        مسؤولين وحدهم عن الامتثال للقوانين المحلية، إذا كانت القوانين المحلية
        قابلة للتطبيق وإلى الحد الذي تنطبق عليه.
      </p>
      <p dir="rtl" class="privacy-text">
        لم يتم تصميم الخدمات لتتوافق مع اللوائح الخاصة بالصناعة (قانون قابلية
        نقل التأمين الصحي والمساءلة (HIPAA)، والقانون الفيدرالي لإدارة أمن
        المعلومات (FISMA)، وما إلى ذلك)، لذلك إذا كانت تفاعلاتك ستخضع لهذه
        القوانين، فلا يجوز لك استخدام الخدمات. لا يجوز لك استخدام الخدمات بطريقة
        تنتهك قانون Gramm-Leach-Bliley (GLBA).
      </p>
      <p dir="rtl" class="privacy-subtitle">
        2. حقوق الملكية الفكرية
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        ملكيتنا الفكرية
      </p>
      <p dir="rtl" class="privacy-text">
        نحن المالك أو المرخص له لجميع حقوق الملكية الفكرية في خدماتنا، بما في
        ذلك جميع التعليمات البرمجية المصدر وقواعد البيانات والوظائف والبرامج
        وتصميمات مواقع الويب والصوت والفيديو والنصوص والصور الفوتوغرافية
        والرسومات في الخدمات (يُشار إليها إجمالاً بـ "المحتوى"). )، بالإضافة إلى
        العلامات التجارية وعلامات الخدمة والشعارات الواردة فيه ("العلامات").
      </p>
      <p dir="rtl" class="privacy-text">
        إن المحتوى والعلامات الخاصة بنا محمية بموجب قوانين حقوق الطبع والنشر
        والعلامات التجارية (ومختلف حقوق الملكية الفكرية الأخرى وقوانين المنافسة
        غير العادلة) والمعاهدات في الولايات المتحدة وحول العالم.
      </p>
      <p dir="rtl" class="privacy-text">
        يتم توفير المحتوى والعلامات في الخدمات "كما هي" أو من خلالها لاستخدامك
        الشخصي غير التجاري أو لغرض العمل الداخلي فقط.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        استخدامك لخدماتنا
      </p>
      <p dir="rtl" class="privacy-text">
        مع مراعاة امتثالك لهذه الشروط القانونية، نمنحك ترخيصًا غير حصري وغير
        قابل للتحويل وقابل للإلغاء من أجل:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li class="privacy-text">الوصول إلى الخدمات، و</li>
        <li class="privacy-text">
          تنزيل أو طباعة نسخة من أي جزء من المحتوى الذي تمكنت من الوصول إليه
          بشكل صحيح.
        </li>
      </ul>
      <p dir="rtl" class="privacy-text">
        فقط لاستخدامك الشخصي وغير التجاري أو لغرض العمل الداخلي.
      </p>
      <p dir="rtl" class="privacy-text">
        باستثناء ما هو منصوص عليه في هذا القسم أو في أي مكان آخر في شروطنا
        القانونية، لا يجوز نسخ أي جزء من الخدمات أو المحتوى أو العلامات أو إعادة
        إنتاجها أو تجميعها أو إعادة نشرها أو تحميلها أو نشرها أو عرضها علنًا أو
        تشفيرها أو ترجمتها أو نقلها أو توزيعها أو بيعها أو ترخيصها أو استغلالها
        لأي غرض تجاري على الإطلاق، دون الحصول على إذن كتابي صريح مسبق منا.
      </p>
      <p dir="rtl" class="privacy-text">
        إذا كنت ترغب في الاستفادة من الخدمات أو المحتوى أو العلامات بخلاف ما هو
        منصوص عليه في هذا القسم أو في أي مكان آخر في شروطنا القانونية، فيرجى
        توجيه طلبك إلى:hello@tigrisstudios.com. إذا منحناك الإذن بنشر أي جزء من
        خدماتنا أو المحتوى أو إعادة إنتاجه أو عرضه علنًا، فيجب عليك تعريفنا
        باعتبارنا المالكين أو المرخصين للخدمات أو المحتوى أو العلامات والتأكد من
        ظهور أي حقوق نشر أو إشعار ملكية أو يكون مرئيًا عند نشر المحتوى الخاص بنا
        أو إعادة إنتاجه أو عرضه.
      </p>
      <p dir="rtl" class="privacy-text">
        نحن نحتفظ بجميع الحقوق غير الممنوحة لك صراحةً في الخدمات والمحتوى
        والعلامات.
      </p>
      <p dir="rtl" class="privacy-text">
        أي خرق لحقوق الملكية الفكرية هذه سيشكل خرقًا ماديًا لشروطنا القانونية
        وسينتهي حقك في استخدام خدماتنا على الفور.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        التقديمات الخاصة بك
      </p>
      <p dir="rtl" class="privacy-text">
        يرجى مراجعة هذا القسمبعناية قبل استخدام خدماتنا لفهم (أ) الحقوق التي
        تمنحها لنا و (ب) الالتزامات التي تقع على عاتقك عند نشر أو تحميل أي محتوى
        من خلال الخدمات.
      </p>
      <p dir="rtl" class="privacy-text">
        <span style={{ fontWeight: 700 }}>التقديمات:</span> من خلال إرسال أي
        سؤال أو تعليق أو اقتراح أو فكرة أو تعليقات أو معلومات أخرى إلينا مباشرة
        حول الخدمات ("الإرسالات")، فإنك توافق على التنازل لنا عن جميع حقوق
        الملكية الفكرية في هذا الإرسال. أنت توافق على أننا نملك هذا التقديم ونحق
        له استخدامه ونشره دون قيود لأي غرض قانوني، تجاريًا أو غير ذلك، دون إقرار
        أو تعويض لك.
      </p>
      <p dir="rtl" class="privacy-text">
        أنت مسؤول عما تنشره أو تحمّله: من خلال إرسال التقديمات إلينا عبر أي جزء
        من الخدمات، فإنك:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li class="privacy-text">
          تأكيد أنكلن تقوم بنشر أو إرسال أو نشر أو تحميل أو نقل من خلال الخدمات
          أي إرسال غير قانوني أو مضايق أو بغيض أو ضار أو تشهيري أو فاحش أو متسلط
          أو مسيئ أو تمييزي أو تهديد لأي شخص أو مجموعة أو جنسي صريح أو كاذب أو
          غير دقيق أو خادعة أو مضللة؛
        </li>
        <li class="privacy-text">
          إلى الحد الذي يسمح به القانون المعمول به، التنازل عن أي وجميع الحقوق
          الأخلاقية لأي تقديم من هذا القبيل؛
        </li>
        <li class="privacy-text">
          تضمن أن أي من هذه التقديمات أصلية بالنسبة لك أو أن لديك الحقوق
          والتراخيص اللازمة لتقديم هذه التقديمات وأن لديك السلطة الكاملة لمنحنا
          الحقوق المذكورة أعلاه فيما يتعلق بتقديماتك؛ و
        </li>
        <li class="privacy-text">
          تتعهد وتتعهد بأن التقديمات الخاصة بك لا تشكل معلومات سرية.
        </li>
      </ul>
      <p dir="rtl" class="privacy-text">
        أنت وحدك المسؤول عن تقديماتك وتوافق صراحةً على تعويضنا عن أي وجميع
        الخسائر التي قد نتكبدها بسبب انتهاكك لـ (أ) هذا القسم، (ب) حقوق الملكية
        الفكرية لأي طرف ثالث، أو (ج) القانون المعمول به .
      </p>
      <p dir="rtl" class="privacy-subtitle">
        3. بيانات المستخدم
      </p>
      <p dir="rtl" class="privacy-text">
        باستخدام الخدمات، فإنك تقر وتضمن ما يلي: (1) ستكون جميع معلومات التسجيل
        التي تقدمها صحيحة ودقيقة وحديثة وكاملة؛ (2) ستحافظ على دقة هذه المعلومات
        وتقوم بتحديث معلومات التسجيل هذه على الفور حسب الضرورة؛ (3) لديك الأهلية
        القانونية وتوافق على الالتزام بهذه الشروط القانونية؛ (4) أنك لست قاصرًا
        في الولاية القضائية التي تقيم فيهاأو إذا كنت قاصرًا، فقد حصلت على إذن
        الوالدين لاستخدام الخدمات; (5) لن تتمكن من الوصول إلى الخدمات من خلال
        وسائل آلية أو غير بشرية، سواء من خلال روبوت أو برنامج نصي أو غير ذلك؛
        (6) أنك لن تستخدم الخدمات لأي غرض غير قانوني أو غير مصرح به؛ و(7) لن
        ينتهك استخدامك للخدمات أي قانون أو لائحة معمول بها.
      </p>
      <p dir="rtl" class="privacy-text">
        إذا قدمت أي معلومات غير صحيحة أو غير دقيقة أو غير حديثة أو غير كاملة،
        فيحق لنا تعليق حسابك أو إنهائه ورفض أي استخدام حالي أو مستقبلي للخدمات
        (أو أي جزء منها).
      </p>
      <p dir="rtl" class="privacy-subtitle">
        4. تسجيل المستخدم
      </p>
      <p dir="rtl" class="privacy-text">
        قد يُطلب منك التسجيل لاستخدام الخدمات. أنت توافق على الحفاظ على سرية
        كلمة المرور الخاصة بك وستكون مسؤولاً عن جميع استخدامات حسابك وكلمة
        المرور الخاصة بك. نحن نحتفظ بالحق في إزالة أو استعادة أو تغيير اسم
        المستخدم الذي تحدده إذا قررنا، وفقًا لتقديرنا الخاص، أن اسم المستخدم هذا
        غير مناسب أو فاحش أو غير مرغوب فيه بأي شكل من الأشكال.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        5. الاشتراكات
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        الفواتير والتجديد
      </p>
      <p dir="rtl" class="privacy-text">
        سيستمر اشتراكك ويتم تجديده تلقائيًا ما لم يتم إلغاؤه. أنت توافق على فرض
        رسوم على طريقة الدفع الخاصة بك على أساس متكرر دون الحاجة إلى موافقتك
        المسبقة على كل رسوم متكررة، حتى يتم إلغاء الطلب المعمول به. يعتمد طول
        دورة الفوترة على نوع خطة الاشتراك التي تختارها عند الاشتراك في الخدمات.
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        إلغاء
      </p>
      <p dir="rtl" class="privacy-text">
        يمكنك إلغاء اشتراكك في أي وقت عن طريق تسجيل الدخول إلى حسابك. سيصبح
        الإلغاء ساري المفعول في نهاية المدة المدفوعة الحالية. إذا كانت لديك أي
        أسئلة أو كنت غير راضٍ عن خدماتنا، فيرجى مراسلتنا عبر البريد الإلكتروني
        على hello@tigrisstudios.com.
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        تغييرات الرسوم
      </p>
      <p dir="rtl" class="privacy-text">
        يجوز لنا، من وقت لآخر، إجراء تغييرات على رسوم الاشتراك وسنبلغك بأي
        تغييرات في الأسعار وفقًا للقانون المعمول به.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        6. الأنشطة المحظورة
      </p>
      <p dir="rtl" class="privacy-text">
        لا يجوز لك الوصول إلى الخدمات أو استخدامها لأي غرض آخر غير الذي نوفر
        الخدمات من أجله. لا يجوز استخدام الخدمات فيما يتعلق بأي مساعي تجارية
        باستثناء تلك التي تم اعتمادها أو اعتمادها على وجه التحديد من قبلنا.
      </p>
      <p dir="rtl" class="privacy-text">
        باعتبارك مستخدمًا للخدمات، فإنك توافق على عدم القيام بما يلي:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li className="privacy-text">
          استرداد البيانات أو المحتوى الآخر بشكل منهجي من الخدمات لإنشاء أو
          تجميع، بشكل مباشر أو غير مباشر، مجموعة أو تجميع أو قاعدة بيانات أو
          دليل دون الحصول على إذن كتابي منا.
        </li>
        <li className="privacy-text">
          خداعنا أو احتيالنا أو تضليلنا نحن والمستخدمين الآخرين، خاصة في أي
          محاولة لمعرفة معلومات الحساب الحساسة مثل كلمات مرور المستخدم.
        </li>
        <li className="privacy-text">
          التحايل على ميزات الخدمات المتعلقة بالأمان أو تعطيلها أو التدخل فيها
          بأي شكل آخر، بما في ذلك الميزات التي تمنع أو تقيد استخدام أو نسخ أي
          محتوى أو تفرض قيودًا على استخدام الخدمات و/أو المحتوى الموجود فيها.
        </li>
        <li className="privacy-text">
          الحط من قدرنا أو تشويهنا أو الإضرار بأي شكل من الأشكال بنا و/أو
          الخدمات.
        </li>
        <li className="privacy-text">
          استخدام أي معلومات تم الحصول عليها من الخدمات لمضايقة شخص آخر أو إساءة
          معاملته أو إيذائه.
        </li>
        <li className="privacy-text">
          إساءة استخدام خدمات الدعم التي نقدمها أو إرسال تقارير كاذبة عن إساءة
          الاستخدام أو سوء السلوك.
        </li>
        <li className="privacy-text">
          استخدام الخدمات بطريقة لا تتفق مع أي قوانين أو لوائح معمول بها.
        </li>
        <li className="privacy-text">
          المشاركة في التأطير غير المصرح به للخدمات أو الارتباط بها.
        </li>
        <li className="privacy-text">
          تحميل أو نقل (أو محاولة تحميل أو نقل) الفيروسات أو أحصنة طروادة أو
          غيرها من المواد، بما في ذلك الاستخدام المفرط للأحرف الكبيرة والبريد
          العشوائي (النشر المستمر للنص المتكرر)، الذي يتعارض مع الاستخدام
          والتمتع دون انقطاع بالخدمات أو تعديل أو إضعاف أو تعطيل أو تغيير أو
          التدخل في استخدام الخدمات أو ميزاتها أو وظائفها أو تشغيلها أو صيانتها.
        </li>
        <li className="privacy-text">
          المشاركة في أي استخدام آلي للنظام، مثل استخدام البرامج النصية لإرسال
          التعليقات أو الرسائل، أو استخدام أي أدوات استخراج البيانات أو
          الروبوتات أو أدوات مماثلة لجمع البيانات واستخلاصها.
        </li>
        <li className="privacy-text">
          حذف حقوق الطبع والنشر أو إشعار حقوق الملكية الأخرى من أي محتوى.
        </li>
        <li className="privacy-text">
          محاولة انتحال شخصية مستخدم أو شخص آخر أو استخدام اسم مستخدم مستخدم
          آخر.
        </li>
        <li className="privacy-text">
          تحميل أو إرسال (أو محاولة تحميل أو إرسال) أي مادة تعمل كآلية جمع أو
          إرسال معلومات سلبية أو نشطة، بما في ذلك على سبيل المثال لا الحصر،
          تنسيقات تبادل الرسومات الواضحة ('gifs')، 1×1 بكسل، أخطاء الويب، ملفات
          تعريف الارتباط أو أجهزة أخرى مماثلة (يُشار إليها أحيانًا باسم "برامج
          التجسس" أو "آليات التجميع السلبية" أو "أجهزة الكمبيوتر الشخصية").
        </li>
        <li className="privacy-text">
          التدخل في الخدمات أو الشبكات أو الخدمات المتصلة بالخدمات أو تعطيلها أو
          إنشاء عبء لا داعي له.
        </li>
        <li className="privacy-text">
          مضايقة أو إزعاج أو تخويف أو تهديد أي من موظفينا أو وكلائنا المشاركين
          في تقديم أي جزء من الخدمات لك.
        </li>
        <li className="privacy-text">
          محاولة تجاوز أي إجراءات من الخدمات تهدف إلى منع أو تقييد الوصول إلى
          الخدمات، أو أي جزء من الخدمات.
        </li>
        <li className="privacy-text">
          نسخ أو تعديل برامج الخدمات، بما في ذلك على سبيل المثال لا الحصر، Flash
          أو PHP أو HTML أو JavaScript أو أي تعليمات برمجية أخرى.
        </li>
        <li className="privacy-text">
          باستثناء ما يسمح به القانون المعمول به، يمكنك فك تشفير أو تفكيك أو
          تفكيك أو إجراء هندسة عكسية لأي من البرامج التي تشتمل على الخدمات أو
          تشكل جزءًا منها بأي شكل من الأشكال.
        </li>
        <li className="privacy-text">
          باستثناء ما قد يكون نتيجة لاستخدام محرك البحث القياسي أو متصفح
          الإنترنت، أو استخدام أو إطلاق أو تطوير أو توزيع أي نظام آلي، بما في
          ذلك على سبيل المثال لا الحصر، أي عنكبوت أو روبوت أو أداة غش أو أداة
          استخراج أو قارئ غير متصل بالإنترنت يصل إلى الخدمات، أو استخدام أو
          تشغيل أي برنامج نصي أو برامج أخرى غير مصرح بها.
        </li>
        <li className="privacy-text">
          استخدم وكيل شراء أو وكيل شراء لإجراء عمليات شراء على الخدمات.
        </li>
        <li className="privacy-text">
          القيام بأي استخدام غير مصرح به للخدمات، بما في ذلك جمع أسماء
          المستخدمين و/أو عناوين البريد الإلكتروني للمستخدمين عن طريق الوسائل
          الإلكترونية أو غيرها بغرض إرسال بريد إلكتروني غير مرغوب فيه، أو إنشاء
          حسابات مستخدمين بوسائل آلية أو تحت ذرائع كاذبة.
        </li>
        <li className="privacy-text">
          استخدم الخدمات كجزء من أي جهد للتنافس معنا أو استخدام الخدمات و/أو
          المحتوى لأي مسعى أو مشروع تجاري يدر الدخل.
        </li>
      </ul>

      <p dir="rtl" class="privacy-subtitle">
        7. المساهمات التي يقدمها المستخدمون
      </p>

      <p dir="rtl" class="privacy-text">
        لا تتيح الخدمات للمستخدمين إرسال المحتوى أو نشره.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        8. ترخيص المساهمة
      </p>

      <p dir="rtl" class="privacy-text">
        توافق أنت والخدمات على أنه يجوز لنا الوصول إلى أي معلومات وبيانات شخصية
        تقدمها وتخزينها ومعالجتها واستخدامها وفقًا لشروط سياسة الخصوصية
        واختياراتك (بما في ذلك الإعدادات).
      </p>

      <p dir="rtl" class="privacy-text">
        من خلال تقديم اقتراحات أو تعليقات أخرى بخصوص الخدمات، فإنك توافق على أنه
        يمكننا استخدام هذه التعليقات ومشاركتها لأي غرض دون تعويضك.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        9. ترخيص تطبيقات الهاتف المحمول
      </p>

      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        استخدام الترخيص
      </p>

      <p dir="rtl" class="privacy-text">
        إذا قمت بالوصول إلى الخدمات عبر التطبيق، فإننا نمنحك حقًا محدودًا وغير
        حصري وغير قابل للتحويل لتثبيت التطبيق واستخدامه على الأجهزة الإلكترونية
        اللاسلكية التي تملكها أو تتحكم فيها، وللوصول إلى التطبيق واستخدامه على
        هذه الأجهزة تتوافق بشكل صارم مع شروط وأحكام ترخيص تطبيقات الهاتف المحمول
        الواردة في هذه الشروط القانونية. لا يجوز لك: (1) باستثناء ما يسمح به
        القانون المعمول به، إلغاء الترجمة أو الهندسة العكسية أو التفكيك أو
        محاولة استخلاص كود المصدر أو فك تشفير التطبيق؛ (2) إجراء أي تعديل أو
        تكييف أو تحسين أو تعزيز أو ترجمة أو عمل مشتق من التطبيق؛ (3) انتهاك أي
        قوانين أو قواعد أو لوائح معمول بها فيما يتعلق بوصولك إلى التطبيق أو
        استخدامه؛ (4) إزالة أو تغيير أو إخفاء أي إشعار ملكية (بما في ذلك أي
        إشعار بحقوق الطبع والنشر أو العلامة التجارية) تم نشره من قبلنا أو من قبل
        الجهات المرخصة للتطبيق؛ (5) استخدام التطبيق لأي مسعى لتوليد الدخل، أو
        مشروع تجاري، أو أي غرض آخر لم يتم تصميمه أو المقصود منه؛ (6) إتاحة
        التطبيق عبر شبكة أو بيئة أخرى تسمح بالوصول أو الاستخدام بواسطة أجهزة أو
        مستخدمين متعددين في نفس الوقت؛ (7) استخدام التطبيق لإنشاء منتج أو خدمة
        أو برنامج يكون، بشكل مباشر أو غير مباشر، منافسًا للتطبيق أو بديلاً له
        بأي شكل من الأشكال؛ (8) استخدام التطبيق لإرسال استفسارات آلية إلى أي
        موقع ويب أو لإرسال أي بريد إلكتروني تجاري غير مرغوب فيه؛ أو (9) استخدام
        أي معلومات ملكية أو أي من واجهاتنا أو حقوق الملكية الفكرية الأخرى لدينا
        في تصميم أو تطوير أو تصنيع أو ترخيص أو توزيع أي تطبيقات أو ملحقات أو
        أجهزة للاستخدام مع التطبيق.
      </p>

      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        أجهزة أبل وأندرويد
      </p>

      <p dir="rtl" class="privacy-text">
        تنطبق الشروط التالية عند استخدام التطبيق الذي تم الحصول عليه من Apple
        Store أو Google Play (يُشار إلى كل منهما باسم "موزع التطبيق") للوصول إلى
        الخدمات: (1) يقتصر الترخيص الممنوح لك لتطبيقنا على ترخيص غير قابل
        للتحويل لاستخدام التطبيق على جهاز يستخدم أنظمة التشغيل Apple iOS أو
        Android، حسب الاقتضاء، ووفقًا لقواعد الاستخدام المنصوص عليها في شروط
        خدمة موزع التطبيقات المعمول بها؛ (2) نحن مسؤولون عن تقديم أي خدمات صيانة
        ودعم فيما يتعلق بالتطبيق على النحو المحدد في شروط وأحكام ترخيص تطبيق
        الهاتف المحمول الوارد في هذه الشروط القانونية أو كما هو مطلوب بموجب
        القانون المعمول به، وأنت تقر بأن كل موزع تطبيق ليس لديه أي التزام على
        الإطلاق بتقديم أي خدمات صيانة ودعم فيما يتعلق بالتطبيق؛ (3) في حالة فشل
        التطبيق في التوافق مع أي ضمان معمول به، يجوز لك إخطار موزع التطبيق
        المعني، ويجوز لموزع التطبيق، وفقًا لشروطه وسياساته، استرداد سعر الشراء،
        إن وجد، المدفوع بالنسبة للتطبيق، وإلى الحد الأقصى الذي يسمح به القانون
        المعمول به، لن يكون لدى موزع التطبيق أي التزام ضمان آخر على الإطلاق فيما
        يتعلق بالتطبيق؛ (4) أنت تقر وتضمن أنك (1) لا تتواجد في دولة تخضع لحظر
        تفرضه حكومة الولايات المتحدة، أو تم تصنيفها من قبل حكومة الولايات
        المتحدة كدولة "داعمة للإرهاب" و(2) أنك لست كذلك مدرج في أي قائمة حكومية
        أمريكية للأطراف المحظورة أو المقيدة؛ (5) يجب عليك الالتزام بشروط اتفاقية
        الطرف الثالث المعمول بها عند استخدام التطبيق، على سبيل المثال. إذا كان
        لديك تطبيق VoIP، فيجب ألا تنتهك اتفاقية خدمة البيانات اللاسلكية الخاصة
        بهم عند استخدام التطبيق؛ و(6) أنت تقر وتوافق على أن موزعي التطبيقات هم
        جهات خارجية مستفيدة من الشروط والأحكام الواردة في ترخيص تطبيقات الهاتف
        المحمول هذا الوارد في هذه الشروط القانونية، وأن كل موزع تطبيق سيكون له
        الحق (وسيعتبر مقبولاً) الحق) في إنفاذ الشروط والأحكام الواردة في ترخيص
        تطبيقات الهاتف المحمول هذا الوارد في هذه الشروط القانونية ضدك باعتبارك
        طرفًا ثالثًا مستفيدًا منها.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        10. المعلنون
      </p>

      <p dir="rtl" class="privacy-text">
        نحن نسمح للمعلنين بعرض إعلاناتهم وغيرها من المعلومات في مناطق معينة من
        الخدمات، مثل إعلانات الشريط الجانبي أو إعلانات البانر. نحن ببساطة نوفر
        المساحة لوضع مثل هذه الإعلانات، وليس لدينا أي علاقة أخرى مع المعلنين.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        11. إدارة الخدمات
      </p>

      <p dir="rtl" class="privacy-text">
        نحن نحتفظ بالحق، ولكن ليس الالتزام، في: (1) مراقبة الخدمات بحثًا عن
        انتهاكات لهذه الشروط القانونية؛ (2) اتخاذ الإجراءات القانونية المناسبة
        ضد أي شخص، وفقًا لتقديرنا الخاص، ينتهك القانون أو هذه الشروط القانونية،
        بما في ذلك على سبيل المثال لا الحصر، إبلاغ هذا المستخدم إلى سلطات إنفاذ
        القانون؛ (3) وفقًا لتقديرنا الخاص ودون حصر، رفض أو تقييد الوصول إلى أو
        الحد من توفر أو تعطيل (إلى الحد الممكن من الناحية التكنولوجية) أي من
        مساهماتك أو أي جزء منها؛ (4) وفقًا لتقديرنا الخاص ودون أي قيود أو إشعار
        أو مسؤولية، للإزالة من الخدمات أو تعطيل جميع الملفات والمحتوى الزائد في
        الحجم أو الذي يشكل عبئًا بأي شكل من الأشكال على أنظمتنا؛ و(5) إدارة
        الخدمات بطريقة مصممة لحماية حقوقنا وممتلكاتنا وتسهيل حسن سير الخدمات.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        12. سياسة الخصوصية
      </p>

      <p dir="rtl" class="privacy-text">
        نحن نهتم بخصوصية البيانات وأمنها. يرجى مراجعة سياسة الخصوصية الخاصة بنا.
        باستخدام الخدمات، فإنك توافق على الالتزام بسياسة الخصوصية الخاصة بنا،
        والتي تم دمجها في هذه الشروط القانونية. يرجى العلم أن الخدمات مستضافة
        فيالإمارات العربية المتحدة. إذا قمت بالوصول إلى الخدمات من أي منطقة أخرى
        في العالم بموجب قوانين أو متطلبات أخرى تحكم جمع البيانات الشخصية أو
        استخدامها أو الكشف عنها والتي تختلف عن القوانين المعمول بها فيالإمارات
        العربية المتحدة، فمن خلال استمرارك في استخدام الخدمات، فإنك تقوم بنقل
        بياناتك إلىالإمارات العربية المتحدة، وأنت توافق صراحةً على نقل بياناتك
        ومعالجتها فيهاالإمارات العربية المتحدة.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        13. المدة والإنهاء
      </p>

      <p dir="rtl" class="privacy-text">
        تظل هذه الشروط القانونية سارية المفعول والتأثير بالكامل أثناء استخدامك
        للخدمات. دون تقييد أي شرط آخر لهذه الشروط القانونية، نحن نحتفظ بالحق،
        وفقًا لتقديرنا الخاص ودون إشعار أو مسؤولية، في رفض الوصول إلى الخدمات
        واستخدامها (بما في ذلك حظر عناوين IP معينة)، لأي شخص لأي سبب أو لـ بدون
        سبب، بما في ذلك على سبيل المثال لا الحصر، انتهاك أي تمثيل أو ضمان أو
        ميثاق وارد في هذه الشروط القانونية أو أي قانون أو لائحة معمول بها. يجوز
        لنا إنهاء استخدامك أو مشاركتك في الخدمات أو حذفهاحسابك وأي محتوى أو
        معلومات قمت بنشرها في أي وقت، دون سابق إنذار، وفقًا لتقديرنا وحدنا.
      </p>

      <p dir="rtl" class="privacy-text">
        إذا قمنا بإنهاء حسابك أو تعليقه لأي سبب من الأسباب، يُحظر عليك التسجيل
        وإنشاء حساب جديد باسمك أو اسم مزيف أو مستعار أو اسم أي طرف ثالث، حتى لو
        كنت تتصرف نيابة عن الطرف الثالث. حزب. بالإضافة إلى إنهاء حسابك أو
        تعليقه، فإننا نحتفظ بالحق في اتخاذ الإجراء القانوني المناسب، بما في ذلك،
        على سبيل المثال لا الحصر، متابعة التعويضات المدنية والجنائية والزجرية.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        14. التعديلات والانقطاعات
      </p>

      <p dir="rtl" class="privacy-text">
        نحن نحتفظ بالحق في تغيير أو تعديل أو إزالة محتويات الخدمات في أي وقت أو
        لأي سبب وفقًا لتقديرنا الخاص دون إشعار. ومع ذلك، ليس لدينا أي التزام
        بتحديث أي معلومات عن خدماتنا. لن نكون مسؤولين تجاهك أو تجاه أي طرف ثالث
        عن أي تعديل أو تغيير في الأسعار أو تعليق أو إيقاف الخدمات.
      </p>

      <p dir="rtl" class="privacy-text">
        لا يمكننا ضمان أن الخدمات ستكون متاحة في جميع الأوقات. قد نواجه مشكلات
        في الأجهزة أو البرامج أو مشكلات أخرى أو نحتاج إلى إجراء صيانة تتعلق
        بالخدمات، مما يؤدي إلى انقطاعات أو تأخيرات أو أخطاء. نحن نحتفظ بالحق في
        تغيير الخدمات أو مراجعتها أو تحديثها أو تعليقها أو إيقافها أو تعديلها
        بأي شكل آخر في أي وقت أو لأي سبب دون إشعارك. أنت توافق على أننا لا نتحمل
        أي مسؤولية من أي نوع عن أي خسارة أو ضرر أو إزعاج ناتج عن عدم قدرتك على
        الوصول إلى الخدمات أو استخدامها أثناء أي توقف أو توقف للخدمات. لن يتم
        تفسير أي شيء في هذه الشروط القانونية على أنه يلزمنا بالحفاظ على الخدمات
        ودعمها أو تقديم أي تصحيحات أو تحديثات أو إصدارات فيما يتعلق بها.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        15. القانون الحاكم
      </p>

      <p dir="rtl" class="privacy-text">
        تخضع هذه الشروط القانونية ويتم تعريفها وفقًا لقوانينالإمارات العربية
        المتحدة. توافق أنت وTigris Studios FZCO بشكل لا رجعة فيه على أن
        محاكمالتتمتع دولة الإمارات العربية المتحدة بالولاية القضائية الحصرية لحل
        أي نزاع قد ينشأ فيما يتعلق بهذه الشروط القانونية.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        16. حل النزاعات
      </p>

      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        المفاوضات غير الرسمية
      </p>

      <p dir="rtl" class="privacy-text">
        لتسريع الحل والتحكم في تكلفة أي نزاع أو خلاف أو مطالبة تتعلق بهذه الشروط
        القانونية (يُشار إلى كل منها باسم "نزاع" وبشكل جماعي باسم "النزاعات")
        المرفوعة بواسطتك أو بنا (بشكل فردي، "طرف" وبشكل جماعي، "الأطراف")، يوافق
        الطرفان على المحاولة أولاً للتفاوض على أي نزاع (باستثناء تلك النزاعات
        المنصوص عليها صراحةً أدناه) بشكل غير رسمي لمدة لا تقل عن14 أيام قبل
        الشروع في التحكيم. تبدأ هذه المفاوضات غير الرسمية بناء على إشعار كتابي
        من أحد الطرفين إلى الطرف الآخر.
      </p>

      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        قيود
      </p>

      <p dir="rtl" class="privacy-text">
        يتفق الطرفان على أن أي تحكيم يجب أن يقتصر على النزاع بين الطرفين بشكل
        فردي. إلى أقصى حد يسمح به القانون، (أ) لا يجوز ضم أي تحكيم إلى أي إجراء
        آخر؛ (ب) لا يوجد حق أو سلطة للتحكيم في أي نزاع على أساس دعوى جماعية أو
        استخدام إجراءات الدعوى الجماعية؛ و(ج) لا يوجد حق أو سلطة لعرض أي نزاع
        بصفة تمثيلية مزعومة نيابة عن عامة الناس أو أي أشخاص آخرين.
      </p>

      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        الاستثناءات من المفاوضات غير الرسمية والتحكيم
      </p>

      <p dir="rtl" class="privacy-text">
        يتفق الطرفان على أن النزاعات التالية لا تخضع للأحكام المذكورة أعلاه فيما
        يتعلق بالمفاوضات غير الرسمية والتحكيم الملزم: (أ) أي نزاعات تسعى إلى
        إنفاذ أو حماية، أو تتعلق بصحة أي من حقوق الملكية الفكرية لأحد الطرفين؛
        (ب) أي نزاع يتعلق أو ينشأ عن ادعاءات السرقة أو القرصنة أو انتهاك
        الخصوصية أو الاستخدام غير المصرح به؛ و(ج) أي مطالبة بالتعويض الزجري. إذا
        تبين أن هذا الشرط غير قانوني أو غير قابل للتنفيذ، فلن يختار أي من
        الطرفين التحكيم في أي نزاع يندرج ضمن هذا الجزء من هذا الحكم الذي يتبين
        أنه غير قانوني أو غير قابل للتنفيذ ويجب أن يتم الفصل في هذا النزاع من
        قبل محكمة مختصة داخل المحاكم المدرجة لـ الاختصاص القضائي المذكور أعلاه،
        ويوافق الطرفان على الخضوع للاختصاص القضائي الشخصي لتلك المحكمة.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        17. التصحيحات
      </p>

      <p dir="rtl" class="privacy-text">
        قد تكون هناك معلومات حول الخدمات تحتوي على أخطاء مطبعية أو عدم دقة أو
        سهو، بما في ذلك الأوصاف والتسعير والتوفر ومعلومات أخرى متنوعة. نحن نحتفظ
        بالحق في تصحيح أي أخطاء أو عدم دقة أو سهو وتغيير أو تحديث المعلومات
        الموجودة على الخدمات في أي وقت، دون إشعار مسبق.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        18. إخلاء المسؤولية
      </p>

      <p dir="rtl" class="privacy-text">
        يتم تقديم الخدمات على أساس "كما هي" و"كما هو متاح". أنت توافق على أن
        استخدامك للخدمات سيكون على مسؤوليتك وحدك. إلى أقصى حد يسمح به القانون،
        نحن نخلي مسؤوليتنا من جميع الضمانات، الصريحة أو الضمنية، فيما يتعلق
        بالخدمات واستخدامك لها، بما في ذلك، على سبيل المثال لا الحصر، الضمانات
        الضمنية لقابلية التسويق والملاءمة لغرض معين وعدم الانتهاك. نحن لا نقدم
        أي ضمانات أو تعهدات بشأن دقة أو اكتمال محتوى الخدمات أو محتوى أي مواقع
        ويب أو تطبيقات جوال مرتبطة بالخدمات ولن نتحمل أي مسؤولية أو مسؤولية عن
        أي (1) أخطاء أو أخطاء أو عدم دقة المحتوى والمواد، (2) الإصابة الشخصية أو
        الأضرار بالممتلكات، من أي طبيعة كانت، الناتجة عن وصولك إلى الخدمات
        واستخدامها، (3) أي وصول غير مصرح به أو استخدام لخوادمنا الآمنة و/أو أي
        وجميع المعلومات الشخصية و/أو / أو المعلومات المالية المخزنة فيها، (4) أي
        انقطاع أو توقف للإرسال إلى الخدمات أو منها، (5) أي أخطاء أو فيروسات أو
        أحصنة طروادة أو ما شابه ذلك قد يتم نقلها إلى الخدمات أو من خلالها بواسطة
        أي طرف ثالث، و/أو (6) أي أخطاء أو إغفالات في أي محتوى ومواد أو أي خسارة
        أو ضرر من أي نوع يحدث نتيجة لاستخدام أي محتوى منشور أو منقول أو بطريقة
        أخرى متاح عبر الخدمات. نحن لا نضمن أو نؤيد أو نضمن أو نتحمل المسؤولية عن
        أي منتج أو خدمة يتم الإعلان عنها أو تقديمها من قبل طرف ثالث من خلال
        الخدمات، أو أي موقع ويب مرتبط تشعبيًا، أو أي موقع ويب أو تطبيق جوال يظهر
        في أي لافتة أو إعلانات أخرى، ونحن لن نفعل ذلك كن طرفًا أو مسؤولاً بأي
        شكل من الأشكال عن مراقبة أي معاملة بينك وبين أي من مقدمي المنتجات أو
        الخدمات من الجهات الخارجية. كما هو الحال مع شراء منتج أو خدمة من خلال أي
        وسيلة أو في أي بيئة، يجب عليك استخدام أفضل حكم لديك وتوخي الحذر حيثما
        كان ذلك مناسبًا.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        19. حدود المسؤولية
      </p>

      <p dir="rtl" class="privacy-text">
        لن نكون نحن أو مدرائنا أو موظفينا أو وكلائنا مسؤولين بأي حال من الأحوال
        تجاهك أو تجاه أي طرف ثالث عن أي أضرار مباشرة أو غير مباشرة أو تبعية أو
        تحذيرية أو عرضية أو خاصة أو تأديبية، بما في ذلك خسارة الأرباح أو خسارة
        الإيرادات أو فقدان البيانات، أو أي أضرار أخرى تنشأ عن استخدامك للخدمات،
        حتى لو تم إبلاغنا بإمكانية حدوث مثل هذه الأضرار. بغض النظر عن أي شيء
        مخالف لما هو وارد هنا، فإن مسؤوليتنا تجاهك لأي سبب كان وبغض النظر عن شكل
        الإجراء، ستقتصر في جميع الأوقات على المبلغ الأقل من المبلغ المدفوع، إن
        وجد، بواسطتك لنا أو . لا تسمح بعض قوانين الولايات الأمريكية والقوانين
        الدولية بوضع قيود على الضمانات الضمنية أو استبعاد أو تقييد بعض الأضرار.
        إذا كانت هذه القوانين تنطبق عليك، فقد لا تنطبق عليك بعض أو كل إخلاءات
        المسؤولية أو القيود المذكورة أعلاه، وقد يكون لديك حقوق إضافية.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        20. التعويض
      </p>

      <p dir="rtl" class="privacy-text">
        أنت توافق على الدفاع عنا وتعويضنا وحمايتها، بما في ذلك الشركات التابعة
        لنا والشركات التابعة لنا وجميع مسؤولينا ووكلائنا وشركائنا وموظفينا، من
        وضد أي خسارة أو ضرر أو مسؤولية أو مطالبة أو طلب، بما في ذلك المحامين
        المعقولين. الرسوم والنفقات التي يتحملها أي طرف ثالث بسبب أو تنشأ عن:(1)
        استخدام الخدمات؛ (2) خرق هذه الشروط القانونية؛ (3) أي خرق لإقراراتك
        وضماناتك المنصوص عليها في هذه الشروط القانونية؛ (4) انتهاكك لحقوق الطرف
        الثالث، بما في ذلك على سبيل المثال لا الحصر حقوق الملكية الفكرية؛ أو (5)
        أي عمل ضار صريح تجاه أي مستخدم آخر للخدمات الذي اتصلت به عبر الخدمات.
        وعلى الرغم مما سبق، فإننا نحتفظ بالحق، على نفقتك الخاصة، في تولي الدفاع
        الحصري والسيطرة على أي مسألة يُطلب منك تعويضنا عنها، وتوافق على التعاون،
        على نفقتك الخاصة، في دفاعنا عن هذه المطالبات. سنبذل جهودًا معقولة
        لإعلامك بأي مطالبة أو إجراء أو إجراء يخضع لهذا التعويض عند علمنا به.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        21. بيانات المستخدم
      </p>

      <p dir="rtl" class="privacy-text">
        سنحتفظ ببعض البيانات التي ترسلها إلى الخدمات بغرض إدارة أداء الخدمات،
        بالإضافة إلى البيانات المتعلقة باستخدامك للخدمات. على الرغم من أننا نقوم
        بإجراء نسخ احتياطية روتينية منتظمة للبيانات، إلا أنك المسؤول الوحيد عن
        جميع البيانات التي ترسلها أو التي تتعلق بأي نشاط قمت به باستخدام
        الخدمات. أنت توافق على أننا لن نتحمل أي مسؤولية تجاهك عن أي خسارة أو تلف
        في أي من هذه البيانات، وأنت بموجب هذا تتنازل عن أي حق في رفع دعوى ضدنا
        ينشأ عن أي خسارة أو تلف في هذه البيانات.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        22. الاتصالات والمعاملات والتوقيعات الإلكترونية
      </p>

      <p dir="rtl" class="privacy-text">
        تشكل زيارة الخدمات وإرسال رسائل البريد الإلكتروني إلينا واستكمال النماذج
        عبر الإنترنت اتصالات إلكترونية. أنت توافق على تلقي الاتصالات
        الإلكترونية، وتوافق على أن جميع الاتفاقيات والإشعارات والإفصاحات وغيرها
        من الاتصالات التي نقدمها لك إلكترونيًا، عبر البريد الإلكتروني وعلى
        الخدمات، تلبي أي متطلبات قانونية بأن تكون هذه الاتصالات كتابية. أنت
        توافق بموجب هذا على استخدام التوقيعات الإلكترونية والعقود والأوامر
        والسجلات الأخرى، وعلى التسليم الإلكتروني للإشعارات والسياسات وسجلات
        المعاملات التي بدأناها أو أكملناها بواسطتنا أو عبر الخدمات. أنت تتنازل
        بموجب هذا عن أي حقوق أو متطلبات بموجب أي قوانين أو لوائح أو قواعد أو
        مراسيم أو قوانين أخرى في أي ولاية قضائية تتطلب التوقيع الأصلي أو التسليم
        أو الاحتفاظ بالسجلات غير الإلكترونية، أو المدفوعات أو منح الاعتمادات بأي
        وسيلة أخرى من الوسائل الإلكترونية.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        23. المستخدمون والمقيمون في كاليفورنيا
      </p>

      <p dir="rtl" class="privacy-text">
        إذا لم يتم حل أي شكوى لدينا بشكل مرض، يمكنك الاتصال بوحدة مساعدة الشكاوى
        التابعة لقسم خدمات المستهلك التابع لإدارة شؤون المستهلك في كاليفورنيا
        كتابيًا على 1625 North Market Blvd., Suite N 112, Sacramento, California
        95834 أو عبر الهاتف على الرقم (800) 952-5210 أو (916) 445-1254.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        24. متنوعة
      </p>

      <p dir="rtl" class="privacy-text">
        تشكل هذه الشروط القانونية وأي سياسات أو قواعد تشغيل ننشرها على الخدمات
        أو فيما يتعلق بالخدمات كامل الاتفاقية والتفاهم بينك وبيننا. إن فشلنا في
        ممارسة أو إنفاذ أي حق أو شرط من هذه الشروط القانونية لا يشكل تنازلاً عن
        هذا الحق أو الحكم. تعمل هذه الشروط القانونية إلى أقصى حد يسمح به
        القانون. يجوز لنا التنازل عن أي أو كل حقوقنا والتزاماتنا للآخرين في أي
        وقت. لن نكون مسؤولين أو مسؤولين عن أي خسارة أو ضرر أو تأخير أو فشل في
        التصرف لأي سبب خارج عن سيطرتنا المعقولة. إذا تم تحديد أي حكم أو جزء من
        حكم من هذه الشروط القانونية على أنه غير قانوني أو باطل أو غير قابل
        للتنفيذ، فإن هذا الحكم أو جزء من الحكم يعتبر قابلاً للفصل عن هذه الشروط
        القانونية ولا يؤثر على صحة وقابلية تنفيذ أي أحكام متبقية. لا توجد علاقة
        مشروع مشترك أو شراكة أو توظيف أو وكالة بينك وبيننا نتيجة لهذه الشروط
        القانونية أو استخدام الخدمات. أنت توافق على أن هذه الشروط القانونية لن
        يتم تفسيرها ضدنا بحكم صياغتها. أنت بموجب هذا تتنازل عن أي وجميع الدفاعات
        التي قد تكون لديك بناءً على النموذج الإلكتروني لهذه الشروط القانونية
        وعدم توقيع الأطراف فيها لتنفيذ هذه الشروط القانونية.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        25. اتصل بنا
      </p>

      <p dir="rtl" class="privacy-text">
        من أجل حل شكوى بخصوص الخدمات أو للحصول على مزيد من المعلومات حول استخدام
        الخدمات، يرجى الاتصال بنا على:
      </p>

      <p
        dir="rtl"
        class="privacy-text"
        style={{ fontWeight: 700, marginBottom: 0 }}
      >
        استوديوهات دجلة FZCO
      </p>
      <p
        dir="rtl"
        class="privacy-text"
        style={{ fontWeight: 700, marginBottom: 0 }}
      >
        16235-001، IFZA Business Park، DDP
      </p>
      <p
        dir="rtl"
        class="privacy-text"
        style={{ fontWeight: 700, marginBottom: 0 }}
      >
        دبي الامارات العربية المتحدة
      </p>
      <p
        dir="rtl"
        class="privacy-text"
        style={{ fontWeight: 700, marginBottom: 0 }}
      >
        الإمارات العربية المتحدة
      </p>
      <p
        dir="rtl"
        class="privacy-text"
        style={{ fontWeight: 700, marginBottom: 0 }}
      >
        hello@tigrisstudios.com
      </p>
    </>
  );
};

export default TermsOfUseAra;
