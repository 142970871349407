import React from "react";

export const PrivacyPolicyAra = () => {
  return (
    <>
      <p dir="rtl" class="privacy-title">
        سياسة الخصوصية
      </p>
      <p dir="rtl" style={{ fontWeight: 700 }} class="privacy-text">
        آخر تحديث 04 مارس 2024
      </p>
      <p dir="rtl" class="privacy-text">
        إشعار الخصوصية هذا لشركة Tigris Studios FZCO ('نحن"،"نحن'، أو 'ملكنا')،
        يصف كيف ولماذا قد نقوم بجمع و/أو تخزين و/أو استخدام و/أو مشاركة
        ('عملية') معلوماتك عند استخدام خدماتنا ('خدمات')، مثل عندما:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li dir="rtl" class="privacy-text">
          قم بتنزيل واستخدام تطبيق الهاتف المحمول الخاص بنا (Niya)، أو أي تطبيق
          آخر خاص بنا يرتبط بإشعار الخصوصية هذا
        </li>
        <li dir="rtl" class="privacy-text">
          شارك معنا بطرق أخرى ذات صلة، بما في ذلك أي مبيعات أو تسويق أو أحداث
        </li>
      </ul>
      <p dir="rtl" class="privacy-text">
        أسئلة أو استفسارات؟ستساعدك قراءة إشعار الخصوصية هذا على فهم حقوق وخيارات
        الخصوصية الخاصة بك. إذا كنت لا توافق على سياساتنا وممارساتنا، فيرجى عدم
        استخدام خدماتنا. إذا كان لا يزال لديك أي أسئلة أو استفسارات، يرجى
        الاتصال بنا على hello@tigrisstudios.com.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        ملخص النقاط الرئيسية
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        ما هي المعلومات الشخصية التي نقوم بمعالجتها؟ عندما تزور خدماتنا أو
        تستخدمها أو تتنقل فيها، قد نقوم بمعالجة المعلومات الشخصية اعتمادًا على
        كيفية تفاعلك معنا ومع الخدمات، والاختيارات التي تقوم بها، والمنتجات
        والميزات التي تستخدمها.
      </p>

      <p dir="rtl" class="privacy-text">
        هل نتلقى أي معلومات من أطراف ثالثة؟ نحن لا نتلقى أي معلومات من أطراف
        ثالثة.
      </p>
      <p dir="rtl" class="privacy-text">
        كيف نقوم بمعالجة المعلومات الخاصة بك؟ نقوم بمعالجة معلوماتك لتوفير
        خدماتنا وتحسينها وإدارتها، والتواصل معك، لأغراض الأمان ومنع الاحتيال،
        والامتثال للقانون. يجوز لنا أيضًا معالجة معلوماتك لأغراض أخرى بموافقتك.
        نحن نقوم بمعالجة معلوماتك فقط عندما يكون لدينا سبب قانوني وجيه للقيام
        بذلك.
      </p>
      <p dir="rtl" class="privacy-text">
        في أي المواقف ومع أي أطراف نشارك المعلومات الشخصية؟ يجوز لنا مشاركة
        المعلومات في مواقف محددة ومع أطراف ثالثة محددة.
      </p>
      <p dir="rtl" class="privacy-text">
        كيف نحافظ على أمان معلوماتك؟ لدينا عمليات وإجراءات تنظيمية وفنية لحماية
        معلوماتك الشخصية. ومع ذلك، لا يمكن ضمان أن يكون النقل الإلكتروني عبر
        الإنترنت أو تكنولوجيا تخزين المعلومات آمنًا بنسبة 100%، لذلك لا يمكننا
        أن نعد أو نضمن أن المتسللين أو مجرمي الإنترنت أو غيرهم من الأطراف
        الثالثة غير المصرح لهم لن يتمكنوا من التغلب على أمننا وجمع المعلومات
        والوصول إليها بشكل غير صحيح. أو سرقة أو تعديل معلوماتك.
      </p>
      <p dir="rtl" class="privacy-text">
        ما هي حقوقك؟ اعتمادًا على موقعك الجغرافي، قد يعني قانون الخصوصية المعمول
        به أن لديك حقوقًا معينة فيما يتعلق بمعلوماتك الشخصية.
      </p>
      <p dir="rtl" class="privacy-text">
        كيف تمارس حقوقك؟ أسهل طريقة لممارسة حقوقك هي عن طريق الاتصال بنا. سننظر
        في أي طلب ونتصرف بناءً عليه وفقًا لقوانين حماية البيانات المعمول بها.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        جدول المحتويات
      </p>
      <br />

      <p dir="rtl" class="privacy-subtitle">
        1. ما هي المعلومات التي نجمعها؟
      </p>
      <br />

      <p dir="rtl" class="privacy-subtitle">
        المعلومات الشخصية التي تكشفها لنا
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        باختصار:نقوم بجمع المعلومات الشخصية التي تقدمها لنا.
      </p>
      <p dir="rtl" class="privacy-text">
        نقوم بجمع المعلومات الشخصية التي تقدمها لنا طوعًا عند التسجيل في
        الخدمات، أو التعبير عن اهتمامك بالحصول على معلومات عنا أو عن منتجاتنا
        وخدماتنا، أو عند مشاركتك في الأنشطة المتعلقة بالخدمات، أو عند الاتصال
        بنا.
      </p>
      <p dir="rtl" class="privacy-text">
        المعلومات الشخصية التي تقدمها. تعتمد المعلومات الشخصية التي نجمعها على
        سياق تفاعلاتك معنا ومع الخدمات، والاختيارات التي تقوم بها، والمنتجات
        والميزات التي تستخدمها. قد تتضمن المعلومات الشخصية التي نجمعها ما يلي:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li dir="rtl" class="privacy-text">
          أسماء
        </li>
        <li dir="rtl" class="privacy-text">
          أرقام الهواتف
        </li>
        <li dir="rtl" class="privacy-text">
          عناوين البريد الإلكتروني
        </li>
        <li dir="rtl" class="privacy-text">
          أسماء المستخدمين
        </li>
      </ul>
      <p dir="rtl" class="privacy-text">
        معلومات حساسة. نحن لا نقوم بمعالجة المعلومات الحساسة.
      </p>
      <p dir="rtl" class="privacy-text">
        {`بيانات تسجيل الدخول لوسائل التواصل الاجتماعي.قد نوفر لك خيار التسجيل معنا باستخدام تفاصيل حساب الوسائط الاجتماعية الحالي الخاص بك، مثل حساب Facebook أو Twitter أو حساب الوسائط الاجتماعية الأخرى. إذا اخترت التسجيل بهذه الطريقة، فسنقوم بجمع المعلومات الموضحة في القسم المسمىأقل.


بيانات الطلب. إذا كنت تستخدم تطبيقنا (تطبيقاتنا)، فقد نقوم أيضًا بجمع المعلومات التالية إذا اخترت تزويدنا بإمكانية الوصول أو الإذن:`}
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li dir="rtl" class="privacy-text">
          معلومات تحديد الموقع الجغرافي. قد نطلب الوصول أو الإذن لتتبع المعلومات
          المستندة إلى الموقع من جهازك المحمول، إما بشكل مستمر أو أثناء استخدامك
          لتطبيق (تطبيقات) الهاتف المحمول الخاص بنا، لتوفير خدمات معينة تعتمد
          على الموقع. إذا كنت ترغب في تغيير وصولنا أو أذوناتنا، فيمكنك القيام
          بذلك في إعدادات جهازك.
        </li>
        <li dir="rtl" class="privacy-text">
          دفع الإخطارات. قد نطلب إرسال إشعارات إليك بشأن حسابك أو ميزات معينة
          للتطبيق (التطبيقات). إذا كنت ترغب في إلغاء الاشتراك في تلقي هذه
          الأنواع من الاتصالات، فيمكنك إيقاف تشغيلها في إعدادات جهازك.
        </li>
      </ul>
      <p dir="rtl" class="privacy-text">
        {`هذه المعلومات ضرورية في المقام الأول للحفاظ على أمان وتشغيل تطبيقنا (تطبيقاتنا)، ولاستكشاف الأخطاء وإصلاحها، ولأغراض التحليلات الداخلية وإعداد التقارير.


يجب أن تكون جميع المعلومات الشخصية التي تقدمها لنا صحيحة وكاملة ودقيقة، ويجب عليك إخطارنا بأي تغييرات تطرأ على هذه المعلومات الشخصية.
`}
      </p>
      <p dir="rtl" class="privacy-subtitle">
        المعلومات التي يتم جمعها تلقائيا
      </p>
      <br />

      <p dir="rtl" class="privacy-text" style={{ fontStyle: "italic" }}>
        باختصار:يتم جمع بعض المعلومات - مثل عنوان بروتوكول الإنترنت (IP) الخاص
        بك و/أو خصائص المتصفح والجهاز - تلقائيًا عند زيارتك لخدماتنا.
      </p>
      <p dir="rtl" class="privacy-text">
        {`

نقوم تلقائيًا بجمع معلومات معينة عند زيارتك للخدمات أو استخدامها أو التنقل فيها. لا تكشف هذه المعلومات عن هويتك المحددة (مثل اسمك أو معلومات الاتصال الخاصة بك) ولكنها قد تتضمن معلومات الجهاز والاستخدام، مثل عنوان IP الخاص بك والمتصفح وخصائص الجهاز ونظام التشغيل وتفضيلات اللغة وعناوين URL المرجعية واسم الجهاز والبلد والموقع ومعلومات حول كيفية ووقت استخدامك لخدماتنا والمعلومات الفنية الأخرى. هذه المعلومات ضرورية في المقام الأول للحفاظ على أمان وتشغيل خدماتنا، ولأغراض التحليلات الداخلية وإعداد التقارير.


مثل العديد من الشركات، نقوم أيضًا بجمع المعلومات من خلال ملفات تعريف الارتباط والتقنيات المماثلة.


المعلومات التي نجمعها تشمل:
`}
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li dir="rtl" class="privacy-text">
          بيانات السجل والاستخدام. بيانات السجل والاستخدام هي معلومات متعلقة
          بالخدمة والتشخيص والاستخدام والأداء، والتي تجمعها خوادمنا تلقائيًا عند
          الوصول إلى خدماتنا أو استخدامها والتي نسجلها في ملفات السجل. اعتمادًا
          على كيفية تفاعلك معنا، قد تتضمن بيانات السجل هذه عنوان IP الخاص بك
          ومعلومات الجهاز ونوع المتصفح والإعدادات والمعلومات المتعلقة بنشاطك في
          الخدمات. (مثل طوابع التاريخ/الوقت المرتبطة باستخدامك، والصفحات
          والملفات التي تم عرضها، وعمليات البحث، والإجراءات الأخرى التي تتخذها
          مثل الميزات التي تستخدمها)، ومعلومات أحداث الجهاز (مثل نشاط النظام،
          وتقارير الأخطاء (تسمى أحيانًا "مخلفات التعطل" ')، وإعدادات الأجهزة).
        </li>
        <li dir="rtl" class="privacy-text">
          بيانات الجهاز. نقوم بجمع بيانات الجهاز مثل المعلومات المتعلقة بجهاز
          الكمبيوتر أو الهاتف أو الجهاز اللوحي أو أي جهاز آخر تستخدمه للوصول إلى
          الخدمات. اعتمادًا على الجهاز المستخدم، قد تتضمن بيانات الجهاز هذه
          معلومات مثل عنوان IP الخاص بك (أو الخادم الوكيل)، وأرقام تعريف الجهاز
          والتطبيق، والموقع، ونوع المتصفح، وطراز الجهاز، وموفر خدمة الإنترنت
          و/أو مشغل شبكة الجوال، ونظام التشغيل، و معلومات تكوين النظام.
        </li>
        <li dir="rtl" class="privacy-text">
          بيانات الموقع. نقوم بجمع بيانات الموقع مثل المعلومات المتعلقة بموقع
          جهازك، والتي يمكن أن تكون دقيقة أو غير دقيقة. يعتمد مقدار المعلومات
          التي نجمعها على نوع وإعدادات الجهاز الذي تستخدمه للوصول إلى الخدمات.
          على سبيل المثال، قد نستخدم نظام تحديد المواقع العالمي (GPS) والتقنيات
          الأخرى لجمع بيانات الموقع الجغرافي التي تخبرنا بموقعك الحالي (استنادًا
          إلى عنوان IP الخاص بك). يمكنك إلغاء الاشتراك في السماح لنا بجمع هذه
          المعلومات إما عن طريق رفض الوصول إلى المعلومات أو عن طريق تعطيل إعداد
          الموقع الخاص بك على جهازك. ومع ذلك، إذا اخترت إلغاء الاشتراك، فقد لا
          تتمكن من استخدام جوانب معينة من الخدمات.
        </li>
      </ul>
      <p dir="rtl" class="privacy-subtitle">
        2. كيف نعالج معلوماتك؟
      </p>
      <br />

      <p dir="rtl" class="privacy-text" style={{ fontStyle: "italic" }}>
        باختصار:نقوم بمعالجة معلوماتك لتوفير خدماتنا وتحسينها وإدارتها، والتواصل
        معك، لأغراض الأمان ومنع الاحتيال، والامتثال للقانون. يجوز لنا أيضًا
        معالجة معلوماتك لأغراض أخرى بموافقتك.
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        نقوم بمعالجة معلوماتك الشخصية لعدة أسباب، اعتمادًا على كيفية تفاعلك مع
        خدماتنا، بما في ذلك:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li dir="rtl" class="privacy-text">
          لتسهيل إنشاء الحساب والمصادقة عليه وإدارة حسابات المستخدمين.قد نقوم
          بمعالجة معلوماتك حتى تتمكن من إنشاء حسابك وتسجيل الدخول إليه، وكذلك
          الحفاظ على حسابك في حالة جيدة.
        </li>
        <li dir="rtl" class="privacy-text">
          لطلب ردود الفعل.قد نقوم بمعالجة معلوماتك عند الضرورة لطلب التعليقات
          وللاتصال بك بشأن استخدامك لخدماتنا.
        </li>
        <li dir="rtl" class="privacy-text">
          لحفظ أو حماية المصالح الحيوية للفرد. قد نقوم بمعالجة معلوماتك عند
          الضرورة لحفظ أو حماية المصالح الحيوية للفرد، مثل منع الضرر.
        </li>
      </ul>
      <p dir="rtl" class="privacy-subtitle">
        3. ما هي الأسس القانونية التي نعتمد عليها لمعالجة معلوماتك؟
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontStyle: "italic" }}>
        باختصار:نحن نعالج معلوماتك الشخصية فقط عندما نعتقد أنها ضرورية ولدينا
        سبب قانوني صالح (أي أساس قانوني) للقيام بذلك بموجب القانون المعمول به،
        مثل موافقتك، للامتثال للقوانين، ولتزويدك بالخدمات للدخول فيها أو الوفاء
        بالتزاماتنا التعاقدية، أو لحماية حقوقك، أو لتحقيق مصالحنا التجارية
        المشروعة.
      </p>
      <p
        dir="rtl"
        class="privacy-text"
        style={{
          fontStyle: "italic",
          fontWeight: 700,
          textDecoration: "underline",
        }}
      >
        إذا كنت مقيمًا في الاتحاد الأوروبي أو المملكة المتحدة، فإن هذا القسم
        ينطبق عليك.
      </p>
      <p dir="rtl" class="privacy-text">
        تتطلب اللائحة العامة لحماية البيانات (GDPR) واللائحة العامة لحماية
        البيانات في المملكة المتحدة منا شرح الأسس القانونية الصحيحة التي نعتمد
        عليها لمعالجة معلوماتك الشخصية. وعلى هذا النحو، قد نعتمد على الأسس
        القانونية التالية لمعالجة معلوماتك الشخصية:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li dir="rtl" class="privacy-text">
          موافقة.يجوز لنا معالجة معلوماتك إذا منحتنا الإذن (أي الموافقة)
          لاستخدام معلوماتك الشخصية لغرض محدد. يمكنك سحب موافقتك في أي وقت.
        </li>
        <li dir="rtl" class="privacy-text">
          المصالح المشروعة قد نقوم بمعالجة معلوماتك عندما نعتقد أنها ضرورية بشكل
          معقول لتحقيق مصالحنا التجارية المشروعة وأن تلك المصالح لا تفوق مصالحك
          وحقوقك وحرياتك الأساسية. على سبيل المثال، قد نقوم بمعالجة معلوماتك
          الشخصية لبعض الأغراض الموضحة من أجل:
        </li>
        <li dir="rtl" class="privacy-text">
          فهم كيفية استخدام مستخدمينا لمنتجاتنا وخدماتنا حتى نتمكن من تحسين
          تجربة المستخدم
        </li>
        <li dir="rtl" class="privacy-text">
          الإلتزامات القانونية. قد نقوم بمعالجة معلوماتك عندما نعتقد أنها ضرورية
          للامتثال لالتزاماتنا القانونية، مثل التعاون مع هيئة إنفاذ القانون أو
          وكالة تنظيمية، أو ممارسة حقوقنا القانونية أو الدفاع عنها، أو الكشف عن
          معلوماتك كدليل في دعوى قضائية نحن فيها متضمن.
        </li>
        <li dir="rtl" class="privacy-text">
          المصالح الحيوية. قد نقوم بمعالجة معلوماتك عندما نعتقد أنها ضرورية
          لحماية مصالحك الحيوية أو المصالح الحيوية لطرف ثالث، مثل المواقف التي
          تنطوي على تهديدات محتملة لسلامة أي شخص.
        </li>
      </ul>
      <p
        dir="rtl"
        class="privacy-text"
        style={{
          fontStyle: "italic",
          fontWeight: 700,
          textDecoration: "underline",
        }}
      >
        إذا كنت مقيمًا في كندا، فهذا القسم ينطبق عليك.
      </p>
      <p dir="rtl" class="privacy-text">
        يجوز لنا معالجة معلوماتك إذا منحتنا إذنًا محددًا (أي موافقة صريحة)
        لاستخدام معلوماتك الشخصية لغرض محدد، أو في المواقف التي يمكن فيها
        استنتاج إذنك (أي موافقة ضمنية). في بعض الحالات الاستثنائية، قد يُسمح لنا
        قانونًا بموجب القانون المعمول به بمعالجة معلوماتك دون موافقتك، بما في
        ذلك، على سبيل المثال:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li dir="rtl" class="privacy-text">
          \إذا كان الجمع في مصلحة الفرد بشكل واضح ولا يمكن الحصول على الموافقة
          في الوقت المناسب \
        </li>
        <li dir="rtl" class="privacy-text">
          لإجراء التحقيقات وكشف الاحتيال والوقاية منه
        </li>
        <li dir="rtl" class="privacy-text">
          بالنسبة للمعاملات التجارية بشرط استيفاء شروط معينة
        </li>
        <li dir="rtl" class="privacy-text">
          إذا كان واردًا في إفادة شاهد وكان التحصيل ضروريًا لتقييم مطالبة
          التأمين أو معالجتها أو تسويتها
        </li>
        <li dir="rtl" class="privacy-text">
          لتحديد المصابين أو المرضى أو المتوفين والتواصل مع أقربائهم
        </li>
        <li dir="rtl" class="privacy-text">
          إذا كانت لدينا أسباب معقولة للاعتقاد بأن أحد الأفراد كان أو قد يكون
          ضحية لإساءة مالية
        </li>
        <li dir="rtl" class="privacy-text">
          إذا كان من المعقول توقع أن يؤدي جمع المعلومات واستخدامها بموافقة إلى
          الإضرار بتوفر المعلومات أو دقتها وكان الجمع معقولًا لأغراض تتعلق
          بالتحقيق في خرق اتفاقية أو مخالفة لقوانين كندا أو المقاطعة
        </li>
        <li dir="rtl" class="privacy-text">
          إذا كان الكشف مطلوبًا للامتثال لأمر استدعاء أو أمر قضائي أو أمر محكمة
          أو قواعد المحكمة المتعلقة بإعداد السجلات
        </li>
        <li dir="rtl" class="privacy-text">
          إذا تم إنتاجها من قبل فرد أثناء عمله أو عمله أو مهنته وكانت المجموعة
          متوافقة مع الأغراض التي تم إنتاج المعلومات من أجلها
        </li>
        <li dir="rtl" class="privacy-text">
          إذا كانت المجموعة لأغراض صحفية أو فنية أو أدبية فقط
        </li>
        <li dir="rtl" class="privacy-text">
          إذا كانت المعلومات متاحة للعامة ومحددة بموجب اللوائح
        </li>
      </ul>
      <p dir="rtl" class="privacy-subtitle">
        4. متى ومع من نشارك معلوماتك الشخصية؟
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        باختصار: يجوز لنا مشاركة المعلومات في مواقف محددة موضحة في هذا القسم
        و/أو مع الأطراف الثالثة التالية.
      </p>
      <p dir="rtl" class="privacy-text">
        قد نحتاج إلى مشاركة معلوماتك الشخصية في الحالات التالية:
      </p>
      <ul dir="rtl" style={{ listStyleType: "square", marginRight: 50 }}>
        <li dir="rtl" class="privacy-text">
          تحويلات الأعمال. يجوز لنا مشاركة معلوماتك أو نقلها فيما يتعلق أو أثناء
          المفاوضات بشأن أي اندماج أو بيع أصول الشركة أو تمويل أو الاستحواذ على
          كل أو جزء من أعمالنا لشركة أخرى.
        </li>
        <li dir="rtl" class="privacy-text">
          عندما نستخدم واجهات برمجة التطبيقات لمنصة خرائط Google. يجوز لنا
          مشاركة معلوماتك مع واجهات برمجة تطبيقات معينة لمنصة خرائط Google (مثل
          واجهة برمجة تطبيقات خرائط Google وواجهة برمجة تطبيقات الأماكن). نحن
          نستخدم واجهات برمجة تطبيقات معينة لمنصة خرائط Google لاسترداد معلومات
          معينة عند تقديم طلبات خاصة بالموقع. يتضمن ذلك: موقع المستخدم؛ وغيرها
          من المعلومات المشابهة. يمكن العثور على قائمة كاملة بما نستخدم
          المعلومات من أجله في هذا القسم وفي القسم السابق بعنواننحصل على موقعك
          ("ذاكرة التخزين المؤقت") ونخزنه على جهازك. يمكنك إلغاء موافقتك في أي
          وقت عن طريق الاتصال بنا على تفاصيل الاتصال المقدمة في نهاية هذه
          الوثيقة. تقوم واجهات برمجة تطبيقات Google Maps Platform التي نستخدمها
          بتخزين ملفات تعريف الارتباط والمعلومات الأخرى على أجهزتك والوصول
          إليها. إذا كنت مستخدمًا حاليًا في المنطقة الاقتصادية الأوروبية (دول
          الاتحاد الأوروبي وأيسلندا وليختنشتاين والنرويج) أو المملكة المتحدة،
          فيرجى إلقاء نظرة على إشعار ملفات تعريف الارتباط الخاص بنا.
        </li>
      </ul>
      <p dir="rtl" class="privacy-subtitle">
        5. ما هو موقفنا تجاه مواقع الطرف الثالث؟
      </p>
      <p dir="rtl" class="privacy-text">{`

باختصار: نحن لسنا مسؤولين عن سلامة أي معلومات تشاركها مع أطراف ثالثة قد نرتبط بها أو نعلن عنها في خدماتنا، ولكنها ليست تابعة لخدماتنا.


قد ترتبط الخدمات بمواقع ويب تابعة لجهات خارجية أو خدمات عبر الإنترنت أو تطبيقات جوال و/أو تحتوي على إعلانات من أطراف ثالثة غير تابعة لنا والتي قد ترتبط بمواقع ويب أو خدمات أو تطبيقات أخرى. وبناءً على ذلك، فإننا لا نقدم أي ضمان فيما يتعلق بأي من هذه الأطراف الثالثة، ولن نكون مسؤولين عن أي خسارة أو ضرر ناتج عن استخدام مواقع الويب أو الخدمات أو التطبيقات التابعة لجهات خارجية. إن تضمين رابط إلى موقع ويب أو خدمة أو تطبيق تابع لجهة خارجية لا يعني موافقة منا. لا يمكننا ضمان سلامة وخصوصية البيانات التي تقدمها لأي طرف ثالث. لا يغطي إشعار الخصوصية هذا أي بيانات تم جمعها بواسطة أطراف ثالثة. نحن لسنا مسؤولين عن المحتوى أو ممارسات وسياسات الخصوصية والأمان الخاصة بأي طرف ثالث، بما في ذلك مواقع الويب أو الخدمات أو التطبيقات الأخرى التي قد تكون مرتبطة بالخدمات أو منها. يجب عليك مراجعة سياسات هذه الأطراف الثالثة والاتصال بهم مباشرة للرد على أسئلتك.
`}</p>
      <p dir="rtl" class="privacy-subtitle">
        6. هل نستخدم ملفات تعريف الارتباط وتقنيات التتبع الأخرى؟
      </p>
      <br />

      <p
        dir="rtl"
        class="privacy-text"
        style={{
          fontStyle: "italic",
          fontWeight: 700,
          textDecoration: "underline",
        }}
      >
        باختصار: قد نستخدم ملفات تعريف الارتباط وتقنيات التتبع الأخرى لجمع
        معلوماتك وتخزينها.
      </p>
      <p dir="rtl" class="privacy-text">
        قد نستخدم ملفات تعريف الارتباط وتقنيات التتبع المشابهة (مثل إشارات الويب
        والبكسلات) للوصول إلى المعلومات أو تخزينها. توجد معلومات محددة حول كيفية
        استخدامنا لهذه التقنيات وكيف يمكنك رفض بعض ملفات تعريف الارتباط في إشعار
        ملفات تعريف الارتباط الخاص بنا.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        7. كيف نتعامل مع تسجيلات الدخول الخاصة بك على مواقع التواصل الاجتماعي؟
      </p>
      <br />

      <p
        dir="rtl"
        class="privacy-text"
        style={{
          fontStyle: "italic",
        }}
      >
        باختصار:إذا اخترت التسجيل أو تسجيل الدخول إلى خدماتنا باستخدام حساب
        وسائل التواصل الاجتماعي، فقد نتمكن من الوصول إلى معلومات معينة عنك.
      </p>
      <p dir="rtl" class="privacy-text">
        سنستخدم المعلومات التي نتلقاها فقط للأغراض الموضحة في إشعار الخصوصية هذا
        أو التي تم توضيحها لك بطريقة أخرى في الخدمات ذات الصلة. يرجى ملاحظة أننا
        لا نتحكم، ولسنا مسؤولين عن، الاستخدامات الأخرى لمعلوماتك الشخصية من قبل
        مزود الوسائط الاجتماعية التابع لجهة خارجية. نوصيك بمراجعة إشعار الخصوصية
        الخاص بهم لفهم كيفية جمع معلوماتك الشخصية واستخدامها ومشاركتها، وكيف
        يمكنك تعيين تفضيلات الخصوصية الخاصة بك على مواقعهم وتطبيقاتهم.
      </p>

      <p dir="rtl" class="privacy-text">
        سنستخدم المعلومات التي نتلقاها فقط للأغراض الموضحة في إشعار الخصوصية هذا
        أو التي تم توضيحها لك بطريقة أخرى في الخدمات ذات الصلة. يرجى ملاحظة أننا
        لا نتحكم، ولسنا مسؤولين عن، الاستخدامات الأخرى لمعلوماتك الشخصية من قبل
        مزود الوسائط الاجتماعية التابع لجهة خارجية. نوصيك بمراجعة إشعار الخصوصية
        الخاص بهم لفهم كيفية جمع معلوماتك الشخصية واستخدامها ومشاركتها، وكيف
        يمكنك تعيين تفضيلات الخصوصية الخاصة بك على مواقعهم وتطبيقاتهم.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        8. إلى متى نحتفظ بمعلوماتك؟
      </p>
      <br />

      <p
        dir="rtl"
        class="privacy-text"
        style={{
          fontStyle: "italic",
        }}
      >
        باختصار:نحتفظ بمعلوماتك طالما كان ذلك ضروريًا لتحقيق الأغراض الموضحة في
        إشعار الخصوصية هذا ما لم ينص القانون على خلاف ذلك.
      </p>
      <p dir="rtl" class="privacy-text">
        سنحتفظ بمعلوماتك الشخصية فقط طالما كان ذلك ضروريًا للأغراض المنصوص عليها
        في إشعار الخصوصية هذا، ما لم تكن هناك فترة احتفاظ أطول مطلوبة أو مسموح
        بها بموجب القانون (مثل الضرائب أو المحاسبة أو المتطلبات القانونية
        الأخرى). لن يتطلب أي غرض في هذا الإشعار منا الاحتفاظ بمعلوماتك الشخصية
        لفترة أطول من الفترة الزمنية التي يكون لدى المستخدمين فيها حساب معنا.
      </p>

      <p dir="rtl" class="privacy-text">
        عندما لا تكون لدينا حاجة تجارية مشروعة مستمرة لمعالجة معلوماتك الشخصية،
        فسنقوم إما بحذف هذه المعلومات أو إخفاء هويتها، أو إذا لم يكن ذلك ممكنًا
        (على سبيل المثال، بسبب تخزين معلوماتك الشخصية في أرشيفات النسخ
        الاحتياطي)، فسنقوم بذلك بشكل آمن تخزين معلوماتك الشخصية وعزلها عن أي
        معالجة إضافية حتى يصبح الحذف ممكنًا.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        9. كيف نحافظ على معلوماتك آمنة؟
      </p>
      <br />

      <p
        dir="rtl"
        class="privacy-text"
        style={{
          fontStyle: "italic",
        }}
      >
        باختصار:نحن نهدف إلى حماية معلوماتك الشخصية من خلال نظام من التدابير
        الأمنية التنظيمية والتقنية.
      </p>
      <p dir="rtl" class="privacy-text">
        لقد قمنا بتنفيذ إجراءات أمنية فنية وتنظيمية مناسبة ومعقولة مصممة لحماية
        أمن أي معلومات شخصية نقوم بمعالجتها. ومع ذلك، على الرغم من الضمانات
        والجهود التي نبذلها لتأمين معلوماتك، لا يمكن ضمان أن يكون النقل
        الإلكتروني عبر الإنترنت أو تكنولوجيا تخزين المعلومات آمنًا بنسبة 100%،
        لذلك لا يمكننا أن نعد أو نضمن أن المتسللين أو مجرمي الإنترنت أو غيرهم من
        الأطراف الثالثة غير المصرح بها لن يتعرضوا للخطر. قادرة على التغلب على
        أمننا وجمع معلوماتك أو الوصول إليها أو سرقتها أو تعديلها بشكل غير صحيح.
        على الرغم من أننا سنبذل قصارى جهدنا لحماية معلوماتك الشخصية، إلا أن نقل
        المعلومات الشخصية من وإلى خدماتنا يكون على مسؤوليتك الخاصة. يجب عليك
        الوصول إلى الخدمات فقط في بيئة آمنة.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        10. ما هي حقوق الخصوصية الخاصة بك؟
      </p>
      <br />

      <p
        dir="rtl"
        class="privacy-text"
        style={{
          fontStyle: "italic",
        }}
      >
        باختصار: في بعض المناطق، مثل المنطقة الاقتصادية الأوروبية (EEA)،
        والمملكة المتحدة (UK)، وسويسرا، وكندا، لديك حقوق تتيح لك الوصول بشكل
        أكبر إلى معلوماتك الشخصية والتحكم فيها. يمكنك مراجعة حسابك أو تغييره أو
        إنهائه في أي وقت.
      </p>
      <p dir="rtl" class="privacy-text">
        في بعض المناطق (مثل المنطقة الاقتصادية الأوروبية والمملكة المتحدة
        وسويسرا وكندا)، تتمتع بحقوق معينة بموجب قوانين حماية البيانات المعمول
        بها. وقد يشمل ذلك الحق في (1) طلب الوصول والحصول على نسخة من معلوماتك
        الشخصية، (2) طلب التصحيح أو المحو؛ (3) لتقييد معالجة معلوماتك الشخصية؛
        (4) إمكانية نقل البيانات، إن أمكن؛ و(5) عدم الخضوع لعملية صنع القرار
        الآلية. وفي ظروف معينة، قد يكون لك أيضًا الحق في الاعتراض على معالجة
        معلوماتك الشخصية. يمكنك تقديم مثل هذا الطلب عن طريق الاتصال بنا باستخدام
        تفاصيل الاتصال الواردة في القسم أقل.
      </p>

      <p dir="rtl" class="privacy-text">
        سننظر في أي طلب ونتصرف بناءً عليه وفقًا لقوانين حماية البيانات المعمول
        بها.
      </p>

      <p dir="rtl" class="privacy-text">
        إذا كنت مقيمًا في المنطقة الاقتصادية الأوروبية أو المملكة المتحدة وتعتقد
        أننا نعالج معلوماتك الشخصية بشكل غير قانوني، فلديك أيضًا الحق في تقديم
        شكوى إلىهيئة حماية البيانات في الدول الأعضاء أوهيئة حماية البيانات في
        المملكة المتحدة.
      </p>

      <p dir="rtl" class="privacy-text">
        إذا كنت مقيمًا في المنطقة الاقتصادية الأوروبية أو المملكة المتحدة وتعتقد
        أننا نعالج معلوماتك الشخصية بشكل غير قانوني، فلديك
        <a
          href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
          style={{
            color: "blue",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          أيضًا الحق في تقديم شكوى إلى هيئة حماية البيانات في الدول الأعضاء
        </a>
        أو
        <a
          href="https://ec.europa.eu/newsroom/article29/items/612080/en"
          style={{
            color: "blue",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          هيئة حماية البيانات في المملكة المتحدة
        </a>
        .
      </p>
      <p dir="rtl" class="privacy-text">
        <a
          href="https://www.edoeb.admin.ch/edoeb/en/home.html"
          style={{
            color: "blue",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          بـالمفوض الفيدرالي لحماية البيانات والمعلومات
        </a>
      </p>
      <p dir="rtl" class="privacy-text">
        سحب موافقتك: إذا كنا نعتمد على موافقتك لمعالجة معلوماتك الشخصية، والتي
        قد تكون موافقة صريحة و/أو ضمنية وفقًا للقانون المعمول به، فلديك الحق في
        سحب موافقتك في أي وقت. يمكنك سحب موافقتك في أي وقت عن طريق الاتصال بنا
        باستخدام تفاصيل الاتصال الواردة في القسم أقل.
      </p>
      <p dir="rtl" class="privacy-text">
        ومع ذلك، يرجى ملاحظة أن هذا لن يؤثر على قانونية المعالجة قبل سحبها، ولن
        يؤثر، عندما يسمح القانون المعمول به، على معالجة معلوماتك الشخصية التي
        تتم بالاعتماد على أسباب المعالجة القانونية بخلاف الموافقة.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        معلومات الحساب
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        إذا كنت ترغب في أي وقت في مراجعة أو تغيير المعلومات الموجودة في حسابك أو
        إنهاء حسابك، فيمكنك:
      </p>
      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          قم بتسجيل الدخول إلى إعدادات حسابك وقم بتحديث حساب المستخدم الخاص بك.
        </li>
      </ul>
      <p dir="rtl" class="privacy-text">
        بناءً على طلبك لإنهاء حسابك، سنقوم بإلغاء تنشيط حسابك ومعلوماتك أو حذفها
        من قواعد بياناتنا النشطة. ومع ذلك، قد نحتفظ ببعض المعلومات في ملفاتنا
        لمنع الاحتيال، واستكشاف المشكلات وإصلاحها، والمساعدة في أي تحقيقات، وفرض
        شروطنا القانونية و/أو الامتثال للمتطلبات القانونية المعمول بها.
      </p>
      <p dir="rtl" class="privacy-text">
        إذا كانت لديك أسئلة أو تعليقات حول حقوق الخصوصية الخاصة بك، فيمكنك
        مراسلتنا عبر البريد الإلكتروني علىhello@tigrisstudios.com.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        11. ضوابط لميزات عدم التتبع
      </p>
      <br />
      <p dir="rtl" class="privacy-text">
        تتضمن معظم متصفحات الويب وبعض أنظمة تشغيل الأجهزة المحمولة وتطبيقات
        الأجهزة المحمولة ميزة أو إعداد عدم التتبع ('DNT') الذي يمكنك تنشيطه
        للإشارة إلى تفضيلات الخصوصية الخاصة بك حتى لا تتم مراقبة وجمع بيانات حول
        أنشطة التصفح عبر الإنترنت. في هذه المرحلة، لم يتم الانتهاء من وضع معيار
        تكنولوجي موحد للتعرف على إشارات DNT وتنفيذها. وعلى هذا النحو، فإننا لا
        نستجيب حاليًا لإشارات متصفح DNT أو أي آلية أخرى تنقل تلقائيًا اختيارك
        بعدم تتبعك عبر الإنترنت. إذا تم اعتماد معيار للتتبع عبر الإنترنت والذي
        يجب علينا اتباعه في المستقبل، فسنبلغك بهذه الممارسة في نسخة منقحة من
        إشعار الخصوصية هذا.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        12. هل يتمتع المقيمون في الولايات المتحدة بحقوق خصوصية محددة؟
      </p>
      <br />

      <p dir="rtl" class="privacy-text" style={{ fontStyle: "italic" }}>
        باختصار:إذا كنت مقيمًا في فيرجينيا أو يوتا أو كونيتيكت أو كولورادو أو
        كاليفورنيا، فسيتم منحك حقوقًا محددة فيما يتعلق بالوصول إلى معلوماتك
        الشخصية.
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: "bold" }}>
        ما هي فئات المعلومات الشخصية التي نجمعها؟
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: "bold" }}>
        لقد قمنا بجمع الفئات التالية من المعلومات الشخصية خلال الاثني عشر (12)
        شهرًا الماضية:
      </p>

      <table dir="rtl" style={{ width: "100%", marginBottom: 30 }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: 8 }}>فئة</th>
            <th
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              أمثلة
            </th>
            <th
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              تم جمعها
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              أ. المعرفات
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              تفاصيل الاتصال، مثل الاسم الحقيقي والاسم المستعار والعنوان البريدي
              ورقم الهاتف أو الهاتف المحمول والمعرف الشخصي الفريد والمعرف عبر
              الإنترنت وعنوان بروتوكول الإنترنت وعنوان البريد الإلكتروني واسم
              الحساب
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              نعم
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              ب. المعلومات الشخصية كما هو محدد في قانون سجلات العملاء في
              كاليفورنيا
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              الاسم ومعلومات الاتصال والتعليم والتوظيف والتاريخ الوظيفي
              والمعلومات المالية
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              نعم
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              ج. خصائص التصنيف المحمية بموجب قانون الولاية أو القانون الفيدرالي
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              الجنس وتاريخ الميلاد
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              نعم
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              د- المعلومات التجارية
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              معلومات المعاملة وتاريخ الشراء والتفاصيل المالية ومعلومات الدفع
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              لا
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              هـ- المعلومات البيومترية
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              بصمات الأصابع وبصمات الصوت
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              لا
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              F. الإنترنت أو أنشطة الشبكة المماثلة الأخرى
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              سجل التصفح وسجل البحث والسلوك عبر الإنترنت وبيانات الاهتمامات
              والتفاعلات مع مواقعنا الإلكترونية وغيرها من المواقع والتطبيقات
              والأنظمة والإعلانات
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              لا
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              G. بيانات تحديد الموقع الجغرافي
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              موقع الجهاز
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              نعم
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              ح- المعلومات الصوتية أو الإلكترونية أو المرئية أو الحرارية أو
              الشمية أو ما شابه ذلك
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              الصور وتسجيلات الصوت والفيديو أو المكالمات التي تم إنشاؤها فيما
              يتعلق بأنشطتنا التجارية
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              لا
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              I. المعلومات المهنية أو المتعلقة بالتوظيف
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              تفاصيل الاتصال التجارية من أجل تزويدك بخدماتنا على مستوى العمل أو
              المسمى الوظيفي وتاريخ العمل والمؤهلات المهنية إذا تقدمت بطلب
              للحصول على وظيفة معنا
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              لا
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              ي. معلومات التعليم
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              سجلات الطلاب ومعلومات الدليل
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              لا
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              ك. الاستنتاجات المستمدة من المعلومات الشخصية التي تم جمعها
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              الاستنتاجات المستمدة من أي من المعلومات الشخصية المجمعة المذكورة
              أعلاه لإنشاء ملف تعريف أو ملخص حول، على سبيل المثال، تفضيلات الفرد
              وخصائصه
            </td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              لا
            </td>
          </tr>
          <tr>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            >
              ل. المعلومات الشخصية الحساسة
            </td>
            <td
              className="privacy-text"
              style={{ border: "1px solid black", padding: 8 }}
            ></td>
            <td
              className="privacy-text"
              style={{
                border: "1px solid black",
                padding: 8,
                textAlign: "center",
              }}
            >
              لا
            </td>
          </tr>
        </tbody>
      </table>

      <p dir="rtl" class="privacy-text">
        سوف نستخدم المعلومات الشخصية التي تم جمعها ونحتفظ بها حسب الحاجة لتقديم
        الخدمات أو من أجل:
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          الفئة أ - طالما أن المستخدم لديه حساب معنا
        </li>
        <li dir="rtl" class="privacy-text">
          الفئة ب - طالما أن المستخدم لديه حساب معنا
        </li>
        <li dir="rtl" class="privacy-text">
          الفئة ج - طالما أن المستخدم لديه حساب معنا
        </li>
        <li dir="rtl" class="privacy-text">
          الفئة G - طالما أن المستخدم لديه حساب معنا
        </li>
      </ul>

      <p dir="rtl" class="privacy-text">
        قد نقوم أيضًا بجمع معلومات شخصية أخرى خارج هذه الفئات من خلال الحالات
        التي تتفاعل فيها معنا شخصيًا أو عبر الإنترنت أو عبر الهاتف أو البريد في
        سياق:
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          تلقي المساعدة من خلال قنوات دعم العملاء لدينا؛
        </li>
        <li dir="rtl" class="privacy-text">
          المشاركة في استبيانات العملاء أو المسابقات؛ و
        </li>
        <li dir="rtl" class="privacy-text">
          تسهيل تقديم خدماتنا والرد على استفساراتكم.
        </li>
      </ul>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        كيف نستخدم معلوماتك الشخصية ونشاركها؟
      </p>
      <br />
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        هل سيتم مشاركة معلوماتك مع أي شخص آخر؟
      </p>
      <p dir="rtl" class="privacy-text">
        يجوز لنا الكشف عن معلوماتك الشخصية مع مقدمي الخدمة لدينا بموجب عقد مكتوب
        بيننا وبين كل مقدم خدمة. قد نستخدم معلوماتك الشخصية لأغراض تجارية خاصة
        بنا، مثل إجراء بحث داخلي للتطوير التكنولوجي والعرض. ولا يعتبر هذا بمثابة
        "بيع" لمعلوماتك الشخصية.
      </p>
      <p dir="rtl" class="privacy-text">
        لم نكشف أو نبيع أو نشارك أي معلومات شخصية مع أطراف ثالثة لأغراض تجارية
        أو تجارية خلال الاثني عشر (12) شهرًا السابقة. لن نبيع أو نشارك المعلومات
        الشخصية في المستقبل التي تخص زوار الموقع والمستخدمين والمستهلكين
        الآخرين.
      </p>
      <p dir="rtl" class="privacy-subtitle">
        سكان كاليفورنيا
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        تسمح المادة 1798.83 من القانون المدني لولاية كاليفورنيا، والمعروفة أيضًا
        باسم قانون "Shine The Light"، لمستخدمينا المقيمين في كاليفورنيا بطلب
        معلومات حول فئات المعلومات الشخصية (إن وجدت) التي كشفنا عنها والحصول
        عليها مرة واحدة سنويًا مجانًا. لأطراف ثالثة لأغراض التسويق المباشر
        وأسماء وعناوين جميع الأطراف الثالثة التي شاركنا معها المعلومات الشخصية
        في السنة التقويمية السابقة مباشرة. إذا كنت مقيمًا في كاليفورنيا وترغب في
        تقديم مثل هذا الطلب، فيرجى إرسال طلبك كتابيًا إلينا باستخدام معلومات
        الاتصال الواردة أدناه.
      </p>
      <p dir="rtl" class="privacy-text">
        إذا كان عمرك أقل من 18 عامًا، وتقيم في كاليفورنيا، ولديك حساب مسجل في
        الخدمات، فلديك الحق في طلب إزالة البيانات غير المرغوب فيها التي تنشرها
        علنًا على الخدمات. لطلب إزالة هذه البيانات، يرجى الاتصال بنا باستخدام
        معلومات الاتصال المقدمة أدناه وتضمين عنوان البريد الإلكتروني المرتبط
        بحسابك وبيان بأنك تقيم في كاليفورنيا. سنتأكد من عدم عرض البيانات بشكل
        عام على الخدمات، ولكن يرجى العلم أنه قد لا تتم إزالة البيانات بشكل كامل
        أو شامل من جميع أنظمتنا (مثل النسخ الاحتياطية وما إلى ذلك).
      </p>
      <p
        dir="rtl"
        class="privacy-subtitle"
        style={{ textDecoration: "underline" }}
      >
        إشعار خصوصية CCPA
      </p>
      <br />
      <p dir="rtl" class="privacy-text">
        ينطبق هذا القسم فقط على سكان كاليفورنيا. بموجب قانون خصوصية المستهلك في
        كاليفورنيا (CCPA)، تتمتع بالحقوق المذكورة أدناه.
      </p>
      <p dir="rtl" class="privacy-text">
        يحدد قانون لوائح ولاية كاليفورنيا "المقيمين" على النحو التالي:
      </p>
      <p dir="rtl" class="privacy-text">
        (1) كل فرد موجود في ولاية كاليفورنيا لغير غرض مؤقت أو انتقالي (2) كل فرد
        مقيم في ولاية كاليفورنيا ومتواجد خارج ولاية كاليفورنيا لغرض مؤقت أو
        انتقالي
      </p>
      <p dir="rtl" class="privacy-text">
        يتم تعريف جميع الأفراد الآخرين على أنهم "غير مقيمين".
      </p>
      <p dir="rtl" class="privacy-text">
        إذا كان تعريف "المقيم" ينطبق عليك، فيجب علينا الالتزام بحقوق والتزامات
        معينة فيما يتعلق بمعلوماتك الشخصية.
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        حقوقك فيما يتعلق ببياناتك الشخصية
      </p>
      <p
        dir="rtl"
        class="privacy-subtitle"
        style={{ textDecoration: "underline" }}
      >
        الحق في طلب حذف البيانات — طلب الحذف
      </p>
      <p dir="rtl" class="privacy-text">
        يمكنك أن تطلب حذف معلوماتك الشخصية. إذا طلبت منا حذف معلوماتك الشخصية،
        فسنحترم طلبك ونحذف معلوماتك الشخصية، مع مراعاة بعض الاستثناءات التي ينص
        عليها القانون، مثل (على سبيل المثال لا الحصر) ممارسة مستهلك آخر لحقه في
        حرية التعبير ، ومتطلبات الامتثال لدينا الناتجة عن التزام قانوني، أو أي
        معالجة قد تكون مطلوبة للحماية من الأنشطة غير القانونية.
      </p>
      <p dir="rtl" class="privacy-text" style={{ textDecoration: "underline" }}>
        الحق في الحصول على المعلومات – طلب المعرفة
      </p>
      <p dir="rtl" class="privacy-text" style={{ fontWeight: 700 }}>
        اعتمادًا على الظروف، لديك الحق في معرفة:
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          ما إذا كنا نجمع ونستخدم معلوماتك الشخصية؛
        </li>
        <li dir="rtl" class="privacy-text">
          فئات المعلومات الشخصية التي نجمعها؛
        </li>
        <li dir="rtl" class="privacy-text">
          الأغراض التي تُستخدم من أجلها المعلومات الشخصية التي تم جمعها؛
        </li>
        <li dir="rtl" class="privacy-text">
          سواء كنا نبيع أو نشارك المعلومات الشخصية مع أطراف ثالثة؛
        </li>
        <li dir="rtl" class="privacy-text">
          فئات المعلومات الشخصية التي قمنا ببيعها أو مشاركتها أو الكشف عنها لغرض
          تجاري؛
        </li>
        <li dir="rtl" class="privacy-text">
          فئات الأطراف الثالثة التي تم بيع المعلومات الشخصية لها أو مشاركتها أو
          الكشف عنها لغرض تجاري؛
        </li>
        <li dir="rtl" class="privacy-text">
          الغرض التجاري أو التجاري لجمع المعلومات الشخصية أو بيعها أو مشاركتها؛
          و
        </li>
        <li dir="rtl" class="privacy-text">
          الأجزاء المحددة من المعلومات الشخصية التي جمعناها عنك.
        </li>
      </ul>

      <p dir="rtl" class="privacy-text">
        وفقًا للقانون المعمول به، نحن لسنا ملزمين بتقديم أو حذف معلومات المستهلك
        التي تم إلغاء تحديد هويتها استجابةً لطلب المستهلك أو إعادة تحديد
        البيانات الفردية للتحقق من طلب المستهلك.
      </p>
      <p
        dir="rtl"
        class="privacy-text"
        style={{ textDecoration: "underline", fontWeight: 700 }}
      >
        الحق في عدم التمييز في ممارسة حقوق الخصوصية للمستهلك
      </p>
      <p dir="rtl" class="privacy-text">
        لن نقوم بالتمييز ضدك إذا مارست حقوق الخصوصية الخاصة بك.
      </p>
      <p
        dir="rtl"
        class="privacy-text"
        style={{ textDecoration: "underline", fontWeight: 700 }}
      >
        الحق في الحد من استخدام المعلومات الشخصية الحساسة والكشف عنها
      </p>
      <p dir="rtl" class="privacy-text">
        نحن لا نقوم بمعالجة المعلومات الشخصية الحساسة للمستهلك.
      </p>
      <p dir="rtl" class="privacy-text" style={{ textDecoration: "underline" }}>
        عملية التحقق
      </p>
      <p dir="rtl" class="privacy-text">
        عند تلقي طلبك، سنحتاج إلى التحقق من هويتك لتحديد أنك نفس الشخص الذي
        لدينا معلومات عنه في نظامنا. تتطلب جهود التحقق هذه منا أن نطلب منك تقديم
        معلومات حتى نتمكن من مطابقتها بالمعلومات التي قدمتها لنا مسبقًا. على
        سبيل المثال، اعتمادًا على نوع الطلب الذي ترسله، قد نطلب منك تقديم
        معلومات معينة حتى نتمكن من مطابقة المعلومات التي تقدمها مع المعلومات
        الموجودة لدينا بالفعل في الملف، أو قد نتصل بك من خلال وسيلة اتصال (على
        سبيل المثال الهاتف أو البريد الإلكتروني) التي قدمتها لنا سابقًا. ويجوز
        لنا أيضًا استخدام طرق تحقق أخرى حسب ما تمليه الظروف.
      </p>
      <p dir="rtl" class="privacy-text">
        سنستخدم فقط المعلومات الشخصية المقدمة في طلبك للتحقق من هويتك أو سلطتك
        لتقديم الطلب. إلى أقصى حد ممكن، سوف نتجنب طلب معلومات إضافية منك لأغراض
        التحقق. ومع ذلك، إذا لم نتمكن من التحقق من هويتك من خلال المعلومات التي
        نحتفظ بها بالفعل، فقد نطلب منك تقديم معلومات إضافية لأغراض التحقق من
        هويتك ولأغراض أمنية أو منع الاحتيال. سنقوم بحذف هذه المعلومات المقدمة
        بشكل إضافي بمجرد الانتهاء من التحقق منك.
      </p>
      <p dir="rtl" class="privacy-text" style={{ textDecoration: "underline" }}>
        حقوق الخصوصية الأخرى
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          يجوز لك الاعتراض على معالجة معلوماتك الشخصية.
        </li>
        <li dir="rtl" class="privacy-text">
          يمكنك طلب تصحيح بياناتك الشخصية إذا كانت غير صحيحة أو لم تعد ذات صلة،
          أو تطلب تقييد معالجة المعلومات.
        </li>
        <li dir="rtl" class="privacy-text">
          يمكنك تعيين وكيل معتمد لتقديم طلب بموجب قانون CCPA نيابةً عنك. يجوز
          لنا رفض طلب من وكيل معتمد لا يقدم دليلاً على أنه مفوض بشكل صحيح للتصرف
          نيابةً عنك وفقًا لقانون CCPA.
        </li>
        <li dir="rtl" class="privacy-text">
          يمكنك طلب إلغاء الاشتراك في البيع المستقبلي أو مشاركة معلوماتك الشخصية
          مع أطراف ثالثة. عند تلقي طلب إلغاء الاشتراك، سنتصرف بناءً على الطلب في
          أقرب وقت ممكن، ولكن في موعد لا يتجاوز خمسة عشر (15) يومًا من تاريخ
          تقديم الطلب.
        </li>
      </ul>

      <p dir="rtl" class="privacy-text">
        لممارسة هذه الحقوق، يمكنك الاتصال بنا عن طريق البريد الإلكتروني على
        hello@tigrisstudios.com، أو عن طريق الرجوع إلى تفاصيل الاتصال الموجودة
        في أسفل هذا المستند. إذا كانت لديك شكوى بشأن كيفية تعاملنا مع بياناتك،
        فنحن نود أن نسمع منك.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        سكان كولورادو
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        ينطبق هذا القسم فقط على سكان كولورادو. بموجب قانون الخصوصية في كولورادو
        (CPA)، لديك الحقوق المذكورة أدناه. ومع ذلك، فإن هذه الحقوق ليست مطلقة،
        وفي بعض الحالات، قد نرفض طلبك وفقًا لما يسمح به القانون.
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          الحق في أن تكون على علم بما إذا كنا نقوم بمعالجة بياناتك الشخصية أم لا
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في الوصول إلى بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في تصحيح الأخطاء في بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في طلب حذف بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في الحصول على نسخة من البيانات الشخصية التي شاركتها معنا سابقًا
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في إلغاء الاشتراك في معالجة بياناتك الشخصية إذا تم استخدامها
          للإعلانات المستهدفة، أو بيع البيانات الشخصية، أو التنميط لتعزيز
          القرارات التي تنتج آثارًا قانونية أو تأثيرات مهمة مماثلة ("التنميط")
        </li>
      </ul>

      <p dir="rtl" class="privacy-text">
        لتقديم طلب لممارسة الرياضة هؤلاء الحقوق الموضحة أعلاه، يرجى إرسال بريد
        إلكتروني hello@tigrisstudios.com.
      </p>

      <p dir="rtl" class="privacy-text">
        إذا رفضنا اتخاذ إجراء بخصوص طلبك وكنت ترغب في استئناف قرارنا، فيرجى
        مراسلتنا عبر البريد الإلكتروني على hello@tigrisstudios.com. وفي غضون
        خمسة وأربعين (45) يومًا من استلام الاستئناف، سنبلغك كتابيًا بأي إجراء تم
        اتخاذه أو لم يتم اتخاذه ردًا على الاستئناف، بما في ذلك شرح كتابي لأسباب
        القرارات.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        سكان كونيتيكت
      </p>

      <p dir="rtl" class="privacy-text">
        ينطبق هذا القسم فقط على سكان ولاية كونيتيكت. بموجب قانون خصوصية البيانات
        في ولاية كونيتيكت (CTDPA)، تتمتع بالحقوق المذكورة أدناه. ومع ذلك، فإن
        هذه الحقوق ليست مطلقة، وفي بعض الحالات، قد نرفض طلبك وفقًا لما يسمح به
        القانون.
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          الحق في أن تكون على علم بما إذا كنا نقوم بمعالجة بياناتك الشخصية أم لا
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في الوصول إلى بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في تصحيح الأخطاء في بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في الحصول على نسخة من البيانات الشخصية التي شاركتها معنا سابقًا
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في إلغاء الاشتراك في معالجة بياناتك الشخصية إذا تم استخدامها
          للإعلانات المستهدفة، أو بيع البيانات الشخصية، أو التنميط لتعزيز
          القرارات التي تنتج آثارًا قانونية أو تأثيرات مهمة مماثلة ("التنميط")
        </li>
      </ul>

      <p dir="rtl" class="privacy-text">
        لتقديم طلب لممارسة هذه الحقوق الموضحة أعلاه، يرجى إرسال بريد إلكتروني
        إلى hello@tigrisstudios.com.
      </p>

      <p dir="rtl" class="privacy-text">
        إذا رفضنا اتخاذ إجراء بخصوص طلبك وكنت ترغب في استئناف قرارنا، فيرجى
        مراسلتنا عبر البريد الإلكتروني على hello@tigrisstudios.com. وفي غضون
        ستين (60) يومًا من استلام الاستئناف، سنبلغك كتابيًا بأي إجراء تم اتخاذه
        أو لم يتم اتخاذه ردًا على الاستئناف، بما في ذلك شرح كتابي لأسباب
        القرارات.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        سكان يوتا
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        ينطبق هذا القسم فقط على سكان ولاية يوتا. بموجب قانون خصوصية المستهلك في
        ولاية يوتا (UCPA)، لديك الحقوق المذكورة أدناه. ومع ذلك، فإن هذه الحقوق
        ليست مطلقة، وفي بعض الحالات، قد نرفض طلبك وفقًا لما يسمح به القانون.
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          الحق في أن تكون على علم بما إذا كنا نقوم بمعالجة بياناتك الشخصية أم لا
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في الوصول إلى بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في طلب حذف بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في الحصول على نسخة من البيانات الشخصية التي شاركتها معنا سابقًا
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في إلغاء الاشتراك في معالجة بياناتك الشخصية إذا تم استخدامها
          للإعلانات المستهدفة أو بيع البيانات الشخصية
        </li>
      </ul>

      <p dir="rtl" class="privacy-text">
        لتقديم طلب لممارسة هذه الحقوق الموضحة أعلاه، يرجى إرسال بريد
        إلكترونيhello@tigrisstudios.com.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        سكان فرجينيا
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        بموجب قانون حماية بيانات المستهلك في فرجينيا (VCDPA):
      </p>

      <p dir="rtl" class="privacy-text">
        "المستهلك" يعني الشخص الطبيعي المقيم في الكومنولث والذي يتصرف فقط في
        سياق فردي أو عائلي. ولا يشمل ذلك الشخص الطبيعي الذي يعمل في سياق تجاري
        أو وظيفي.
      </p>
      <p dir="rtl" class="privacy-text">
        "البيانات الشخصية" تعني أي معلومات مرتبطة أو يمكن ربطها بشكل معقول بشخص
        طبيعي محدد أو يمكن تحديد هويته. لا تتضمن "البيانات الشخصية" بيانات
        مجهولة الهوية أو معلومات متاحة للعامة.
      </p>
      <p dir="rtl" class="privacy-text">
        "بيع البيانات الشخصية" يعني تبادل البيانات الشخصية مقابل مقابل نقدي.
      </p>
      <p dir="rtl" class="privacy-text">
        إذا كان تعريف "المستهلك" ينطبق عليك، فيجب علينا الالتزام بحقوق والتزامات
        معينة فيما يتعلق ببياناتك الشخصية.
      </p>

      <p dir="rtl" class="privacy-text" style={{ textDecoration: "underline" }}>
        حقوقك فيما يتعلق ببياناتك الشخصية
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          الحق في أن تكون على علم بما إذا كنا نقوم بمعالجة بياناتك الشخصية أم لا
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في الوصول إلى بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في تصحيح الأخطاء في بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في طلب حذف بياناتك الشخصية
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في الحصول على نسخة من البيانات الشخصية التي شاركتها معنا سابقًا
        </li>
        <li dir="rtl" class="privacy-text">
          الحق في إلغاء الاشتراك في معالجة بياناتك الشخصية إذا تم استخدامها
          للإعلانات المستهدفة، أو بيع البيانات الشخصية، أو التنميط لتعزيز
          القرارات التي تنتج آثارًا قانونية أو تأثيرات مهمة مماثلة ("التنميط")
        </li>
      </ul>

      <p dir="rtl" class="privacy-text" style={{ textDecoration: "underline" }}>
        مارس حقوقك المنصوص عليها بموجب قانون Virginia VCDPA
      </p>

      <p dir="rtl" class="privacy-text">
        يمكنك الاتصال بنا عبر البريد الإلكتروني على hello@tigrisstudios.com.
      </p>

      <p dir="rtl" class="privacy-text">
        إذا كنت تستخدم وكيلًا معتمدًا لممارسة حقوقك، فقد نرفض الطلب إذا لم يقدم
        الوكيل المعتمد دليلاً على أنه مفوض بشكل صحيح للتصرف نيابةً عنك.
      </p>

      <p dir="rtl" class="privacy-text" style={{ textDecoration: "underline" }}>
        عملية التحقق
      </p>

      <p dir="rtl" class="privacy-text">
        قد نطلب منك تقديم معلومات إضافية ضرورية بشكل معقول للتحقق منك ومن طلب
        المستهلك الخاص بك. إذا قمت بتقديم الطلب من خلال وكيل معتمد، فقد نحتاج
        إلى جمع معلومات إضافية للتحقق من هويتك قبل معالجة طلبك.
      </p>

      <p dir="rtl" class="privacy-text">
        عند استلام طلبك، سوف نقوم بالرد دون تأخير لا مبرر له، ولكن في جميع
        الحالات، خلال خمسة وأربعين (45) يومًا من تاريخ الاستلام. ويجوز تمديد
        فترة الاستجابة مرة واحدة لمدة خمسة وأربعين (45) يومًا إضافية عند الضرورة
        المعقولة. وسنبلغك بأي تمديد من هذا القبيل خلال فترة الاستجابة الأولية
        البالغة 45 يومًا، بالإضافة إلى سبب التمديد.
      </p>
      <p dir="rtl" class="privacy-text" style={{ textDecoration: "underline" }}>
        الحق في الاستئناف
      </p>

      <p dir="rtl" class="privacy-text">
        إذا رفضنا اتخاذ إجراء بخصوص طلبك، فسنبلغك بقرارنا والأسباب الكامنة
        وراءه. إذا كنت ترغب في استئناف قرارنا، يرجى مراسلتنا عبر البريد
        الإلكتروني على hello@tigrisstudios.com. وفي غضون ستين (60) يومًا من
        استلام الاستئناف، سنبلغك كتابيًا بأي إجراء تم اتخاذه أو لم يتم اتخاذه
        ردًا على الاستئناف، بما في ذلك شرح كتابي لأسباب القرارات.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        13. هل تتمتع المناطق الأخرى بحقوق خصوصية محددة؟
      </p>
      <br />

      <p dir="rtl" class="privacy-text" style={{ fontStyle: "italic" }}>
        باختصار: قد يكون لديك حقوق إضافية بناءً على البلد الذي تقيم فيه.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        أستراليا و نيوزيلندا
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        نقوم بجمع معلوماتك الشخصية ومعالجتها بموجب الالتزامات والشروط التي حددها
        قانون الخصوصية الأسترالي لعام 1988 وقانون الخصوصية النيوزيلندي لعام 2020
        (قانون الخصوصية).
      </p>

      <p dir="rtl" class="privacy-text">
        يفي إشعار الخصوصية هذا بمتطلبات الإشعار المحددة في كلا قانوني الخصوصية،
        على وجه الخصوص: ما هي المعلومات الشخصية التي نجمعها منك، ومن أي مصادر،
        ولأي أغراض، والمستلمين الآخرين لمعلوماتك الشخصية.
      </p>

      <p dir="rtl" class="privacy-text">
        إذا كنت لا ترغب في تقديم المعلومات الشخصية اللازمة لتحقيق الغرض المنطبق،
        فقد يؤثر ذلك على قدرتنا على تقديم خدماتنا، على وجه الخصوص:
      </p>

      <ul
        dir="rtl"
        style={{ listStyleType: "square", marginRight: 50, marginBottom: 20 }}
      >
        <li dir="rtl" class="privacy-text">
          نقدم لك المنتجات أو الخدمات التي تريدها
        </li>
        <li dir="rtl" class="privacy-text">
          الرد على طلباتك أو المساعدة فيها
        </li>
        <li dir="rtl" class="privacy-text">
          إدارة حسابك معنا
        </li>
        <li dir="rtl" class="privacy-text">
          تأكيد هويتك وحماية حسابك
        </li>
      </ul>

      <p dir="rtl" class="privacy-text">
        لديك الحق في أي وقت في طلب الوصول إلى معلوماتك الشخصية أو تصحيحها.
      </p>
      <p dir="rtl" class="privacy-text">
        أناإذا كنت تعتقد أننا نقوم بمعالجة معلوماتك الشخصية بشكل غير قانوني،
        فلديك الحق في تقديم شكوى بشأن انتهاك مبادئ الخصوصية الأسترالية إلىمكتب
        مفوض المعلومات الأسترالي وانتهاكًا لمبادئ الخصوصية النيوزيلندية الخاصة
        بـمكتب مفوض الخصوصية في نيوزيلندا.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        جمهورية جنوب أفريقيا
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        لديك الحق في أي وقت في طلب الوصول إلى معلوماتك الشخصية أو تصحيحها. إذا
        لم تكن راضيًا عن الطريقة التي نتعامل بها مع أي شكوى فيما يتعلق بمعالجتنا
        للمعلومات الشخصية، فيمكنك الاتصال بمكتب الجهة التنظيمية، وتفاصيلها هي:
      </p>

      <p dir="rtl" class="privacy-text">
        هيئة تنظيم المعلومات (جنوب أفريقيا)
      </p>
      <p dir="rtl" class="privacy-text">
        استفسارات عامة:enquiries@inforegulator.org.za
      </p>
      <p dir="rtl" class="privacy-text">
        الشكاوى (إكمال النموذج 5 من سياسات الإجراءات القانونية/مجالات
        الأولوية):PAIAComplaints@inforegulator.org.za
        &POPIAComplaints@inforegulator.org.za
      </p>

      <p dir="rtl" class="privacy-subtitle">
        14. هل نقوم بإجراء تحديثات على هذا الإشعار؟
      </p>
      <br />
      <p dir="rtl" class="privacy-text" style={{ fontStyle: "italic" }}>
        باختصار:نعم، سنقوم بتحديث هذا الإشعار حسب الضرورة للبقاء متوافقًا مع
        القوانين ذات الصلة.
      </p>
      <p dir="rtl" class="privacy-text">
        قد نقوم بتحديث إشعار الخصوصية هذا من وقت لآخر. ستتم الإشارة إلى الإصدار
        المحدث بتاريخ "منقح" وسيكون الإصدار المحدث ساري المفعول بمجرد إمكانية
        الوصول إليه. إذا أجرينا تغييرات جوهرية على إشعار الخصوصية هذا، فقد نخطرك
        إما عن طريق نشر إشعار بهذه التغييرات بشكل بارز أو عن طريق إرسال إشعار
        إليك مباشرةً. نحن نشجعك على مراجعة إشعار الخصوصية هذا بشكل متكرر لتكون
        على علم بكيفية حماية معلوماتك.
      </p>

      <p dir="rtl" class="privacy-subtitle">
        15. كيف يمكنك الاتصال بنا بشأن هذا الإشعار؟
      </p>
      <br />

      <p dir="rtl" class="privacy-text">
        إذا كانت لديك أسئلة أو تعليقات حول هذا الإشعار، فيمكنك مراسلتنا عبر
        البريد الإلكتروني على hello@tigrisstudios.com أو الاتصال بنا عبر البريد
        على:
      </p>
      <p dir="rtl" class="privacy-text" style={{ marginBottom: 0 }}>
        استوديوهات دجلة FZCO
      </p>
      <p dir="rtl" class="privacy-text" style={{ marginBottom: 0 }}>
        16235-001، IFZA Business Park، DDP
      </p>
      <p dir="rtl" class="privacy-text" style={{ marginBottom: 0 }}>
        دبي,الإمارات العربية المتحدة
      </p>
      <p dir="rtl" class="privacy-text" style={{ marginBottom: 0 }}>
        الإمارات العربية المتحدة
      </p>

      <p dir="rtl" class="privacy-subtitle">
        16. كيف يمكنك مراجعة أو تحديث أو حذف البيانات التي نجمعها منك؟
      </p>
      <br />
      <p dir="rtl" class="privacy-text">
        لديك الحق في طلب الوصول إلى المعلومات الشخصية التي نجمعها منك، أو تغيير
        تلك المعلومات، أو حذفها.
      </p>
    </>
  );
};

export default PrivacyPolicyAra;
