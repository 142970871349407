const burgerMenu = () => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666748 15.1111C0.666748 14.4975 1.16421 14 1.77786 14H19.5556C20.1693 14 20.6667 14.4975 20.6667 15.1111C20.6667 15.7248 20.1693 16.2222 19.5556 16.2222H1.77786C1.16421 16.2222 0.666748 15.7248 0.666748 15.1111Z"
      fill="#3cb6c1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666748 8.44449C0.666748 7.83084 1.16421 7.33337 1.77786 7.33337H19.5556C20.1693 7.33337 20.6667 7.83084 20.6667 8.44449C20.6667 9.05813 20.1693 9.5556 19.5556 9.5556H1.77786C1.16421 9.5556 0.666748 9.05813 0.666748 8.44449Z"
      fill="#3cb6c1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666748 1.7778C0.666748 1.16415 1.16421 0.666687 1.77786 0.666687H19.5556C20.1693 0.666687 20.6667 1.16415 20.6667 1.7778C20.6667 2.39145 20.1693 2.88891 19.5556 2.88891H1.77786C1.16421 2.88891 0.666748 2.39145 0.666748 1.7778Z"
      fill="#3cb6c1"
    />
  </svg>
);

export default burgerMenu;
