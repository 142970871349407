import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./styles.css";

import { Footer, Input, Textarea } from "../../components";

const SEND_EMAIL_URL = "https://api.niyaapp.com/contact/send";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendMail = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(SEND_EMAIL_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message,
          name,
          email,
        }),
      }).then((res) => res.json());

      setName("");
      setEmail("");
      setMessage("");
      console.log(response);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const isBtnDisabled = !name.trim() || !message.trim() || !isValidEmail(email);

  return (
    <div className="about container">
      <div className="contact-wrapper">
        <h2 className="about-title">Contact us</h2>
        <div style={{ marginBottom: 20 }}>
          <Input value={name} onChange={setName} placeholder="Name" />
        </div>
        <div style={{ marginBottom: 20 }}>
          <Input value={email} onChange={setEmail} placeholder="Email" />
        </div>
        <div style={{ marginBottom: 20 }}>
          <Textarea
            value={message}
            onChange={setMessage}
            placeholder="Message"
            max={160}
          />
        </div>

        <div
          className={`contact-button ${
            isBtnDisabled && "contact-button-disabled"
          }`}
          disabled={isBtnDisabled || isLoading}
          onClick={sendMail}
        >
          {isLoading ? <ClipLoader color="white" size={15} /> : "Send"}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
