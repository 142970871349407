import React, { useEffect, useState } from "react";
import { Footer } from "../../components";
import LanguageSelect from "../../components/LanguageSelect";
import { ICONS } from "../../assets";
import TermsOfUseEng from "./TermsOfUseEng";
import TermsOfUseInd from "./TermsOfUseInd";
import TermsOfUseAra from "./TermsOfUseAra";

const options = [
  { value: "eng", label: "English", icon: <ICONS.engFlag /> },
  {
    value: "ind",
    label: "Bahasa Indonesia",
    icon: <ICONS.indonesiaFlag />,
  },
  {
    value: "ar",
    label: "العربية",
    icon: <ICONS.arabicFlag />,
  },
];

const languagesConfig = {
  eng: <TermsOfUseEng />,
  ind: <TermsOfUseInd />,
  ar: <TermsOfUseAra />,
};
const TermsOfUse = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(options[0]);

  const handleSelectChange = (language) => {
    setSelectedLanguage(language);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ flex: 1, paddingTop: 100 }}>
      <div className="container">
        <LanguageSelect
          language={selectedLanguage}
          onSelect={handleSelectChange}
          options={options}
        />
        {languagesConfig[selectedLanguage.value]}
      </div>
      <Footer hidePrivacyLink />
    </div>
  );
};

export default TermsOfUse;
