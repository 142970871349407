const EngFlag = () => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_511_161)">
      <rect y="0.5" width="24" height="17" rx="3" fill="#1A47B8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.55311 0.5H0V3.33333L21.433 17.5H24V14.6667L2.55311 0.5Z"
        fill="white"
      />
      <path
        d="M0.851541 0.5L24 15.8402V17.5H23.1684L0 2.14397V0.5H0.851541Z"
        fill="#F93939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7143 0.5H24V3.33333C24 3.33333 9.15406 12.7718 2.28571 17.5H0V14.6667L21.7143 0.5Z"
        fill="white"
      />
      <path
        d="M24 0.5H23.2249L0 15.8534V17.5H0.851541L24 2.15638V0.5Z"
        fill="#F93939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.72809 0.5H15.2919V5.74373H24V12.2528H15.2919V17.5H8.72809V12.2528H0V5.74373H8.72809V0.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1053 0.5H13.8947V7.03846H24V10.9615H13.8947V17.5H10.1053V10.9615H0V7.03846H10.1053V0.5Z"
        fill="#F93939"
      />
    </g>
    <defs>
      <clipPath id="clip0_511_161">
        <rect y="0.5" width="24" height="17" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EngFlag;
