import React from "react";

import Top from "./Top";
import Features from "./Features";
import { Footer } from "../../components";

const Home = () => {
  return (
    <div className="App">
      <div className="App-top">
        <div className="App-top-wrapper">
          <Top />
        </div>
      </div>
      <Features />
      <Footer />
    </div>
  );
};

export default Home;
