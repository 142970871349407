import React from "react";
import "./styles.css";
import { NavLink } from "react-router-dom";

const Footer = ({ hidePrivacyLink }) => {
  return (
    <div className="container">
      <footer className="footer">
        <p className="footer-title">
          © 2024 Tigris Studios FZCO. All rights reserved.
        </p>
        {hidePrivacyLink ? null : (
          <div className="privacy-wrap">
            <NavLink to="/privacy" className="privacy-link">
              Privacy Policy
            </NavLink>
            <NavLink to="/terms" className="privacy-link">
              Terms of Service
            </NavLink>
          </div>
        )}
      </footer>
    </div>
  );
};

export default Footer;
